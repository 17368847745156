import gql from "graphql-tag";

import { IPrepareSystemHighlightSeqPayload } from "redux/reducer_types";

export const GET_HSID_FOR_SYSTEM_HIGHLIGHT_SEQ = gql`
    mutation getHsidForSystemHighlightSeq(
        $input: CreateSavedHighlightSequenceInput!
    ) {
        createSavedHighlightSequence(input: $input) {
            highlightSequence {
                id
            }
        }
    }
`;

export interface IResult {
    highlightSequence: {
        id: number;
    };
}

export interface IVariables {
    input: IPrepareSystemHighlightSeqPayload;
}

// export default class QSystemHighlightSeqForPlayer extends Mutation<
//     IResult,
//     IVariables
// > {}
