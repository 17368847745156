import produce from "immer";

import * as React from "react";

import parentLogger from "app/utils/loggerService";
const logger = parentLogger.child({ module: "userAndRoleProvider" });

export type ClientData = {
    clientId: number;
    clientName: string;
    clientShortName: string;
    clientType: "entity" | "individual";
    titleText: string;
    subtitleText?: string;
    logoS3Filename?: string;
    additionalInfoMd?: string;
    colorScheme?: {
        [key: string]: any;
    };
};

type State = { clientData: ClientData };

type Action = {
    type: "saveClient";
    value: ClientData;
};

type Dispatch = (action: Action) => void;
type UserAndRoleProviderProps = { children: React.ReactNode };

const ClientContext = React.createContext<
    { state: State; dispatch: Dispatch } | undefined
>(undefined);

const initialState: State = {
    clientData: {
        clientId: -1,
        clientName: "None",
        clientShortName: "none",
        clientType: "entity",
        titleText: "None",
    },
};

function clientReducer(state: State, action: Action) {
    logger.debug(`got action of ${JSON.stringify(action)}`);
    switch (action.type) {
        case "saveClient": {
            return produce(state, (draft) => {
                draft.clientData = action.value;
            });
        }
        default: {
            // @ts-ignore  thinks we can never end up here
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function ClientProvider({ children }: UserAndRoleProviderProps) {
    const [state, dispatch] = React.useReducer(clientReducer, initialState);
    logger.debug(
        `in provider after useReducer in ClientProvider.  have state of ${JSON.stringify(
            state
        )}`
    );
    // NOTE: you *might* need to memoize this value
    // Learn more in http://kcd.im/optimize-context
    const value = { state, dispatch };
    return (
        <ClientContext.Provider value={value}>
            {children}
        </ClientContext.Provider>
    );
}

function useClientProvider() {
    const context = React.useContext(ClientContext);
    if (context === undefined) {
        throw new Error(
            "useClientProvider must be used within a ClientProvider"
        );
    }
    return context;
}

export { ClientProvider, useClientProvider };
