import gql from "graphql-tag";

import { IPlaybackRate } from "app/graphql_types";

export const UPDATE_HIGHLIGHT = gql`
    mutation updatehighlight($input: UpdateHighlightInput!) {
        updateHighlight(input: $input) {
            clientMutationId
        }
    }
`;

// interface IResult {
//     clientMutationId: null;
// }

export interface IVariables {
    input: {
        vHighlightId: number;
        vDeleteHighlight?: boolean;
        vIsApproved?: boolean | null;
        vCaption?: string | null;
        vHighlightCategoryId?: number | null;
        vHighlightTimeMs?: number | null;
        vQuality?: number | null;
        vSpecifiedDuration?: number | null;
        vSpecifiedAdvanceMs?: number | null;
        vViewName?: string | null;
        vPlaybackRates?: IPlaybackRate[] | null;
        vHighlightSubCats?: string[] | null;
    };
}

// export default class MUpdateHighlight extends Mutation<IResult, IVariables> {}
