import * as fromActions from "./app_actions";
import { IStoreAuth } from "./reducer_types";

// @ts-ignore -- problem is the type of initial state since it is partial
export const authReducer = (
    state: IStoreAuth = {},
    action: fromActions.ActionsType
) => {
    switch (action.type) {
        case fromActions.ActionTypes.SAVE_AUTH_PROPS: {
            const { authProps } = action.payload;
            return { ...state, authProps };
        }

        case fromActions.ActionTypes.SAVE_CHANGED_AUTH_STATE: {
            const { changedState } = action.payload;
            return { ...state, changedState };
        }

        case fromActions.ActionTypes.SET_REDIRECT_AFTER_LOGIN: {
            const { redirectAfterLoginTo } = action.payload;
            return { ...state, redirectAfterLoginTo };
        }

        default:
            return state;
    }
};
