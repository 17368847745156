/** @format */

import * as React from "react";

import { MessageBar } from "office-ui-fabric-react";
import { CommandBar } from "office-ui-fabric-react/lib/CommandBar";
import styled, { css, DefaultTheme, withTheme } from "styled-components";

import { IRelement } from "app/types";

export interface IMedia {
    desktop?: any;
    tablet?: any;
    phone?: any;
}

export const getMedia = (sizes) => {
    return Object.keys(sizes).reduce((acc, label) => {
        acc[label] = (...args) =>
            // @ts-ignore
            css`
                @media (min-width: ${sizes[label].min /
                    16}em) and (max-width: ${sizes[label].max / 16}em) {
                    ${css(...args)}
                }
            `;
        return acc;
    }, {});
};

// Grid
interface IGridProps extends IRelement {
    gtc?: string | string[];
    gtr?: string | string[];
    gg?: string;
    gta?: string[] | string[][];
    areaName?: string;
    margin?: string | string[];
    backgroundColor?: string;
    borderRadius?: string | string[];
    boxShadow?: string | string[];
    jc?: string | string[];
    padding?: string | string[];
    gc?: string | string[];
    gr?: string | string[];
    gac?: string | string[];
    gar?: string | string[];
    gaf?: string | string[];
    overflow?: string;
    border?: string | string[];
    borderBottom?: string | string[];
    pageBreakInside?: string;
    position?: string;
    zIndex?: number;
    fontSizeOrSizes?: number | string | number[] | string[];
    fontFamily?: string;
    fontWeight?: number;
    height?: string | string[];
    width?: string | string[];
    minWidth?: string;
    minHeight?: string;
    maxWidth?: string;
    maxHeight?: string;
    alignItems?: string;
    alignContent?: string;
    justifyItems?: string;
    justifyContent?: string;
}

// OLD - props didn't pass through export const Grid = styled<IGridProps, "div">("div")`
// NEW - doesn't work export const Grid = styled<IGridProps>(InnerDivNoProps)`

export const Grid = styled("div")<IGridProps>`
    display: grid;

    ${(props) => {
        if (props.width && typeof props.width === "string") {
            return `width: ${props.width};`;
        } else if (props.width) {
            const media: IMedia = getMedia(props.theme.sizes);
            return `
                ${media.desktop`width: ${props.width![0]};`.join("")}
                ${media.tablet`width: ${props.width![1]};`.join("")}
                ${media.phone`width: ${props.width![2]};`.join("")}
                `;
        } else {
            return ""; // return no default
        }
    }}

    ${(props) => {
        if (props.height && typeof props.height === "string") {
            return `height: ${props.height};`;
        } else if (props.height) {
            const media: IMedia = getMedia(props.theme.sizes);
            return `
                ${media.desktop`height: ${props.height![0]};`.join("")}
                ${media.tablet`height: ${props.height![1]};`.join("")}
                ${media.phone`height: ${props.height![2]};`.join("")}
                `;
        } else {
            return ""; // return no default
        }
    }}

    ${({ minWidth }) =>
        minWidth &&
        `
        min-width: ${minWidth};
    `}

    ${({ minHeight }) =>
        minHeight &&
        `
        min-height: ${minHeight};
    `}

    ${({ maxWidth }) =>
        maxWidth &&
        `
        max-width: ${maxWidth};
    `}

    ${({ maxHeight }) =>
        maxHeight &&
        `
        max-height: ${maxHeight};
    `}

    ${(props) => {
        if (props.gtc && typeof props.gtc === "string") {
            return `grid-template-columns: ${props.gtc};`;
        } else if (props.gtc) {
            const media: IMedia = getMedia(props.theme.sizes);
            return `
                ${media.desktop`grid-template-columns: ${props.gtc![0]};`.join(
                    ""
                )}
                ${media.tablet`grid-template-columns: ${props.gtc![1]};`.join(
                    ""
                )}
                ${media.phone`grid-template-columns: ${props.gtc![2]};`.join(
                    ""
                )}
                `;
        } else {
            return "grid-template-columns: 1fr;";
        }
    }}

    ${(props) => {
        if (props.gac && typeof props.gac === "string") {
            return `grid-auto-columns: ${props.gac};`;
        } else if (props.gac) {
            const media: IMedia = getMedia(props.theme.sizes);
            return `
                ${media.desktop`grid-auto-columns: ${props.gac![0]};`.join("")}
                ${media.tablet`grid-auto-columns: ${props.gac![1]};`.join("")}
                ${media.phone`grid-auto-columns: ${props.gac![2]};`.join("")}
                `;
        } else {
            return ""; // return no default
        }
    }}

    ${(props) => {
        if (props.gar && typeof props.gar === "string") {
            return `grid-auto-rows: ${props.gar};`;
        } else if (props.gar) {
            const media: IMedia = getMedia(props.theme.sizes);
            return `
                ${media.desktop`grid-auto-rows: ${props.gar![0]};`.join("")}
                ${media.tablet`grid-auto-rows: ${props.gar![1]};`.join("")}
                ${media.phone`grid-auto-rows: ${props.gar![2]};`.join("")}
                `;
        } else {
            return ""; // return no default
        }
    }}

    ${(props) => {
        if (props.gaf && typeof props.gaf === "string") {
            return `grid-auto-flow: ${props.gaf};`;
        } else if (props.gaf) {
            const media: IMedia = getMedia(props.theme.sizes);
            return `
                ${media.desktop`grid-auto-flow: ${props.gaf![0]};`.join("")}
                ${media.tablet`grid-auto-flow: ${props.gaf![1]};`.join("")}
                ${media.phone`grid-auto-flow: ${props.gaf![2]};`.join("")}
                `;
        } else {
            return ""; // return no default
        }
    }}

    ${(props) => {
        if (props.gtr && typeof props.gtr === "string") {
            return `grid-template-rows: ${props.gtr};`;
        } else if (props.gtr) {
            const media: IMedia = getMedia(props.theme.sizes);
            return `
                ${media.desktop`grid-template-rows: ${props.gtr![0]};`.join("")}
                ${media.tablet`grid-template-rows: ${props.gtr![1]};`.join("")}
                ${media.phone`grid-template-rows: ${props.gtr![2]};`.join("")}
                `;
        } else {
            return "grid-template-rows: 1fr;";
        }
    }}

    ${(props) => {
        if (props.margin && typeof props.margin === "string") {
            return `margin: ${props.margin};`;
        } else if (props.margin) {
            const media: IMedia = getMedia(props.theme.sizes);
            return `
                ${media.desktop`margin: ${props.margin![0]};`.join("")}
                ${media.tablet`margin: ${props.margin![1]};`.join("")}
                ${media.phone`margin: ${props.margin![2]};`.join("")}
                `;
        } else {
            return "margin: 0px;";
        }
    }}

    ${(props) => {
        if (props.gta && typeof props.gta[0] === "string") {
            const gtaList = props.gta as string[];
            return `grid-template-areas: ${gtaList
                .map((el) => JSON.stringify(el))
                .join(" ")};`;
        } else if (props.gta) {
            const media: IMedia = getMedia(props.theme.sizes);
            const gtaTexts = ["desktop", "tablet", "phone"].map(
                (size, index) => {
                    const gtaList = props.gta![index] as string[];
                    return gtaList.map((el) => JSON.stringify(el)).join(" ");
                }
            );
            return `
                ${media.desktop`grid-template-areas: ${gtaTexts[0]};`.join("")}
                ${media.tablet`grid-template-areas: ${gtaTexts[1]};`.join("")}
                ${media.phone`grid-template-areas: ${gtaTexts[2]};`.join("")}
                `;
        } else {
            // no gta's
            return "";
        }
    }}

    grid-gap: ${(props) => (props.gg ? `${props.gg};` : "10px;")}

    ${({ areaName }) =>
        areaName &&
        `
        grid-area: ${areaName};
    `}

    ${({ backgroundColor }) =>
        backgroundColor &&
        `
        background-color: ${backgroundColor};
    `}

    ${(props) => {
        if (props.border && typeof props.border === "string") {
            return `border: ${props.border};`;
        } else if (props.border) {
            const media: IMedia = getMedia(props.theme.sizes);
            return `
                ${media.desktop`border: ${props.border![0]};`.join("")}
                ${media.tablet`border: ${props.border![1]};`.join("")}
                ${media.phone`border: ${props.border![2]};`.join("")}
                `;
        } else {
            return ""; // no default
        }
    }}

    ${(props) => {
        if (props.borderBottom && typeof props.borderBottom === "string") {
            return `border-bottom: ${props.borderBottom};`;
        } else if (props.borderBottom) {
            const media: IMedia = getMedia(props.theme.sizes);
            return `
                ${media.desktop`border-bottom: ${props.borderBottom![0]};`.join(
                    ""
                )}
                ${media.tablet`border-bottom: ${props.borderBottom![1]};`.join(
                    ""
                )}
                ${media.phone`border-bottom: ${props.borderBottom![2]};`.join(
                    ""
                )}
                `;
        } else {
            return ""; // no default
        }
    }}

    ${(props) => {
        if (props.borderRadius && typeof props.borderRadius === "string") {
            return `border-radius: ${props.borderRadius};`;
        } else if (props.borderRadius) {
            const media: IMedia = getMedia(props.theme.sizes);
            return `
                ${media.desktop`border-radius: ${props.borderRadius![0]};`.join(
                    ""
                )}
                ${media.tablet`border-radius: ${props.borderRadius![1]};`.join(
                    ""
                )}
                ${media.phone`border-radius: ${props.borderRadius![2]};`.join(
                    ""
                )}
                `;
        } else {
            return ""; // no default
        }
    }}

    ${(props) => {
        if (props.boxShadow && typeof props.boxShadow === "string") {
            return `box-shadow: ${props.boxShadow};`;
        } else if (props.boxShadow) {
            const media: IMedia = getMedia(props.theme.sizes);
            return `
                ${media.desktop`box-shadow: ${props.boxShadow![0]};`.join("")}
                ${media.tablet`box-shadow: ${props.boxShadow![1]};`.join("")}
                ${media.phone`box-shadow: ${props.boxShadow![2]};`.join("")}
                `;
        } else {
            return ""; // no default
        }
    }}

    ${({ fontFamily }) =>
        fontFamily &&
        `
        font-family: ${fontFamily};
    `}

    ${({ fontWeight }) =>
        fontWeight &&
        `
        font-weight: ${fontWeight};
    `}


    ${({ fontSizeOrSizes, theme }) => {
        if (typeof fontSizeOrSizes === "number") {
            // no media info, in pixels
            return `font-size: ${fontSizeOrSizes}px;`;
        } else if (typeof fontSizeOrSizes === "string") {
            // no media info, in other unit
            return `font-size: ${fontSizeOrSizes};`;
        } else if (
            Array.isArray(fontSizeOrSizes) &&
            typeof fontSizeOrSizes[0] === "number"
        ) {
            const media: IMedia = getMedia(theme.sizes);
            return `
                ${media.desktop`font-size: ${fontSizeOrSizes![0]}px;`.join("")}
                ${media.tablet`font-size: ${fontSizeOrSizes![1]}px;`.join("")}
                ${media.phone`font-size: ${fontSizeOrSizes![2]}px;`.join("")}
                `;
        } else if (
            Array.isArray(fontSizeOrSizes) &&
            typeof fontSizeOrSizes[0] === "string"
        ) {
            const media: IMedia = getMedia(theme.sizes);
            return `
                ${media.desktop`font-size: ${fontSizeOrSizes![0]};`.join("")}
                ${media.tablet`font-size: ${fontSizeOrSizes![1]};`.join("")}
                ${media.phone`font-size: ${fontSizeOrSizes![2]};`.join("")}
                `;
        } else {
            return "";
        }
    }}

    ${(props) => {
        if (props.jc && typeof props.jc === "string") {
            return `justify-content: ${props.jc};`;
        } else if (props.jc) {
            const media: IMedia = getMedia(props.theme.sizes);
            return `
                ${media.desktop`justify-content: ${props.jc![0]};`.join("")}
                ${media.tablet`justify-content: ${props.jc![1]};`.join("")}
                ${media.phone`justify-content: ${props.jc![2]};`.join("")}
                `;
        } else {
            return ""; // no default
        }
    }}

    ${(props) => {
        if (props.padding && typeof props.padding === "string") {
            return `padding: ${props.padding};`;
        } else if (props.padding) {
            const media: IMedia = getMedia(props.theme.sizes);
            return `
                ${media.desktop`padding: ${props.padding![0]};`.join("")}
                ${media.tablet`padding: ${props.padding![1]};`.join("")}
                ${media.phone`padding: ${props.padding![2]};`.join("")}
                `;
        } else {
            return ""; // no default
        }
    }}

    ${({ alignItems }) =>
        alignItems &&
        `
        align-items: ${alignItems};
    `}

    ${({ justifyItems }) =>
        justifyItems &&
        `
        justify-items: ${justifyItems};
    `}

    ${({ alignContent }) =>
        alignContent &&
        `
        align-content: ${alignContent};
    `}

    ${({ justifyContent }) =>
        justifyContent &&
        `
        justify-content: ${justifyContent};
    `}

    ${({ zIndex }) =>
        zIndex &&
        `
        z-index: ${zIndex};
    `}

    ${({ overflow }) =>
        overflow &&
        `
    overflow: ${overflow};
    `}

    ${({ position }) =>
        position &&
        `
    position: ${position};
    `}

    ${({ pageBreakInside }) =>
        pageBreakInside &&
        `
    page-break-inside: ${pageBreakInside};
    `}


    ${({ gr, theme }) => {
        if (typeof gr === "string") {
            // this has no media info
            return `grid-row: ${gr};`;
        } else if (gr) {
            const media: IMedia = getMedia(theme.sizes);
            return `
                ${media.desktop`grid-row: ${gr![0]};`.join("")}
                ${media.tablet`grid-row: ${gr![1]};`.join("")}
                ${media.phone`grid-row: ${gr![2]};`.join("")}
                `;
        } else {
            return "";
        }
    }}

    ${(props) => {
        if (props.gc && typeof props.gc === "string") {
            return `grid-column: ${props.gc};`;
        } else if (props.gc) {
            const media: IMedia = getMedia(props.theme.sizes);
            return `
                ${media.desktop`grid-column: ${props.gc![0]};`.join("")}
                ${media.tablet`grid-column: ${props.gc![1]};`.join("")}
                ${media.phone`grid-column: ${props.gc![2]};`.join("")}
                `;
        } else {
            const returnVal = props.areaName ? "" : "grid-column: 1 / -1;";
            return returnVal;
        }
    }}
`;

// FullWidthRow

interface IFWRProps extends IRelement {
    backgroundColor?: string;
    rowHeight?: string;
    heightMinMax?: boolean;
    gtc?: string;
    alignItems?: string;
    areaName?: string;
}

// OLD - props didn't pass through export const FullWidthRow = styled<IFWRProps, "div">("div")`
// NEW - doesn't work export const FullWidthRow = styled<IFWRProps>(InnerDivNoProps)`

export const FullWidthRow = styled("div")<IFWRProps>`
    display: grid;
    grid-column: 1 / -1;
    ${({ gtc }) =>
        gtc &&
        `
        grid-template-columns: ${gtc};
    `}

    ${({ alignItems }) =>
        alignItems &&
        `
        align-items: ${alignItems};
    `}

    ${({ backgroundColor }) =>
        backgroundColor &&
        `
        background-color: ${backgroundColor};
    `}

    ${({ heightMinMax, rowHeight }) => {
        if (heightMinMax) {
            return (
                rowHeight &&
                `
                height: minmax(${rowHeight}, max-content);
                `
            );
        } else {
            return (
                rowHeight &&
                `
                height: ${rowHeight};
                `
            );
        }
    }}

    ${({ areaName }) =>
        areaName &&
        `
        grid-area: ${areaName};
    `}
`;

// GridItem
interface IGIProps extends IRelement {
    gc?: string | string[];
    gr?: string | string[];
    fontSizeOrSizes?: number | string | number[] | string[];
    backgroundColor?: string;
    color?: string;
    marginLeft?: number | number[];
    marginTop?: number | number[];
    margin?: string | string[];
    padding?: string | string[];
    alignItems?: string;
    areaName?: string;
    noGrid?: boolean;
    hidden?: boolean;
    justifySelf?: string;
    alignSelf?: string;
    fontFamily?: string;
    fontWeight?: number;
    border?: string | string[];
    borderBottom?: string | string[];
    borderRadius?: string | string[];
    pageBreakInside?: string;
    position?: string;
    zIndex?: number;
    overflow?: string;
    height?: string;
    width?: string | string[];
    minWidth?: string;
    minHeight?: string;
    maxWidth?: string;
    maxHeight?: string;
    float?: string;
}

// OLD - props didn't pass through export const GridItem = styled<IGIProps, "div">("div")`
// NEW - doesn't work export const GridItem = styled<IGIProps>(InnerDivNoProps)`

export const GridItem = styled("div")<IGIProps>`
    ${({ noGrid, hidden }) => {
        if (hidden) {
            return "display: none;";
        } else if (noGrid) {
            return "display: block;";
        } else {
            return "display: grid;";
        }
    }}

    ${({ gc, theme }) => {
        if (typeof gc === "string") {
            // this has no media info
            return `grid-column: ${gc};`;
        } else if (gc) {
            const media: IMedia = getMedia(theme.sizes);
            return `
                ${media.desktop`grid-column: ${gc![0]};`.join("")}
                ${media.tablet`grid-column: ${gc![1]};`.join("")}
                ${media.phone`grid-column: ${gc![2]};`.join("")}
                `;
        } else {
            return "";
        }
    }}

    ${({ gr, theme }) => {
        if (typeof gr === "string") {
            // this has no media info
            return `grid-row: ${gr};`;
        } else if (gr) {
            const media: IMedia = getMedia(theme.sizes);
            return `
                ${media.desktop`grid-row: ${gr![0]};`.join("")}
                ${media.tablet`grid-row: ${gr![1]};`.join("")}
                ${media.phone`grid-row: ${gr![2]};`.join("")}
                `;
        } else {
            return "";
        }
    }}

    ${({ fontFamily }) =>
        fontFamily &&
        `
        font-family: ${fontFamily};
    `}

    ${({ fontWeight }) =>
        fontWeight &&
        `
        font-weight: ${fontWeight};
    `}


    ${({ alignItems }) =>
        alignItems &&
        `
        align-items: ${alignItems};
    `}

    ${({ marginLeft, theme }) => {
        if (typeof marginLeft === "number") {
            // this has no media info
            return `margin-left: ${marginLeft}px;`;
        } else if (marginLeft) {
            const media: IMedia = getMedia(theme.sizes);
            return `
                ${media.desktop`margin-left: ${marginLeft![0]}px;`.join("")}
                ${media.tablet`margin-left: ${marginLeft![1]}px;`.join("")}
                ${media.phone`margin-left: ${marginLeft![2]}px;`.join("")}
                `;
        } else {
            return "";
        }
    }}

    ${({ marginTop, theme }) => {
        if (typeof marginTop === "number") {
            // this has no media info
            return `margin-top: ${marginTop}px;`;
        } else if (marginTop) {
            const media: IMedia = getMedia(theme.sizes);
            return `
                ${media.desktop`margin-top: ${marginTop![0]}px;`.join("")}
                ${media.tablet`margin-top: ${marginTop![1]}px;`.join("")}
                ${media.phone`margin-top: ${marginTop![2]}px;`.join("")}
                `;
        } else {
            return "";
        }
    }}

    ${({ margin, theme }) => {
        if (typeof margin === "string") {
            // this has no media info
            return `margin: ${margin};`;
        } else if (margin) {
            const media: IMedia = getMedia(theme.sizes);
            return `
                ${media.desktop`margin: ${margin![0]};`.join("")}
                ${media.tablet`margin: ${margin![1]};`.join("")}
                ${media.phone`margin: ${margin![2]};`.join("")}
                `;
        } else {
            return "";
        }
    }}

    ${({ padding, theme }) => {
        if (typeof padding === "string") {
            // this has no media info
            return `padding: ${padding};`;
        } else if (padding) {
            const media: IMedia = getMedia(theme.sizes);
            return `
                ${media.desktop`padding: ${padding![0]};`.join("")}
                ${media.tablet`padding: ${padding![1]};`.join("")}
                ${media.phone`padding: ${padding![2]};`.join("")}
                `;
        } else {
            return "";
        }
    }}

    ${({ backgroundColor }) =>
        backgroundColor &&
        `
        background-color: ${backgroundColor};
    `}

    ${({ color }) =>
        color &&
        `
        color: ${color};
    `}

    ${({ zIndex }) =>
        zIndex &&
        `
        z-index: ${zIndex};
    `}

    ${(props) => {
        if (props.border && typeof props.border === "string") {
            return `border: ${props.border};`;
        } else if (props.border) {
            const media: IMedia = getMedia(props.theme.sizes);
            return `
                ${media.desktop`border: ${props.border![0]};`.join("")}
                ${media.tablet`border: ${props.border![1]};`.join("")}
                ${media.phone`border: ${props.border![2]};`.join("")}
                `;
        } else {
            return ""; // no default
        }
    }}

    ${(props) => {
        if (props.borderBottom && typeof props.borderBottom === "string") {
            return `border-bottom: ${props.borderBottom};`;
        } else if (props.borderBottom) {
            const media: IMedia = getMedia(props.theme.sizes);
            return `
                ${media.desktop`border-bottom: ${props.borderBottom![0]};`.join(
                    ""
                )}
                ${media.tablet`border-bottom: ${props.borderBottom![1]};`.join(
                    ""
                )}
                ${media.phone`border-bottom: ${props.borderBottom![2]};`.join(
                    ""
                )}
                `;
        } else {
            return ""; // no default
        }
    }}

    ${(props) => {
        if (props.borderRadius && typeof props.borderRadius === "string") {
            return `border-radius: ${props.borderRadius};`;
        } else if (props.borderRadius) {
            const media: IMedia = getMedia(props.theme.sizes);
            return `
                ${media.desktop`border-radius: ${props.borderRadius![0]};`.join(
                    ""
                )}
                ${media.tablet`border-radius: ${props.borderRadius![1]};`.join(
                    ""
                )}
                ${media.phone`border-radius: ${props.borderRadius![2]};`.join(
                    ""
                )}
                `;
        } else {
            return ""; // no default
        }
    }}

    ${({ position }) =>
        position &&
        `
    position: ${position};
    `}

    ${({ pageBreakInside }) =>
        pageBreakInside &&
        `
    page-break-inside: ${pageBreakInside};
    `}

    ${({ height }) =>
        height &&
        `
        height: ${height};
    `}

    ${({ width, theme }) => {
        if (typeof width === "string") {
            // this has no media info
            return `width: ${width};`;
        } else if (width) {
            const media: IMedia = getMedia(theme.sizes);
            return `
                ${media.desktop`width: ${width![0]};`.join("")}
                ${media.tablet`width: ${width![1]};`.join("")}
                ${media.phone`width: ${width![2]};`.join("")}
                `;
        } else {
            return "";
        }
    }}

    ${({ minWidth }) =>
        minWidth &&
        `
        min-width: ${minWidth};
    `}

    ${({ minHeight }) =>
        minHeight &&
        `
        min-height: ${minHeight};
    `}

    ${({ maxWidth }) =>
        maxWidth &&
        `
        max-width: ${maxWidth};
    `}

    ${({ maxHeight }) =>
        maxHeight &&
        `
        max-height: ${maxHeight};
    `}

    ${({ float }) =>
        float &&
        `
        float: ${float};
    `}

    ${({ overflow }) =>
        overflow &&
        `
        overflow: ${overflow};
    `}

    ${({ overflow_x }) =>
        overflow_x &&
        `
        overflow-x: ${overflow_x};
    `}

    ${({ overflow_y }) =>
        overflow_y &&
        `
        overflow-y: ${overflow_y};
    `}

    ${({ fontSizeOrSizes, theme }) => {
        if (typeof fontSizeOrSizes === "number") {
            // no media info, in pixels
            return `font-size: ${fontSizeOrSizes}px;`;
        } else if (typeof fontSizeOrSizes === "string") {
            // no media info, in other unit
            return `font-size: ${fontSizeOrSizes};`;
        } else if (
            Array.isArray(fontSizeOrSizes) &&
            typeof fontSizeOrSizes[0] === "number"
        ) {
            const media: IMedia = getMedia(theme.sizes);
            return `
                ${media.desktop`font-size: ${fontSizeOrSizes![0]}px;`.join("")}
                ${media.tablet`font-size: ${fontSizeOrSizes![1]}px;`.join("")}
                ${media.phone`font-size: ${fontSizeOrSizes![2]}px;`.join("")}
                `;
        } else if (
            Array.isArray(fontSizeOrSizes) &&
            typeof fontSizeOrSizes[0] === "string"
        ) {
            const media: IMedia = getMedia(theme.sizes);
            return `
                ${media.desktop`font-size: ${fontSizeOrSizes![0]};`.join("")}
                ${media.tablet`font-size: ${fontSizeOrSizes![1]};`.join("")}
                ${media.phone`font-size: ${fontSizeOrSizes![2]};`.join("")}
                `;
        } else {
            return "";
        }
    }}

    ${({ areaName }) =>
        areaName &&
        `
        grid-area: ${areaName};
    `}

    ${({ alignSelf }) =>
        alignSelf &&
        `
    align-self: ${alignSelf};
`}

    ${({ justifySelf }) =>
        justifySelf &&
        `
    justify-self: ${justifySelf};
`}
`;

// Page Banner
interface IBannerProps {
    theme?: DefaultTheme;
    bannerText: string;
    areaName?: string;
    farItems?: any[];
}

// <FullWidthRow
//     backgroundColor={ props.theme!.pageBannerBackground }
//     rowHeight={ `${props.theme!.pageBannerSize * 2}px` }
//     heightMinMax={true}
//     areaName={ props.areaName }
//     >

const PageBannerUnstyled: React.SFC<IBannerProps> = (props) => (
    <Grid
        backgroundColor={props.theme!.pageBannerBackground}
        gtc="5fr 1fr"
        gc="1 / -1"
    >
        <GridItem
            fontSizeOrSizes={[60, 30, 18]}
            color={props.theme!.pageBannerColor}
            marginLeft={20}
            gc="1 / 2"
            fontFamily="macho"
        >
            {props.bannerText}
        </GridItem>
        {props.farItems ? (
            <GridItem gc="2 / 3">
                <CommandBar items={[]} farItems={props.farItems || []} />
            </GridItem>
        ) : (
            <></>
        )}
    </Grid>
);

export const PageBanner = withTheme(PageBannerUnstyled);

// Message Area
interface IMessageAreaProps {
    theme?: DefaultTheme;
    messageText: string;
}

const MessageBarUnstyled: React.SFC<IMessageAreaProps> = (props) => (
    <FullWidthRow>
        <GridItem>
            <MessageBar isMultiline={true}>
                {props.messageText}
                {props.children}
            </MessageBar>
        </GridItem>
    </FullWidthRow>
);

export const MessageArea = withTheme(MessageBarUnstyled);

// ImageContainer

interface IICProps extends IRelement {
    src: string;
}

const ImageTagStyled = styled("img")<IICProps>`
    object-fit: scale-down;
    max-width: 100%;
`;

export const ImageContainer: React.SFC<IICProps> = (props) => (
    <ImageTagStyled src={props.src} />
);

// PageGrid1
interface IPageGrid1Props extends IGridProps {
    theme?: DefaultTheme;
}

const UnThemedPageGrid1: React.SFC<IPageGrid1Props> = (props) => (
    <Grid
        gtc={props.theme!.pageGrid1.gtc}
        gtr={props.theme!.pageGrid1.gtr}
        gg={props.theme!.pageGrid1.gg}
        gta={props.theme!.pageGrid1.gta}
        margin={props.theme!.pageGrid1.margin}
        areaName={props.areaName}
    >
        {props.children}
    </Grid>
);

export const PageGrid1 = withTheme(UnThemedPageGrid1);

// PrintPageGrid
interface IPrintPageGridProps extends IGridProps {
    theme?: DefaultTheme;
}

const UnThemedPrintPageGrid: React.SFC<IPrintPageGridProps> = (props) => (
    <Grid
        gtc={props.theme!.printPageGrid.gtc}
        gtr={props.theme!.printPageGrid.gtr}
        gg={props.theme!.printPageGrid.gg}
        gta={props.theme!.printPageGrid.gta}
        margin={props.theme!.printPageGrid.margin}
        areaName={props.areaName}
    >
        {props.children}
    </Grid>
);

export const PrintPageGrid = withTheme(UnThemedPrintPageGrid);

// Box item for checking display grid

interface IDisplayTestItem {
    areaName?: string;
}

export const DisplayTestItem = styled("div")<IDisplayTestItem>`
    display: grid;
    border: 5px solid rgba(0, 0, 0, 0.03);
    border-radius: 3px;
    font-size: 35px;
    background-color: #ffc600;
    height: 400px;
    width: 200px;

    ${({ areaName }) =>
        areaName &&
        `
        grid-area: ${areaName};
    `}
`;

// Answerbox

const answerBoxSizeLookup = {
    answer_box_small: {
        areaName: "answer_inline",
        height: "100px",
        width: "100px",
    },
    answer_box_vsmall: {
        areaName: "answer_inline",
        height: "50px",
        width: "50px",
    },
    answer_box_wide_large: {
        // alignItems: "flex-start",
        areaName: "answer",
        height: "400px",
        width: "100%",
    },
    answer_box_wide_medium: {
        // alignItems: "flex-start",
        areaName: "answer",
        height: "200px",
        width: "100%",
    },
    answer_box_wide_small: {
        // alignItems: "flex-start",
        areaName: "answer",
        height: "100px",
        width: "100%",
    },
    answer_box_wide_xlarge: {
        // alignItems: "flex-start",
        areaName: "answer",
        height: "700px",
        width: "100%",
    },
};

interface IAnswerBoxProps {
    size: string;
}

export const AnswerBox = styled("div")<IAnswerBoxProps>`
    display: grid;
    justify-self: end;
    position: relative;
    page-break-inside: avoid;
    border: 1px solid lightblue;
    ${(props) => `grid-area: ${answerBoxSizeLookup[props.size].areaName};`}
    ${(props) => `height: ${answerBoxSizeLookup[props.size].height};`}
    ${(props) => `width: ${answerBoxSizeLookup[props.size].width};`}
`;
