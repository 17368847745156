const stringIsNullOrEmpty = (str) => {
    if (
        str !== null &&
        str !== undefined &&
        str.toString().trim().length !== 0
    ) {
        return false;
    } else {
        return true;
    }
};

const validateEmailAddress = (emailAddress) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(emailAddress);
};

// TODO - make this more robust using external library
const validateMobileNumber = (mobileNumber) => {
    const re = /^\+(?:[0-9]●?){6,14}[0-9]$/;
    return re.test(mobileNumber);
};

export { stringIsNullOrEmpty, validateEmailAddress, validateMobileNumber };
