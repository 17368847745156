import {
    faBatteryEmpty,
    faBatteryFull,
    faBatteryHalf,
    faBatteryQuarter,
    faBatteryThreeQuarters,
    faChevronCircleDown,
    faEye,
    faEyeSlash,
    faPlusSquare,
    faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

// import {
//     faFacebookSquare
// } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const additionalFaIcons = {
    icons: {
        "battery-empty": <FontAwesomeIcon icon={faBatteryEmpty} />,
        "battery-full": <FontAwesomeIcon icon={faBatteryFull} />,
        "battery-half": <FontAwesomeIcon icon={faBatteryHalf} />,
        "battery-quarter": <FontAwesomeIcon icon={faBatteryQuarter} />,
        "battery-three-quarters": (
            <FontAwesomeIcon icon={faBatteryThreeQuarters} />
        ),
        "fa-chevron-circle-down": (
            <FontAwesomeIcon icon={faChevronCircleDown} />
        ),
        "fa-eye": <FontAwesomeIcon icon={faEye} />,
        "fa-eye-slash": <FontAwesomeIcon icon={faEyeSlash} />,
        "fa-facebook-square": <FontAwesomeIcon icon={["fab", "facebook-f"]} />,
        "fa-plus-square": <FontAwesomeIcon icon={faPlusSquare} />,
        "fa-trash-alt": <FontAwesomeIcon icon={faTrashAlt} />,
    },
};
