/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import intl from "react-intl-universal";
import { find } from "lodash";

import asyncComponent from "app/AsyncComponent2";
// import { ResponsiveHandler, PageHandler } from "./Handlers";
import AppWithAuth from "./components/AppWithAuth";

import { Actions } from "redux/app_actions";
import { Languages } from "Resources";
import { IStore } from "redux/reducer_types";
import LegalTextPage from "app/LegalTextPage";
import { ClientProvider } from "providers/clientProvider";
// import DeleteThisComponent from "app/DeleteThis";

const AsyncOneMp4Public = asyncComponent(
    () => import("components/public_side/OneMp4Public")
);
const AsyncPublicVideo = asyncComponent(
    () => import("components/public_side/PublicVideo")
);
// const AsyncValidateWithToken = asyncComponent(() =>
//     import("components/admin/ValidateWithToken")
// );
// const AsyncSignUpSuccess = asyncComponent(() =>
//     import("components/admin/SignUp/Step02")
// );
const AsyncLegalPage = asyncComponent(
    () => import("components/admin/LegalPage")
);

// use query and get the client info ... but is apollo even set up yet?
// can it be set up earlier now?

const Routes = () => {
    // console.log("rendering Routes");
    const language = useSelector((state: IStore) => state.app.language);
    const dispatch = useDispatch();

    const setInitialLanguage = () => {
        let localeToUse = intl.determineLocale({
            urlLocaleKey: "lang",
            cookieLocaleKey: "lang",
        });

        let foundLanguageEntry = find(Languages.allLanguages, function (o) {
            return o.lang.indexOf(localeToUse) !== -1;
        });
        if (foundLanguageEntry) {
            localeToUse = foundLanguageEntry.lang[0];
        } else {
            localeToUse = Languages.defaultLangKey;
        }
        const variables = { language: localeToUse };
        dispatch(Actions.requestNewLanguage({ ...variables }));
    };

    useEffect(() => {
        setInitialLanguage();
    }, []);

    if (!language) {
        return <div>Loading language file...</div>;
    }

    // N.B. all routes other than token validation go through AppWithAuth
    // leaving this page in for future sign up capability

    return (
        <>
            {/* <PageHandler> */}
            {/* <ResponsiveHandler> */}
            <Switch>
                <Route
                    path="/public/:videoType/:eventType/:eventName"
                    component={AsyncPublicVideo}
                />
                <Route path="/publicmp4" children={<AsyncOneMp4Public />} />
                {/* <Route --> concept will need to be upgraded if this approach of a permanent livestream link is used; may be better to use the database, though
                    path="/livestream"
                    component={() => {
                        window.location.href = "https://v.ctage.com/4oq";
                        return null;
                    }}
                /> */}
                <Route path="/privacypolicy" children={<LegalTextPage />} />
                <Route path="/cookiepolicy" children={<LegalTextPage />} />
                <Route path="/termsofuse" children={<LegalTextPage />} />
                <Route
                    path="/fairprocessingnotice"
                    children={<LegalTextPage />}
                />
                <Route path="/about" children={<LegalTextPage />} />
                <Route path="/contact" children={<LegalTextPage />} />

                {/* <Route
                        path="/validate"
                        component={AsyncValidateWithToken}
                    /> */}
                {/* <Route
                        path="/signupsucccess"
                        component={AsyncSignUpSuccess}
                    /> */}
                {/* from Valearn: <Route path="/site/:lang?/:urlEmail?" component={AppWithAuth} /> */}
                <Route
                    path={`/legal/:side/:pageName`}
                    component={AsyncLegalPage}
                />

                {/* <Route path="/app" children={<AppWithAuth />} /> */}
                <Route
                    children={
                        <ClientProvider>
                            <AppWithAuth />
                        </ClientProvider>
                    }
                />
                {/* <Route children={<div>Ending in Routes now</div>} /> */}
                {/* <Route children={<DeleteThisComponent />} /> */}
            </Switch>
            {/* </ResponsiveHandler> */}
            {/* </PageHandler> */}
        </>
    );
};
export default Routes;
