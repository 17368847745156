import { Component } from "react";

interface IProps {
    [key: string]: any;
}

interface IState {
    component: any;
}

export default function asyncComponent(importComponent) {
    class AsyncComponent extends Component<IProps, IState> {
        constructor(props: IProps) {
            super(props);

            this.state = {
                component: null,
            };
        }

        async componentDidMount() {
            const { default: component } = await importComponent();

            this.setState({
                component: component,
            });
        }

        render() {
            const C = this.state.component;

            return C ? <C {...this.props} /> : null;
        }
    }

    return AsyncComponent;
}
