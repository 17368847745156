import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import {
    delay,
    map,
} from "rxjs/operators";

import { ActionTypes as AT } from "./app_actions";
import {
    IStore,
} from "./reducer_types";

interface INoPayloadForEpic {
    type: string;
    payload: {};
}

export const startMeasuringBandwidthEpic = (
    action$: ActionsObservable<INoPayloadForEpic>, state$: StateObservable<IStore>) =>
    action$.pipe(
        ofType(AT.START_MEASURING_BANDWIDTH),
        delay(10 * 1000),
        map(() => {
            return ({
                payload: {},
                type: AT.END_MEASURING_BANDWIDTH,
            });
        }),
);

export const endMeasuringBandwidthEpic = (
    action$: ActionsObservable<INoPayloadForEpic>, state$: StateObservable<IStore>) =>
    action$.pipe(
        ofType(AT.END_MEASURING_BANDWIDTH),
        delay(5 * 60 * 1000),
        map(() => {
            return ({
                payload: {},
                type: AT.CONSIDER_MEASURING_BANDWIDTH,
            });
        }),
);

export const considerMeasuringBandwidthEpic = (
    action$: ActionsObservable<INoPayloadForEpic>, state$: StateObservable<IStore>) =>
    action$.pipe(
        ofType(AT.CONSIDER_MEASURING_BANDWIDTH),
        map(() => {
            const now = Date.now();
            let actionType = AT.END_MEASURING_BANDWIDTH;  // will start another waiting round
            if (now - state$.value.app.bandwidthAsofdate > 3 * 60 * 1000 &&
                    now - state$.value.app.lastActivityTime < 10 * 60 * 1000) {
                actionType = AT.START_MEASURING_BANDWIDTH;
            } else if (now - state$.value.app.lastActivityTime > 10 * 60 * 1000) {
                actionType = AT.EPIC_COMPLETED
            }
            return ({
                payload: {},
                type: actionType,
            });
        }),
);
