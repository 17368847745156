// import { ItemProps } from design-components/AppSelect";

const DialogButtonTypes = {
    CloseOnly: "close-only",
    SubmitCancel: "submit-cancel",
    YesNo: "yes-no",
};

export { DialogButtonTypes };

export const financialScoreMapping = {
  1: "No scholarship needed",
  2: "Big budget",
  3: "Big budget",
  4: "Moderate budget",
  5: "Moderate budget",
  6: "Limited budget",
  7: "Limited budget",
  8: "Limited budget",
  9: "Limited budget",
};