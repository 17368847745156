/** @format */

import gql from "graphql-tag";

export const UPDATE_ROLE_PREFERENCES = gql`
    mutation setrolepreferences($input: UpdateUserRoleConfidentialInput!) {
        updateUserRoleConfidential(input: $input) {
            clientMutationId
        }
    }
`;
