import gql from "graphql-tag";

// import { IRoleEvent } from "app/graphql_types";
import { roleEventFields } from "app/graqhQlFragments";

export const UPDATE_ROLE_EVENT = gql`
    mutation updateRoleEvent($input: UpdateExistingRoleEventInput!) {
        updateExistingRoleEvent(input: $input) {
            roleEvent {
                ...roleEventFields
            }
        }
    }
    ${roleEventFields}
`;

// interface IResult {
//     updateExistingRoleEvent: {
//         roleEvent: IRoleEvent;
//     };
// }

export interface IVariables {
    input: {
        vRoleEventId: number;
        vDeleteRecord: boolean;
        vIndividualStats?: {
            [key: string]: string | number | string[] | number[] | boolean;
        };
        vJerseyNumber?: number | null;
        vComment?: string | null;
        vPositions?: string[] | null;
        vJerseyColor?: string | null;
        vProposedChangePending?: {
            [key: string]: string | number | string[] | number[] | boolean;
        };
        vJerseyAndPositionsApproved?: boolean | null;
        vRoleEventApproved?: boolean | null;
    };
}

// export default class MUpdateRoleEvent extends Mutation<IResult, IVariables> {}
