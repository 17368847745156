/** @format */

import { Intent } from "@blueprintjs/core";
import { quantileRank } from "simple-statistics";
import intl from "react-intl-universal";

import { IUserRole } from "app/graphql_types";
import { showToast } from "app/AppSupport/Utils";
import * as fromActions from "./app_actions";
import * as rt from "./reducer_types";

const xtx = (str: string, key?: string) => intl.get(key || str || "x") || str;

const getActiveRole = (roles, lastRoleId) => {
    const activeRoleList = roles.filter((role) => {
        return role.id === lastRoleId;
    });
    // choose first role if there is no currently active role provided that
    // has a match in roles; sometimes active roles become expired, for instance
    const activeRole = activeRoleList.length > 0 ? activeRoleList[0] : roles[0];
    return activeRole;
};

const bwtests = {
    high: {
        up: {},
        down: {
            tenMbquantile: ["gt", 0.75],
            fiveMbquantile: ["gt", 0.45],
            operator: "or",
            to: "med",
        },
    },
    med: {
        up: {
            tenMbquantile: ["lt", 0.7],
            operator: "or",
            to: "high",
        },
        down: {
            fiveMbquantile: ["gt", 0.5],
            operator: "or",
            to: "low",
        },
    },
    low: {
        up: {
            tenMbquantile: ["lt", 0.88],
            fiveMbquantile: ["lt", 0.3],
            operator: "or",
            to: "med",
        },
        down: {},
    },
};

const checkval = (testobj, fiveMbquantile, tenMbquantile) => {
    let tenval = false;
    let fiveval = false;

    if (testobj.tenMbquantile !== undefined) {
        if (testobj.tenMbquantile[0] === "gt") {
            tenval = tenMbquantile > testobj.tenMbquantile[1];
        } else {
            tenval = tenMbquantile < testobj.tenMbquantile[1];
        }
    }

    if (testobj.fiveMbquantile !== undefined) {
        if (testobj.fiveMbquantile[0] === "gt") {
            fiveval = fiveMbquantile > testobj.fiveMbquantile[1];
        } else {
            fiveval = fiveMbquantile < testobj.fiveMbquantile[1];
        }
    }

    if (testobj.operator === "and") {
        return tenval && fiveval;
    } else {
        return tenval || fiveval;
    }
};

//  rt.IStoreApp <-- errors show up if this is set on state (use any instead),
//  but is useful when making changes
export const appReducer = (
    state: any = {},
    action: fromActions.ActionsType
) => {
    switch (action.type) {
        case fromActions.ActionTypes.SAVE_USER_DATA: {
            const payload: rt.ISaveUserPayload = action.payload;
            const { user } = payload;
            return { ...state, user };
        }

        case fromActions.ActionTypes.SAVE_GROUP_UUID_LIST: {
            const payload: rt.ISaveGroupUuidList = action.payload;
            const { groupUuids } = payload;

            return { ...state, groupUuids };
        }

        case fromActions.ActionTypes.SAVE_GROUP_SHARE_TYPES: {
            const payload: rt.ISaveGroupShareTypes = action.payload;
            const { groupShareTypes } = payload;

            return { ...state, groupShareTypes };
        }

        case fromActions.ActionTypes.SAVE_ROLES_DATA: {
            const payload: rt.ISaveRolesData = action.payload;
            const { dbUser, roles, lastRoleId } = payload;
            const { activeRole } = state;
            // no need to remcompute an active role if one exists and is from the same user
            // SAVE_ROLES_DATA occurs often so would overwrite
            if (
                activeRole &&
                roles.map((role) => role.nodeId).includes(activeRole.nodeId)
            ) {
                return {
                    ...state,
                    dbUser,
                    roles,
                };
            } else {
                const newActiveRole = getActiveRole(roles, lastRoleId);
                return {
                    ...state,
                    activeRole: newActiveRole,
                    dbUser,
                    roles,
                };
            }
        }

        case fromActions.ActionTypes.CHOOSE_ROLE: {
            const payload: rt.IChooseRole = action.payload;
            const { roleId } = payload;
            const { roles } = state;
            const activeRole = getActiveRole(roles, roleId);

            return {
                ...state,
                activeRole,
                lastRoleId: roleId,
            };
        }

        case fromActions.ActionTypes.UPLOAD_PROGRESS_UPDATE: {
            const payload: rt.IUploadProgressUpdate = action.payload;
            const { loaded, total } = payload;
            const {
                fileUploadCount,
                fileUploadPercentage,
                fileUploadPrevTime,
                fileUploadPrevLoaded,
            } = state;
            if (
                fileUploadPrevLoaded === undefined ||
                loaded < fileUploadPrevLoaded
            ) {
                return {
                    ...state,
                    fileUploadCount: 1,
                    fileUploadPercentage: 0.0,
                    fileUploadPredictedFinish: undefined,
                    fileUploadPredictedMSecs: undefined,
                    fileUploadPrevLoaded: 0,
                    fileUploadPrevTime: new Date().getTime(),
                    fileUploadTotal: total,
                };
            } else if (
                Math.floor((fileUploadCount + 1) / 50) * 50 ===
                fileUploadCount + 1
            ) {
                // update predictions
                const newPercentage = (loaded * 1.0) / total;
                const newTime = new Date().getTime();
                const newPredictedMSecs =
                    ((1 - newPercentage) * (newTime - fileUploadPrevTime)) /
                    (newPercentage - fileUploadPercentage);
                if (newPercentage === 1.0) {
                    showToast("Upload completed", 0, Intent.SUCCESS);
                }
                return {
                    ...state,
                    fileUploadCount: fileUploadCount + 1,
                    fileUploadPercentage: newPercentage,
                    fileUploadPredictedFinish: newTime + newPredictedMSecs,
                    fileUploadPredictedMSecs: newPredictedMSecs,
                    fileUploadPrevTime: newTime,
                };
            } else {
                // save count only
                return {
                    ...state,
                    fileUploadCount: fileUploadCount + 1,
                };
            }
        }

        case fromActions.ActionTypes.SAVE_PLUS_CODE_DATA: {
            const payload: rt.ISavePlusCodeData = action.payload;
            const { enteredPlusCode, plus_code } = payload;

            const plusCode = {
                ...state.plusCode,
                [enteredPlusCode]: plus_code,
            };

            return {
                ...state,
                plusCode,
            };
        }

        case fromActions.ActionTypes.EPIC_COMPLETED: {
            const payload: rt.IEpicCompletedPayload = action.payload;
            if (
                payload.toastMessage &&
                payload.toastIntent &&
                payload.toastMs
            ) {
                showToast(
                    payload.toastMessage,
                    payload.toastMs,
                    payload.toastIntent
                );
            }
            return state;
        }

        case fromActions.ActionTypes.SET_NAVBAR_DISPLAY_SETTING: {
            const payload: rt.ISetNavbarDisplaySettingPayload = action.payload;
            return {
                ...state,
                navbarDisplaySetting: payload.navbarDisplaySetting,
            };
        }

        case fromActions.ActionTypes.SET_NAVBAR_LOGO_ENTITY_NODE_ID: {
            const payload: rt.ISetNavbarLogoEntityNodeIdPayload =
                action.payload;
            return {
                ...state,
                navbarLogoEntityNodeId: payload.navbarLogoEntityNodeId,
            };
        }

        case fromActions.ActionTypes.SET_WITH_HIGHLIGHTS_FLAG: {
            const payload: rt.ISetWithHighlightsFlagPayload = action.payload;
            return {
                ...state,
                withHighlights: payload.withHighlights,
            };
        }

        case fromActions.ActionTypes.SAVE_IP_DATA: {
            const payload: rt.ISaveIpDataPayload = action.payload;
            return {
                ...state,
                geoData: payload.response,
            };
        }

        case fromActions.ActionTypes.SAVE_RECAPTCHA_V3_SCORE: {
            const payload: rt.ISaveRecaptchaV3ScorePayload = action.payload;
            // console.log("recaptcha Score is", payload.score);
            return {
                ...state,
                recaptchaScore: payload.score,
            };
        }

        case fromActions.ActionTypes.SAVE_RECAPTCHA_V2_RESPONSE: {
            const payload: rt.ISaveRecaptchaV2ResponsePayload = action.payload;
            return {
                ...state,
                recaptchaV2Reponse: payload,
            };
        }

        case fromActions.ActionTypes.STORE_CURRENT_VIDEO_POSITION: {
            const payload: rt.IStoreCurrentVideoPositionPayload =
                action.payload;
            const { videoPositions } = state;
            const newVideoPositions = {
                ...videoPositions,
                ...payload,
            };
            return {
                ...state,
                videoPositions: newVideoPositions,
            };
        }

        case fromActions.ActionTypes.SAVE_ROLE_PREFERENCES: {
            const payload: rt.ISaveRolePreferencesPayload = action.payload;
            // only update preferences for active role (potential race condition check)
            if (
                state.roles &&
                state.activeRole &&
                payload.activeRoleId === state.activeRole.id
            ) {
                const updatedRole: IUserRole = JSON.parse(
                    JSON.stringify(state.activeRole)
                );
                updatedRole.userRoleConfidentialById.rolePreferences = {
                    ...updatedRole.userRoleConfidentialById.rolePreferences,
                    ...payload.newPreferences,
                };
                const oldRoles = state.roles.filter(
                    (r) => r.id !== updatedRole.id
                );

                const newRoles = [...oldRoles, updatedRole];

                return {
                    ...state,
                    activeRole: updatedRole,
                    roles: newRoles,
                };
                // epic will update the database
            } else {
                return state;
            }
        }

        case fromActions.ActionTypes.SET_TAG_FLAG: {
            const payload: rt.ISetTagFlagPayload = action.payload;
            return {
                ...state,
                tagFlag: payload.tagFlag,
            };
        }

        case fromActions.ActionTypes.CHANGE_NEXT_HIGHLIGHT: {
            return {
                ...state,
                changeNextHighlight:
                    state.changeNextHighlight === undefined
                        ? true
                        : !state.changeNextHighlight,
            };
        }

        case fromActions.ActionTypes.CHANGE_PREV_HIGHLIGHT: {
            return {
                ...state,
                changePrevHighlight:
                    state.changePrevHighlight === undefined
                        ? true
                        : !state.changePrevHighlight,
            };
        }

        case fromActions.ActionTypes.CHANGE_STOP_VIDEO: {
            return {
                ...state,
                changeStopVideo:
                    state.changeStopVideo === undefined
                        ? true
                        : !state.changeStopVideo,
            };
        }

        case fromActions.ActionTypes.SAVE_SHORTCUT_KEYS_DESC: {
            const payload: rt.ISaveShortcutKeysDesc = action.payload;
            return {
                ...state,
                shortcutKeysDescElements: payload.shortcutKeysDescElements,
            };
        }

        case fromActions.ActionTypes.REQUEST_VIEW: {
            const payload: rt.IRequestViewPayload = action.payload;
            return {
                ...state,
                viewRequest: payload,
            };
        }

        case fromActions.ActionTypes.SET_VIDEO_VIEWPOINTS: {
            const payload: rt.ISetVideoViewpointsPayload = action.payload;
            return {
                ...state,
                videoViewpoints: payload.videoViewpoints,
            };
        }

        case fromActions.ActionTypes.SET_ACTIVE_VIDEO_VIEWPOINT: {
            const payload: rt.ISetActiveVideoViewpointPayload = action.payload;
            return {
                ...state,
                activeVideoViewpoint: payload.activeVideoViewpoint,
            };
        }

        case fromActions.ActionTypes.TOGGLE_NEXT_HIGHLIGHT_REQUEST: {
            return {
                ...state,
                nextHighlightRequest:
                    state.nextHighlightRequest !== undefined
                        ? !state.nextHighlightRequest
                        : true,
            };
        }

        case fromActions.ActionTypes.TOGGLE_PREV_HIGHLIGHT_REQUEST: {
            return {
                ...state,
                prevHighlightRequest:
                    state.prevHighlightRequest !== undefined
                        ? !state.prevHighlightRequest
                        : true,
            };
        }

        case fromActions.ActionTypes.SAVE_CURRENT_TAGGING_VARS: {
            const payload: rt.ISaveCurrentTaggingVarsPayload = action.payload;
            return {
                ...state,
                taggingQueryVariables: payload.taggingQueryVariables,
            };
        }

        case fromActions.ActionTypes.SAVE_COOKIE_APPROVAL: {
            const payload: rt.ISaveCookieApprovalPayload = action.payload;
            return {
                ...state,
                cookiesApproved: payload.approved,
            };
        }

        case fromActions.ActionTypes.SAVE_EVENT_DATA_QUERY_EVENT_ID_LIST: {
            const payload: rt.ISaveEventIdListPayload = action.payload;
            return {
                ...state,
                eventDataQueryEventIdList: payload.eventDataQueryEventIdList,
            };
        }

        case fromActions.ActionTypes.SHOW_SERVICE_WORKER_MESSAGE: {
            const payload: rt.IShowServiceWorkerMessagePayload = action.payload;
            if (
                payload.toastMessage &&
                payload.toastIntent &&
                payload.toastMs
            ) {
                showToast(
                    payload.toastMessage,
                    payload.toastMs,
                    payload.toastIntent
                );
            }
            return state;
        }

        case fromActions.ActionTypes.SAVE_INITIAL_SCREEN_SIZE: {
            const payload: rt.ISaveInitialScreenSize = action.payload;
            return {
                ...state,
                initialScreenSize: {
                    height: payload.height,
                    width: payload.width,
                },
            };
        }

        case fromActions.ActionTypes.Q_IS_ACTIVE: {
            const payload: rt.IQActiveStatusPayload = action.payload;
            const qid = payload.qid;
            if (state.queues && state.queues[qid]) {
                // add to queue List
                const currentQueue = {
                    ...state.queues[qid],
                };
                currentQueue.active = true;
                const queues = {
                    ...state.queues,
                    [qid]: currentQueue,
                };
                return {
                    ...state,
                    queues,
                };
            } else if (state.queues) {
                // create new queue
                const queues = {
                    ...state.queues,
                };
                queues[qid] = {
                    active: true,
                    completedTasks: [],
                    failedTasks: [],
                    queueList: [],
                };
                return {
                    ...state,
                    queues,
                };
            } else {
                return {
                    ...state,
                    queues: {
                        [qid]: {
                            active: true,
                            completedTasks: [],
                            failedTasks: [],
                            queueList: [],
                        },
                    },
                };
            }
        }

        case fromActions.ActionTypes.Q_IS_INACTIVE: {
            const payload: rt.IQActiveStatusPayload = action.payload;
            const qid = payload.qid;
            if (state.queues && state.queues[qid]) {
                // add to queue List
                const currentQueue = {
                    ...state.queues[qid],
                };
                currentQueue.active = false;
                const queues = {
                    ...state.queues,
                    [qid]: currentQueue,
                };
                return {
                    ...state,
                    queues,
                };
            } else if (state.queues) {
                // create new queue
                const queues = {
                    ...state.queues,
                };
                queues[qid] = {
                    active: false,
                    completedTasks: [],
                    failedTasks: [],
                    queueList: [],
                };
                return {
                    ...state,
                    queues,
                };
            } else {
                return {
                    ...state,
                    queues: {
                        [qid]: {
                            active: false,
                            completedTasks: [],
                            failedTasks: [],
                            queueList: [],
                        },
                    },
                };
            }
        }

        case fromActions.ActionTypes.RESET_QUEUE: {
            const payload: rt.IQActiveStatusPayload = action.payload;
            const qid = payload.qid;
            if (state.queues && state.queues[qid]) {
                const currentQueue = {
                    active: false,
                    completedTasks: [],
                    failedTasks: [],
                    queueList: [],
                };
                const queues = {
                    ...state.queues,
                    [qid]: currentQueue,
                };
                return {
                    ...state,
                    queues,
                };
            }
            break;
        }

        case fromActions.ActionTypes.ADD_TO_QUEUE: {
            // @ts-ignore
            const payload: rt.IAddToQueuePayload = action.payload;
            const qid = payload.qid;
            if (state.queues && state.queues[qid]) {
                // add to queue List
                const currentQueue = {
                    ...state.queues[qid],
                };
                currentQueue.queueList.push(payload.action);
                const queues = {
                    ...state.queues,
                    [qid]: currentQueue,
                };
                return {
                    ...state,
                    queues,
                };
            } else if (state.queues) {
                // create new queue
                const queues = {
                    ...state.queues,
                };
                queues[qid] = {
                    active: false,
                    completedTasks: [],
                    failedTasks: [],
                    queueList: [payload.action],
                };
                return {
                    ...state,
                    queues,
                };
            } else {
                return {
                    ...state,
                    queues: {
                        [qid]: {
                            active: false,
                            completedTasks: [],
                            failedTasks: [],
                            queueList: [payload.action],
                        },
                    },
                };
            }
        }

        case fromActions.ActionTypes.ADD_MULTIPLE_TO_QUEUE: {
            const payload: rt.IAddMultipleToQueuePayload = action.payload;
            let queues = {
                ...state.queues,
            };
            payload.tasklist.forEach((t) => {
                const taction: rt.IQueueAction = t.action;
                const qid = t.qid;
                if (queues && queues[qid] && queues[qid].queueList) {
                    queues[qid].queueList.push(taction);
                } else if (queues && queues[qid]) {
                    queues[qid].queueList = [taction];
                } else if (queues) {
                    queues[qid] = {
                        active: false,
                        completedTasks: [],
                        failedTasks: [],
                        queueList: [taction],
                    };
                } else {
                    queues = {
                        [qid]: {
                            active: false,
                            completedTasks: [],
                            failedTasks: [],
                            queueList: [taction],
                        },
                    };
                }
            });
            return {
                ...state,
                queues,
            };
        }

        case fromActions.ActionTypes.Q_START_NEXT_TASK: {
            const payload: rt.IQStartTaskPayload = action.payload;
            const qid = payload.qid;
            const queue = {
                ...state.queues[qid],
            };
            const nextTask =
                queue && queue.queueList
                    ? queue.queueList.splice(0, 1)
                    : undefined;
            if (nextTask) {
                queue.executing = nextTask ? nextTask[0] : undefined;
                const queues = {
                    ...state.queues,
                    [qid]: queue,
                };
                return {
                    ...state,
                    queues,
                };
            }
            break;
        }

        case fromActions.ActionTypes.Q_TASK_COMPLETED:
        case fromActions.ActionTypes.Q_END_CHECKING_WITH_ERROR: {
            const payload = action.payload;
            // console.log(`reducer: completing task ${payload}`);
            const qid = payload.qid;
            const queue = {
                ...state.queues[qid],
            };
            if (queue.executing) {
                // console.log(`reducer: setting queue ${qid} to undefined`);
                if (action.type === fromActions.ActionTypes.Q_TASK_COMPLETED) {
                    if (queue.executing.uniqueId === action.payload.uniqueId) {
                        queue.completedTasks.push(queue.executing);
                        queue.executing = undefined;
                    } else {
                        console.log(`R Q_TASK_COMPLETED: request to complete action
                        (${action.payload.uniqueId}) for ${qid} that is not currently
                        executing`);
                    }
                } else {
                    queue.failedTasks.push(queue.executing);
                    queue.executing = undefined;
                    queue.queueList = [];
                }
            }
            const queues = {
                ...state.queues,
                [qid]: queue,
            };
            return {
                ...state,
                queues,
            };
        }

        case fromActions.ActionTypes.UPDATE_HIGHLIGHT_SEQ_DATA: {
            const payload: rt.IUpdateHSeqDataPayload = action.payload;
            const currData = {
                ...(state.hSequenceData || { showHOnly: false }),
                ...payload,
            };
            return {
                ...state,
                hSequenceData: currData,
            };
        }

        case fromActions.ActionTypes.SAVE_PREVIOUS_PLAYING_STATE: {
            const payload: rt.ISavePrevPlayingStatePayload = action.payload;
            const { prevPlayingState } = payload;

            return {
                ...state,
                prevPlayingState,
            };
        }

        case fromActions.ActionTypes.ESTIMATE_BANDWIDTH: {
            const payload: rt.IEstimateBandwidthPayload = action.payload;
            const { bandwidthEstimate } = payload;
            if (bandwidthEstimate === 500000 || bandwidthEstimate === 5000000) {
                // these values are provided as bandwidthEstimates but are not computations
                return state;
            } else {
                const bandwidthAsofdate = Date.now();
                const newBes = [
                    bandwidthEstimate,
                    ...(state.bandwidthEstimates || []).slice(
                        0,
                        process.env.REACT_APP_MAX_BANDWIDTH_OBS
                    ),
                ];
                // console.log(newBes)
                const tenMbquantile = quantileRank(newBes, 10000000);
                const fiveMbquantile = quantileRank(newBes, 5000000);

                const bandwidthGrade = state.bandwidthGrade;

                const tests = bwtests[bandwidthGrade || "med"];
                const gradeLevel = checkval(
                    tests.up,
                    fiveMbquantile,
                    tenMbquantile
                )
                    ? tests.up.to
                    : checkval(tests.down, fiveMbquantile, tenMbquantile)
                    ? tests.down.to
                    : bandwidthGrade;
                // if (process.env.NODE_ENV !== "production") {
                // console.log(`Bandwidth estimated at ${bandwidthEstimate} as of ${bandwidthAsofdate}. 5mb, 10mb quantile ranks are ${fiveMbquantile}, ${tenMbquantile}. Grade is ${gradeLevel}`);
                // }

                return {
                    ...state,
                    bandwidthAsofdate,
                    bandwidthEstimate,
                    bandwidthEstimates: newBes,
                    fiveMbquantile,
                    bandwidthGrade: gradeLevel,
                    tenMbquantile,
                };
            }
        }

        case fromActions.ActionTypes.SAVE_NEW_SYSTEM_HIGHLIGHT_SEQ: {
            const payload: rt.ISaveNewSystemHighlightSeqPayload =
                action.payload;
            const { newHsid } = payload;

            return {
                ...state,
                newHsid,
            };
        }

        case fromActions.ActionTypes.CURRENT_ROLE_EVENTS: {
            const payload: rt.ICurrentRoleEventsPayload = action.payload;
            const { currentRoleEvents } = payload;

            return {
                ...state,
                currentRoleEvents,
            };
        }

        case fromActions.ActionTypes.START_MEASURING_BANDWIDTH: {
            return {
                ...state,
                checkingDownloadSpeed: true,
            };
        }

        case fromActions.ActionTypes.END_MEASURING_BANDWIDTH: {
            return {
                ...state,
                checkingDownloadSpeed: false,
            };
        }

        case fromActions.ActionTypes.UPDATE_LAST_ACTIVITY_TIME: {
            const payload: rt.IUpdateLastActivityTime = action.payload;
            const { lastActivityTime } = payload;

            return {
                ...state,
                lastActivityTime,
            };
        }

        case fromActions.ActionTypes.SAVE_BROWSER_TYPE: {
            const payload: rt.ISaveBrowserType = action.payload;
            const { browserData } = payload;

            return {
                ...state,
                browserData,
            };
        }

        case fromActions.ActionTypes.FLAG_SEQUENCE: {
            const payload: rt.IFlagSequencePayload = action.payload;
            const { isASequence } = payload;

            return {
                ...state,
                isASequence,
            };
        }

        case fromActions.ActionTypes.SET_NEW_BW_GRADE: {
            const payload: rt.ISetNewBwGradePayload = action.payload;
            const { gradeLevel } = payload;

            console.log(`setting badwidth grade to ${gradeLevel}`);
            return {
                ...state,
                bandwidthGrade: gradeLevel,
            };
        }

        case fromActions.ActionTypes.TURN_ON_OR_OFF_KEYBOARD_COMMANDS: {
            const payload: rt.IOnOffKeyboardCommandsPayload = action.payload;
            const { keyboardCommandState } = payload;

            return {
                ...state,
                keyboardCommandState,
            };
        }

        case fromActions.ActionTypes.SET_ACTIVE_LANGUAGE: {
            const payload: rt.ISetCurrentLanguage = action.payload;
            return { ...state, language: payload.language };
        }

        case fromActions.ActionTypes.SAVE_RAW_COGNITO_USER: {
            const payload: rt.IRawCognitoUser = action.payload;
            let userFound = true;
            try {
                const returnedUsername =
                    payload.rawCognitoUser.challengeParam.USERNAME;
                if (
                    returnedUsername &&
                    returnedUsername.length === 36 &&
                    returnedUsername[8] === "-"
                ) {
                    userFound = false;
                    showToast(
                        xtx(
                            "User not found.  Please check the email address to see if it is correct and either, press 'Start over' to fix a misspelling or register if it is correct",
                            "signup.user_not_found"
                        ),
                        20000,
                        Intent.PRIMARY
                    );
                }
            } catch (e) {
                // allow it to continue
            }
            return {
                ...state,
                rawCognitoUser: payload.rawCognitoUser,
                userFound,
            };
        }

        case fromActions.ActionTypes.SAVE_AUTH_STATUS: {
            const payload: rt.ISaveAuthStatus = action.payload;
            return {
                ...state,
                authStatus: payload.authStatus,
                isGuest: payload.isGuest ? true : false,
            };
        }

        case fromActions.ActionTypes.SET_LOGIN_DETAILS: {
            const payload: rt.ILoginDetails = action.payload;
            return {
                ...state,
                loginDetails: payload,
            };
        }

        case fromActions.ActionTypes.SET_REGISTRATION_DETAILS: {
            const payload: rt.IRegistrationDetails = action.payload;
            return {
                ...state,
                registrationDetails: payload,
            };
        }

        case fromActions.ActionTypes.SAVE_CLIENT_DETAILS: {
            const payload: rt.ISaveClientDetails = action.payload;
            return {
                ...state,
                clientId: payload.clientId,
                clientType: payload.clientType,
            };
        }

        case fromActions.ActionTypes.SAVE_CURRENT_PLAYER: {
            const payload: rt.ISaveCurrentPlayer = action.payload;
            return {
                ...state,
                currentPlayer: payload.player,
            };
        }

        // case fromActions.ActionTypes.USE_APP_AS_GUEST: {
        //     const payload: rt.IUseAppAsGuest = action.payload;
        //     return {
        //         ...state,
        //         isGuest: payload.newValue,
        //     };
        // }

        default:
            return state;
    }
};

console.log(typeof appReducer);
