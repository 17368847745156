/** @format */

// from https://github.com/richardzcode/Journal-AWS-Amplify-Tutorial/blob/18107609685467514f0707b7c9830eef7c386eae/step-08/journal/src/store/AmplifyBridge.js
import { Auth, Hub } from "aws-amplify";

// import { switchUser, updateProfile, deleteProfile } from "./actions";
import { Actions } from "../../redux/app_actions";
import { prepareUserData, onEvent, onError } from "app/AppSupport/Utils";
import parentLogger from "app/utils/loggerService";
import { Intent } from "@blueprintjs/core";

const logger = parentLogger.child({
    module: "AmplifyBridge",
});

export default class AmplifyBridge {
    store: any = {};

    constructor(store) {
        // console.log("constructing AmplifyBridge");
        // @ts-ignore
        this.store = store;

        Hub.listen("auth", (data) => {
            const { payload } = data;
            // console.log(`got data in Hub: ${JSON.stringify(payload)}`);
            this.onAuthEvent(payload);
            if (payload.data && payload.data.username) {
                logger.debug(
                    `New auth event: "${payload.data.username}" has "${payload.event}"; `
                );
            } else {
                logger.debug(`A new auth event has happened: ${payload.event}`);
            }
        });
        this.onAuthEvent({});

        // this.onHubCapsule = this.onHubCapsule.bind(this);
        // const listener = (data) => {
        //     console.log(data.payload.event);

        //     switch (data.payload.event) {
        //         case "signIn":
        //             logger.error("user signed in"); //[ERROR] My-Logger - user signed in
        //             break;
        //         case "signUp":
        //             logger.error("user signed up");
        //             break;
        //         case "signOut":
        //             logger.error("user signed out");
        //             break;
        //         case "signIn_failure":
        //             logger.error("user sign in failed");
        //             break;
        //         case "configured":
        //             logger.error("the Auth module is configured");
        //             break;
        //         default:
        //             logger.debug("default");
        //     }
        //     this.checkUser(); // triggering each time
        // };
        // Hub.listen("auth", listener); // Add this component as a listener of auth events.
        // original working part
        // Hub.listen("auth", this, "AmplifyBridge");
        // this.checkUser(); // first check / initialisation
    }

    onAuthEvent(payload) {
        // console.log(
        //     `received auth event payload: "${JSON.stringify(payload)}"`
        // );
        if (payload.data?.code === "UserNotFoundException") {
            this.store.dispatch(
                Actions.showToast({
                    toastMessage:
                        "Email address or mobile provided not found.  Check the spelling and then click 'Start over' to correct it.  If it is correct, you will need to click on 'Click here for guest access instead' (this button will show up after about 15 seconds), but please get in touch and let us know you have had difficulties ",
                    toastIntent: Intent.WARNING,
                    toastMs: 0,
                })
            );
            onEvent(null, payload, "user-not-found");
        }
        this.checkUser(payload); // triggered every time user sign in / out
    }

    checkUser(payload) {
        Auth.currentAuthenticatedUser()
            .then((user) => this.checkUserSuccess(user, payload))
            .catch((err) => this.checkUserError(err, payload));
    }

    loadUserInfo(user) {
        Auth.currentUserInfo()
            .then((info) => this.loadUserInfoSuccess(user, info))
            .catch((err) => this.loadUserInfoError(user, err));
    }

    loadProfile(user) {
        Auth.userAttributes(user)
            .then((data) => this.loadProfileSuccess(data))
            .catch((err) => this.loadProfileError(err));
    }

    checkUserSuccess(user, payload) {
        logger.debug("check user success", user, payload);
        // console.log(
        //     `in AmplifyBridge checkUserSuccess, got user of ${JSON.stringify(
        //         user
        //     )}`
        // );
        this.store.dispatch(
            Actions.saveAuthProps({
                authProps: {
                    authState: "signedIn",
                    authData: prepareUserData(false, user),
                },
            })
        );
        // this.store.dispatch(Actions.requestNewLanguage({ language: "en" }));
        this.store.dispatch(Actions.saveAuthStatus({ authStatus: "signedIn" }));
        // this.loadUserInfo(user); // Defer store.dispatch to loadUserInfo
        // this.loadProfile(user);
    }

    checkUserError(err, payload) {
        logger.debug("check user error", err);
        onError(null, err, payload);
        this.store.dispatch(Actions.saveAuthStatus({ authStatus: "signIn" }));
        // this.store.dispatch(switchUser(null));
        // this.store.dispatch(deleteProfile());
    }

    loadUserInfoSuccess(user, info) {
        logger.debug("load user info success", user, info);
        // console.log(
        //     `in AmplifyBridge loadUserInfoSuccess, got user of ${JSON.stringify(
        //         user
        //     )}`
        // );
        Object.assign(user, info);
        Actions.saveAuthProps({
            authProps: {
                authState: "signedIn",
                authData: prepareUserData(false, user),
            },
        });
        Actions.saveAuthStatus({ authStatus: "signedIn" });
        // this.store.dispatch(switchUser(user));
    }

    loadUserInfoError(user, err) {
        logger.debug("load user info error", err);
        // this.store.dispatch(switchUser(user)); // Still dispatchs user object
    }

    loadProfileSuccess(data) {
        logger.debug("load profile success", data);
        // const profile = this.translateAttributes(data);
        // console.log(profile);
        // this.store.dispatch(updateProfile(profile));
    }

    loadProfileError(err) {
        logger.debug("load profile error", err);
        // this.store.dispatch(deleteProfile());
    }

    // Auth.userAttributes returns an array of attributes.
    // We map it to an object for easy use.
    translateAttributes(data) {
        const attributes = {};
        data.filter((attr) =>
            ["given_name", "family_name"].includes(attr.Name)
        ).forEach((attr) => (attributes[attr.Name] = attr.Value));
        return attributes;
    }
}
