import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import * as Sentry from "@sentry/browser";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import Zendesk, { ZendeskAPI } from "react-zendesk";
import WebfontLoader from "@dr-kobros/react-webfont-loader";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";

import Routes from "./Routes";
import { store } from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import AmplifyBridge from "./components/auth/AmplifyBridge";
import { HistoryWrapper } from "app/Configuration/BrowserHistory";

// import { BrowserRouter } from "react-router-dom";
// import ReactGA from "react-ga";
// import "pure-react-carousel/dist/react-carousel.es.css";

import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";

import "normalize.css/normalize.css";
import "app/blueprint-adj.css";
import "app/blueprint-datetime-adj.css";
import "./index.css";

// registering both Fabric icons and FA icons
import { registerIcons } from "@uifabric/styling";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import { additionalFaIcons } from "app/utils/fa_icons";
initializeIcons();
registerIcons(additionalFaIcons);

// import "sticky-react-table/lib/themes/light.scss";

// import "./app/blueprint-adj.css"; // difficult to use only with sass imports; possibly worth another try
// import "./app/blueprint-datetime-adj.css"; // is this used at all?

// import "./Resources/GlobalStyles.scss"; // TODO - see about where this should be applied, and in what order (last?)

// import "./ReactotronConfig";

new AmplifyBridge(store);

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
});

// const config = {
//     typekit: {
//         id: "dse1zon",
//     },
// };

const config = {
    google: {
        families: ["Source Sans Pro:300,600"],
    },
    typekit: {
        id: "cea3ryk",
    },
};

// ReactGA.initialize("UA-134846670-1", {
//   debug: false,
//   gaOptions: {
//       siteSpeedSampleRate: 100,
//   },
//   titleCase: false,
// });

const history = createBrowserHistory();
HistoryWrapper.init(history);

ReactDOM.render(
    <CookiesProvider>
        <Provider store={store}>
            <WebfontLoader config={config}>
                <Router history={history}>
                    <Routes />
                    <Zendesk
                        zendeskKey={
                            process.env.REACT_APP_ZENDESK_KEY ||
                            "no_key_provided"
                        }
                        onLoaded={() => {
                            // console.log("Zendesk is loaded");
                            ZendeskAPI("webWidget", "hide");
                        }}
                    />
                </Router>
            </WebfontLoader>
        </Provider>
    </CookiesProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register(serviceWorkerConfig);
serviceWorker.unregister();
