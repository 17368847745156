/** @format */

// see create_translation_files.sh for generation of the languages json, including the language_list
import allLanguagesSource from "../language_list.json";

export const allLanguages = allLanguagesSource;

const defaultLanguages = allLanguages.filter((l) => l.isDefault);
export const defaultLangKey =
    defaultLanguages.length > 0 ? defaultLanguages[0].lang[0] : "en";

export const allLanguagesList = allLanguages
    .sort((a, b) => (a.name > b.name ? 1 : a.name === b.name ? 0 : -1))
    .map((el) => el.lang[0]);
