import gql from "graphql-tag";

// import { IRoleEvent } from "app/graphql_types";
import { roleEventFields } from "app/graqhQlFragments";
import { IPlayerSummary } from "./MAddTeamSheet";

// N.B. this function handles updates as well as new records
export const ADD_ROLE_EVENTS = gql`
    mutation addRoleEventsCall($input: AddRoleEventsInput!) {
        addRoleEvents(input: $input) {
            roleEvents {
                ...roleEventFields
            }
        }
    }
    ${roleEventFields}
`;

// interface IMAddRoleResults {
//     addRoleEvents: {
//         roleEvents: {
//             nodes: IRoleEvent[];
//         };
//     };
// }

export interface IMAddRoleEventsVars {
    input: {
        vEventId: number;
        vRoleDataJsonList: IPlayerSummary[];
    };
}

// export default class MAddRoleEvents extends Mutation<
//     IMAddRoleResults,
//     IMAddRoleEventsVars
// > {}
