import { DefaultTheme } from "styled-components";

// const sizes = {
//     desktop: { min: 1200, max: 99999 },

//     tablet: { min: 992, max: 1199 },

//     phone: { min: 0, max: 991 },
// };

const sizes = {
    desktop: { min: 1350, max: 99999 },

    tablet: { min: 769, max: 1349 },

    phone: { min: 0, max: 768 },
};
export const theme: DefaultTheme = {
    backgroundColor: "white",
    bordershadow20: "rgba(16, 22, 26, 0.2)",
    bordershadow40: "rgba(16, 22, 26, 0.4)",
    dateFormat: "LL",
    fontFamily: "macho, Museo-Sans, Helvetica Neue, sans-serif",
    largeFontSize: 20,
    linkColor: "blue",
    name: "Default theme",
    oppositeColor: "white",
    paddingTop: "50px",
    pageBannerBackground: "rgb(3, 131, 135)",
    pageBannerColor: "#fcfcfc",
    pageBannerSize: 40,
    pageBannerSize_desktop: 40,
    pageBannerSize_phone: 18,
    pageGrid1: {
        gg: "10px",
        gta: [["banner banner",
              "messages messages",
              "nav content"],
              ["banner banner",
              "messages messages",
              "nav content"],
              ["banner",
              "messages",
              "nav",
              "content"]],
        gtc: ["auto 1fr", "auto 1fr", "1fr"],
        gtr: ["auto auto 1fr", "auto auto 1fr", "auto auto auto auto"],
        margin: ["0 2%", "0 2%", "0 5px"],
    },
    printPageGrid: {
        gg: "10px",
        gta: ["title",
              "subject",
              "otherdetail",
              "content"],
        gtc: "1fr",
        gtr: "auto auto 1fr",
        margin: "0 2%",
    },
    questionResultColors: {
        r1: "red",
        r2: "red",
        r3: "pink",
        r4: "brown",
        r5: "lightblue",
        r6: "greenyellow",
        r7: "green",
    },
    secondLinkColor: "lightblue",
    sizes,
    smallFontSize: 11,
    textColor: "#10161A",
    unapprovedLinkColor: "black",
    unpublishedLinkColor: "orange",
};

export const darkTheme: DefaultTheme = {
    ...theme,
    backgroundColor: "#10161A",
    bordershadow20: "rgba(190, 190, 190, 0.2)",
    bordershadow40: "rgba(190, 190, 190, 0.4)",
    linkColor: "yellow",
    name: "Dark theme",
    oppositeColor: "black",
    secondLinkColor: "#EEF29B",
    textColor: "white",
    unapprovedLinkColor: "white",
    unpublishedLinkColor: "orange",
};

export const raiseTheme: DefaultTheme = {
    ...theme,
    fontFamily: "miller-display, serif;",
    fontWeight: 300,
    largeFontSize: 55,
    lineHeight: "1.6",
    smallFontSize: 21,
    textColor: "rgba(0,0,0,0.84)",
};
