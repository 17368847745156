/** @format */

export const protectedFileFields = `
fragment protectedFileFields on ProtectedFile {
    id
    nodeId
    actualFilename
    s3Filename
    ctUser {
        identityId
    }
}
`;

export const leagueFields = `
fragment leagueFields on League {
    id
    nodeId
    leagueName
    country
    region
    isGeneric
    leagueDescription
}
`;

// requires protectedFileFields
export const entityFields = `
fragment entityFields on Entity {
    id
    nodeId
    entityName
    entityCategory
    mainColour
    plusCode
    scoreboardName
    logoFile {
        ...protectedFileFields
    }
}
`;

// requires entityFields, protectedFileFields
export const teamFields = `
fragment teamFields on Team {
    id
    nodeId
    teamName
    teamType
    ageGroup
    season
    scoreboardNameOverride
    entity {
        ...entityFields
    }
}
`;

// requires teamFields, entityFields, protectedFileFields
export const privateRoleFields = `
fragment privateRoleFields on CtUserRole {
    nodeId
    id
    roleDescription
    publicName
    confirmedByUser
    userRole
    lastUserRole
    expired
    userRoleConfidentialById {
        roleStartDate
        roleEndDate
        rolePreferences
        recruiterCategory
        recruitingIntoCountries
        recruitingIntoCompetitionGroups
    }
    ctUser {
        id
        cognitoSub
        userConfidentialById {
            accessIds
        }
    }
    entity {
        ...entityFields
    }
    team {
        ...teamFields
    }
}
`;

// requires teamFields, entityFields, protectedFileFields
export const publicRoleFields = `
fragment publicRoleFields on CtUserRole {
    id
    nodeId
    publicName
    userRole
    roleDescription
    entity {
        ...entityFields
    }
    team {
        ...teamFields
    }
}
`;

// requires teamFields, publicRoleFields, entityFields, protectedFileFields
export const trainingFields = `
fragment trainingFields on Training {
    id
    nodeId
    description
    pitch
    qualityOfPitch
    team {
        ...teamFields
    }
}
`;

// requires leagueFields, teamFields
export const teamLeagueFields = `
fragment teamLeagueFields on TeamLeague {
    id
    nodeId
    teamLeagueDesc
    league {
        ...leagueFields
    }
    team {
        ...teamFields
    }
}
`;

// requires teamLeagueFields, leagueFields, teamFields
export const gameFields = `
fragment gameFields on Game {
    id
    nodeId
    goalTimesApproved
    finalScoreData
    isHomeGame
    teamColour
    oppositionColour
    pitch
    qualityOfPitch
    matchComment
    isForScouting
    teamLeague {
        ...teamLeagueFields
    }
    oppositionTeam {
        ...teamFields
    }
    scoutedTeam {
        ...teamFields
    }
    scoutedForTeam {
        ...teamFields
    }
}
`;

// requires teamFields, publicRoleFields, entityFields, protectedFileFields
export const otherVideoEventFields = `
fragment otherVideoEventFields on OtherVideoEvent {
    id
    nodeId
    description
    team {
        ...teamFields
    }
    entity {
        ...entityFields
    }
    otherVideoEventRoles {
        nodes {
            role {
                ...publicRoleFields
            }
        }
    }
}
`;

// requires publicRoleFields, entityFields, gameFields,
// teamLeagueFields, leagueFields, teamFields, trainingFields,
// otherVideoEventFields, protectedFileFields
export const eventFields = `
fragment eventFields on Event {
    id
    nodeId
    eventTimeUtc
    ownerUuids
    temperatureC
    weather
    altitude
    plusCodeOverride
    comment
    ctUserRoleByRoleOwner {
        ...publicRoleFields
    }
    roleEvents {
        nodes {
            ...roleEventFields
        }
    }
    game {
        ...gameFields
    }
    training {
        ...trainingFields
    }
    otherVideoEvent {
        ...otherVideoEventFields
    }
}
`;

export const teamEventHighlightFields = `
fragment teamEventHighlightFields on TeamEventHighlight {
	id
    nodeId
    eventId
    highlightTimeMs
    specifiedAdvanceMs
    specifiedDuration
    caption
    quality
    viewName
    playbackRates
    highlightSubCats
    inProcess
    isApproved
    showAsHighlight
    aclWrite
    highlightCategory {
        categoryName
        color
        superCategory
        scoreboardDesignation
        color
    }
}
`;

export const roleEventFields = `
fragment roleEventFields on RoleEvent {
    id
    nodeId
    eventId
    individualStats
    jerseyNumber
    jerseyColor
    comment
    personalComment
    positions
    proposedChangePending
    roleEventApproved
    jerseyAndPositionsApproved
    role {
        id
        publicName
    }
}
`;

export const eventVideoFields = `
fragment eventVideoFields on Event {
    id
    eventTimeUtc
    weather
    temperatureC
    altitude
    plusCodeOverride
    comment
    roleEvents {
        nodes {
            ...roleEventFields
        }
    }
    videos {
        nodes {
            id
            nodeId
            cloudfrontUrlMapByHlsBucket {
                cloudfrontUrl
            }
            hlsFolder
            hlsPlaylist
            s3BucketGuid
            videoCat
            noaudio
            camera
            isHighlightsOnly
            srcframerate
            srcheight
            srcwidth
            versionName
            isActive
        }
    }
    otherVideoEvent {
        entity {
            entityName
        }
        team {
            teamName
        }
        description
    }
    training {
        pitch
        qualityOfPitch
        description
        team {
            teamName
        }
    }
    game {
        matchComment
        pitch
        qualityOfPitch
        isHomeGame
        isForScouting
        goalTimesApproved
        finalScoreData
        teamColour
        oppositionColour
        oppositionTeam {
            scoreboardNameOverride
            entity {
                scoreboardName
            }
        }
        scoutedTeam {
            scoreboardNameOverride
            entity {
                scoreboardName
            }
        }
        teamLeague {
            team {
                scoreboardNameOverride
                entity {
                    scoreboardName
                }
            }
        }
    }
}
`;

export const highlightCategoryFields = `
fragment highlightCategoryFields on HighlightCategory {
    id
    nodeId
    categoryName
    color
    superCategory
    scoreboardDesignation
    captionTemplate
    captionAdvanceMs
    captionDurationMs
    relevantToGk
    relevantToOutfield
    relevantToTeam
    relevantWhileClientInPossession
    relevantWhileOppInPossession
    relevantToShootoutOnly
    inactive
    defaultQuality
    buttonDisplaySize
    buttonDisplayOrder
    subCategories
}
`;

export const videoListFields = `
fragment videoListFields on Event {
    id
    nodeId
    eventTimeUtc
    comment
    temperatureC
    weather
    altitude
    plusCodeOverride
    individualHighlightSummary
    teamHighlightSummary
    videosCount
    roleEvents {
        nodes {
            ...roleEventFields
        }
    }
    videos {
        nodes {
            id
            nodeId
            cloudfrontUrlMapByHlsBucket {
                cloudfrontUrl
            }
            cloudfrontUrlMapByDashBucket {
                cloudfrontUrl
            }
            cloudfrontUrlMapByMp4Bucket {
                cloudfrontUrl
            }
            hlsBucket
            hlsFolder
            hlsPlaylist
            dashBucket
            dashFolder
            dashPlaylist
            mp4Bucket
            mp4Folder
            mp4Filename
            s3BucketGuid
            videoCat
            noaudio
            camera
            isHighlightsOnly
            srcframerate
            srcheight
            srcwidth
            versionName
            isActive
        }
    }
    otherVideoEvent {
        description
        shortName
        team {
            teamName
            entity {
                nodeId
                logoFile {
                    ...protectedFileFields
                }
            }
        }
        entity {
            nodeId
            entityName
            logoFile {
                ...protectedFileFields
            }
        }
    }
    training {
        description
        shortName
        pitch
        qualityOfPitch
        team {
            teamName
            entity {
                nodeId
                logoFile {
                    ...protectedFileFields
                }
            }
        }
    }
    game {
        goalTimesApproved
        finalScoreData
        isHomeGame
        matchComment
        pitch
        qualityOfPitch
        isForScouting
        teamColour
        oppositionColour
        noScoreboard
        scoutedTeam {
            teamName
            scoreboardNameOverride
            entity {
                scoreboardName
                logoFile {
                    ...protectedFileFields
                }
            }
        }
        scoutedForTeam {
            teamName
            scoreboardNameOverride
            entity {
                scoreboardName
                nodeId
            }
        }
        teamLeague {
            league {
                leagueName
            }
            team {
                teamName
                scoreboardNameOverride
                entity {
                    nodeId
                    scoreboardName
                    logoFile {
                        ...protectedFileFields
                    }
                }
            }
        }
        oppositionTeam {
            teamName
            scoreboardNameOverride
            entity {
                scoreboardName
                logoFile {
                    ...protectedFileFields
                }
            }
        }
    }
}
`;

export const retrievedHighlightFields = `
fragment retrievedHighlightFields on RelevantHighlight {
    id
    nodeId
    eventId
    roleId: ctUserRoleId
    publicName
    highlightTimeMs
    specifiedAdvanceMs
    specifiedDuration
    caption
    quality
    viewName
    playbackRates
    unalteredPlaybackRates
    unalteredSpecifiedAdvanceMs
    unalteredSpecifiedDuration
    highlightSubCats
    inProcess
    isApproved
    showAsHighlight
    aclWrite
    highlightCategoryId
    createdByRole
    hasWriteAccess
}
`;

export const retrievedHighlightFieldsNoNodeId = `
fragment retrievedHighlightFieldsNoNodeId on RelevantHighlight {
    id
    eventId
    roleId: ctUserRoleId
    publicName
    highlightTimeMs
    specifiedAdvanceMs
    specifiedDuration
    caption
    quality
    viewName
    playbackRates
    unalteredPlaybackRates
    unalteredSpecifiedAdvanceMs
    unalteredSpecifiedDuration
    highlightSubCats
    inProcess
    isApproved
    showAsHighlight
    aclWrite
    highlightCategoryId
    createdByRole
    hasWriteAccess
}
`;

export const teamHighlightFields = `
fragment teamHighlightFields on TeamEventHighlight {
    id
    nodeId
    eventId
    highlightTimeMs
    specifiedAdvanceMs
    specifiedDuration
    caption
    quality
    viewName
    playbackRates
    highlightSubCats
    inProcess
    isApproved
    showAsHighlight
    aclWrite
    highlightCategoryId
    createdByRole
}
`;

export const individualHighlightFields = `
fragment individualHighlightFields on IndividualEventHighlight {
	id
    nodeId
    eventId
    roleId: ctUserRoleId
    highlightTimeMs
    specifiedAdvanceMs
    specifiedDuration
    caption
    quality
    viewName
    playbackRates
    highlightSubCats
    inProcess
    isApproved
    showAsHighlight
    aclWrite
    highlightCategory {
        categoryName
        color
        superCategory
        scoreboardDesignation
        color
    }
}
`;

// requires protectedFileFields
export const highlightSequenceFields = `
fragment highlightSequenceFields on HighlightSequence {
    id
    nodeId
    sequenceLabel
    sequenceDesc
    quality
    sequenceCharacteristics
    forTeamId
    forRoleId
    forUserId
    forUser {
        ctUserRoles {
            nodes {
                id
            }
        }
    }
    systemHighlightSequenceId
    isApproved
    isPublished
    doNotDisplay
    isMainIntro
    createdByRole
    cloudfrontUrl
    s3BucketGuid
    s3Subfolder
    mp4Filename
    roleOwner
    aclWrite
    ownerUuids
    nonInteractiveOnly
    imageProtectedFile {
        ...protectedFileFields
    }
}
`;

export const highlightLikeEventFields = `
fragment highlightLikeEventFields on HighlightLikeEvent {
    id
    nodeId
    teamEventHighlightId
    individualEventHighlightId
    ratedByRole
    isAFavourite
    qualityScore
    likeHighlight
}
`;

export const highlightCommentFields = `
fragment highlightCommentFields on HighlightComment {
    id
    nodeId
    teamEventHighlightId
    individualEventHighlightId
    commentTimeMs
    commentTitle
    additionalComments
    markedHidden
}
`;
