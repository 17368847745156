import gql from "graphql-tag";

import {
    IAvailableHighlightDataRecord,
    IVideoList,
} from "app/graphql_types";
import {
    protectedFileFields,
    roleEventFields,
    videoListFields,
} from "app/graqhQlFragments";

// N.B. the plpgsql function just returns all of the events in the id list; it does nothing with role ids
export const EVENT_DATA_BY_EVENT_IDS_OR_SEQ = gql`
    query eventDataByEventIdsOrSeq(
        $vEventIdList: [Int]
        $vHighlightSequenceId: Int
        $vQualityThreshold: Int
    ) {
        getRoleEventsByListOrSequence(
            vEventIdList: $vEventIdList
            vHighlightSequenceId: $vHighlightSequenceId
        ) {
            nodes {
                ...videoListFields
            }
        }
        getHighlightsCountEventIdList(
            vEventIdList: $vEventIdList
            vHighlightSequenceId: $vHighlightSequenceId
            vQualityThreshold: $vQualityThreshold
        ) {
            nodes {
                eventId
                highlightType
                approvedCount
                unapprovedCount
            }
        }
    }
    ${protectedFileFields}
    ${roleEventFields}
    ${videoListFields}
`;

// different here because of use with graphql HOC
export interface IResult {
    getRoleEventsByListOrSequence: {
        nodes: IVideoList[];
    };
    getHighlightsCountEventIdList: {
        nodes: IAvailableHighlightDataRecord[];
    };
    loading: boolean;
    error?: any;
}

export interface IVariables {
    vEventIdList?: number[];
    vHighlightSequenceId?: number;
    vQualityThreshold?: number;
}

// export default class QEventDataByEventIdList extends Query<
//     IResult,
//     IVariables
// > {}
