import gql from "graphql-tag";

import { IReplaceHSItems } from "redux/reducer_types";

export const REPLACE_HIGHLIGHT_SEQUENCE_ITEMS = gql`
    mutation replaceAllItems($input: ReplaceHighlightSequenceItemsInput!) {
        replaceHighlightSequenceItems(input: $input) {
            clientMutationId
        }
    }
`;

// interface IResult {
//     clientMutationId: null;
// }

export interface IVariables {
    input: IReplaceHSItems;
}

// export default class MUpdateHighlight extends Mutation<IResult, IVariables> {}
