/** @format */

import * as React from "react";

import { HTMLTable } from "@blueprintjs/core";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { createGlobalStyle, ThemeProvider } from "styled-components";

import { Grid, GridItem } from "design-components/CSSGridElements";
import {
    LegalMainHeading,
    LegalSectionHeading,
    LegalText,
} from "design-components/LegalComponents";
import { raiseTheme } from "design-components/theme";

// N.B. all GlobalStyles need to have the same elements or odd things seem to happen
const GlobalStyle = createGlobalStyle`
  body {
    background: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.textColor};
    font-family: ${(props) => props.theme.fontFamily};
    padding-top: ${(props) => props.theme.paddingTop};
    width: "100vw";
    height: "100vh";
    lineHeight: 1.28581;
  }
`;

interface IContentItem {
    type: "paragraph" | "textlist" | "table";
    value?: string;
    listtype?: "bulleted" | "numbered";
    items?: string[];
    headerColumns?: string[];
    numcolumns?: number;
    rows?: [
        {
            col0?: string;
            col1?: string;
            col2?: string;
            col3?: string;
            col4?: string;
            col5?: string;
            col6?: string;
            col7?: string;
            col8?: string;
            col9?: string;
            col10?: string;
        }
    ];
}

interface ISection {
    sectionHeading: string;
    content: IContentItem[];
}

interface ITextPageData {
    name: string;
    data: ISection[];
    lastUpdated: string;
}

interface IProps {
    pageSource: string;
}

interface IState {
    textPageData?: ITextPageData;
}

// @ts-ignore
// declare module "*.json" {
//     const value: ITextPageData;
//     export default value;
// }

class LegalTextPage extends React.PureComponent<
    IProps & RouteComponentProps,
    IState
> {
    constructor(props: IProps & RouteComponentProps) {
        super(props);
        this.state = {
            textPageData: undefined,
        };
    }

    public async componentDidMount() {
        ReactGA.event({
            action: "Opened PrivacyPolicy section",
            category: "Legal",
        });
        const pageSource = this.props.location.pathname.replace("/", "");
        // N.B. can't provide name as variable to dynamic import
        let textPageData;
        if (pageSource === "privacypolicy") {
            textPageData = await import("./privacyPolicy.json");
        } else if (pageSource === "cookiepolicy") {
            textPageData = await import("./cookiePolicy.json");
        } else if (pageSource === "termsofuse") {
            textPageData = await import("./termsofuse-2.json");
        } else if (pageSource === "fairprocessingnotice") {
            textPageData = await import("./fairProcessingNotice.json");
        } else if (pageSource === "contact") {
            textPageData = await import("./contact.json");
        } else if (pageSource === "about") {
            textPageData = await import("./about.json");
        }
        // @ts-ignore
        this.setState({ textPageData });
    }

    public render() {
        // // const { pageSource } = this.props;
        // return <></>;
        const { textPageData } = this.state;
        if (!textPageData) {
            return <></>;
        }
        return (
            // @ts-ignore
            <ThemeProvider theme={raiseTheme}>
                <React.Fragment>
                    <Helmet>
                        <title>{textPageData.name}</title>
                    </Helmet>
                    <GlobalStyle />
                    <Grid
                        gg="20px"
                        gtc={["30px 1fr 30px", "30px 1fr 30px", "1fr"]}
                        gtr={"auto"}
                        gta={[
                            [
                                ". title .",
                                ". main .",
                                ". lastupdated .",
                                ". footer  .",
                            ],
                            [
                                ". title  .",
                                ". main .",
                                ". lastupdated .",
                                ". footer  .",
                            ],
                            ["title", "main", "lastupdated", "footer"],
                        ]}
                    >
                        <GridItem areaName="title">
                            <LegalMainHeading>
                                {textPageData.name}
                            </LegalMainHeading>
                        </GridItem>
                        <Grid areaName="main" gtc="1fr" gtr="auto" gaf="row">
                            {textPageData.data.map((section, i) => {
                                return (
                                    <GridItem key={i}>
                                        <LegalSectionHeading>
                                            {section.sectionHeading}
                                        </LegalSectionHeading>
                                        <>
                                            {section.content.map((part, j) => {
                                                if (part.type === "paragraph") {
                                                    return (
                                                        <LegalText key={j}>
                                                            {part.value}
                                                        </LegalText>
                                                    );
                                                } else if (
                                                    part.type === "textlist" &&
                                                    part.listtype === "bulleted"
                                                ) {
                                                    return (
                                                        <ul key={j}>
                                                            {part.items!.map(
                                                                (item, m) => (
                                                                    <li key={m}>
                                                                        {item}
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    );
                                                } else if (
                                                    part.type === "textlist" &&
                                                    part.listtype === "numbered"
                                                ) {
                                                    return (
                                                        <ol key={j}>
                                                            {part.items!.map(
                                                                (item, m) => (
                                                                    <li key={m}>
                                                                        {item}
                                                                    </li>
                                                                )
                                                            )}
                                                        </ol>
                                                    );
                                                } else if (
                                                    part.type === "table"
                                                ) {
                                                    return (
                                                        <HTMLTable
                                                            key={j}
                                                            bordered={true}
                                                            striped={true}
                                                        >
                                                            {part.headerColumns ? (
                                                                <thead>
                                                                    <tr>
                                                                        {part.headerColumns.map(
                                                                            (
                                                                                hc,
                                                                                l
                                                                            ) => (
                                                                                <th
                                                                                    key={`th${l}`}
                                                                                >
                                                                                    {
                                                                                        hc
                                                                                    }
                                                                                </th>
                                                                            )
                                                                        )}
                                                                    </tr>
                                                                </thead>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <tbody>
                                                                <>
                                                                    {part.rows!.map(
                                                                        (
                                                                            row,
                                                                            m
                                                                        ) => {
                                                                            const colArray =
                                                                                Array(
                                                                                    part.numcolumns
                                                                                ).fill(
                                                                                    1
                                                                                );
                                                                            return (
                                                                                <tr
                                                                                    key={`tr${m}`}
                                                                                >
                                                                                    {colArray.map(
                                                                                        (
                                                                                            _,
                                                                                            k
                                                                                        ) => {
                                                                                            if (
                                                                                                true
                                                                                            ) {
                                                                                                return (
                                                                                                    <td
                                                                                                        key={`tr${m}td${k}`}
                                                                                                    >
                                                                                                        {
                                                                                                            row[
                                                                                                                `col${k}`
                                                                                                            ]
                                                                                                        }
                                                                                                    </td>
                                                                                                );
                                                                                            } else {
                                                                                                return (
                                                                                                    <>

                                                                                                    </>
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    )}
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                                </>
                                                            </tbody>
                                                        </HTMLTable>
                                                    );
                                                } else {
                                                    return <></>;
                                                }
                                            })}
                                        </>
                                    </GridItem>
                                );
                            })}
                        </Grid>
                        <GridItem areaName="lastupdated">
                            {textPageData.lastUpdated
                                ? `Last updated: ${textPageData.lastUpdated}`
                                : ""}
                        </GridItem>
                    </Grid>
                </React.Fragment>
            </ThemeProvider>
        );
    }
}

export default withRouter(LegalTextPage);
