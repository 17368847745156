// import * as React from "react";

import styled from "styled-components";

export const LegalMainHeading = styled.h1``;

export const LegalSectionHeading = styled.h2``;

export const LegalText = styled.p``;

export const LegalListItem = styled.li``;
