/** @format */
import { intersection } from "lodash";
import moment from "moment";

import { IUserRole } from "app/graphql_types";
import { ICtUserData } from "auth/QUserInfo";
import { store } from "redux/store";

import intl from "react-intl-universal";
import parentLogger from "app/utils/loggerService";

const logger = parentLogger.child({
    module: "DataUtils",
});
const xtx = (str: string, key?: string) => intl.get(key || str || "x") || str;

export const prepareUserData = (isGuest: boolean, user: any) => {
    if (!isGuest) {
        const savedUser = {
            attributes: {
                "custom:games_role": user.attributes["custom:games_role"],
                "custom:manual_sub": user.attributes["custom:manual_sub"],
                email: user.attributes.email,
                email_verified: user.attributes.email_verified,
                family_name: user.attributes.family_name,
                given_name: user.attributes.given_name,
                phone_number: user.attributes.phone_number,
                phone_number_verified: user.attributes.phone_number_verified,
                preferred_username: user.attributes.preferred_username,
                sub: user.attributes.sub,
            },
            cognitoSub: user.attributes["custom:manual_sub"],
            signInUserSession: {
                idToken: user.signInUserSession.idToken,
            },
        };
    
        return savedUser;
    
    } else {
        return {
            attributes: {
                "custom:games_role": "guest",
                "custom:manual_sub": "3f77ea23-7899-44c9-9f08-7cb0196b76c1",
                email: "donotsend@ctage.com",
                email_verified: false,
                family_name: "User",
                given_name: "Guest",
                phone_number: "",
                phone_number_verified: false,
                preferred_username: "Guest",
                sub: "3f77ea23-7899-44c9-9f08-7cb0196b76c1",              
            },
            cognitoSub: "3f77ea23-7899-44c9-9f08-7cb0196b76c1",
            signInUserSession: {
                idToken: "no_token_for_guest"
            },
        }
    }
};

export const prepareDbUserData = (data: ICtUserData, handleSs: boolean) => {
    logger.debug(
        `in prepareDbUserData: got relevant data of: cognitoSub: ${data.cognitoSub}, numRoles: ${data.ctUserRoles.nodes.length}`
    );
    const { nodeId, id, cognitoSub } = data;
    const {
        email,
        firstName,
        lastName,
        authorised,
        accessIds,
    } = data.userConfidentialById;
    const roles: IUserRole[] = data.ctUserRoles.nodes;
    let roleData: any[] = [];
    const specificSubjectInfoArray: any[] = [];
    if (roles.length > 0) {
        roleData = roles.map((role) => (role));
    }
    const lastRoles = roles.filter(
        (r) => r.lastUserRole
    );
    return {
        dbUser: {
            cognitoSub,
            email,
            firstName,
            lastName,
            id,
            nodeId,
            authorised,
            accessIds,
        },
        roles: roleData,
        lastRoleId: lastRoles.length > 0 ? lastRoles[0].id : undefined,
        specificSubjectInfoArray,
    };
};


export const checkCanWrite = (fullWriteList: string[]) => {
    // @ts-ignore
    const accessIds = store.getState().app.dbUser.accessIds;
    if (accessIds) {
        const arrayIntersect = intersection(fullWriteList, accessIds);
        return arrayIntersect.length > 0;
    }
    return false;
};

const transformBigint = (bigintString: string) => {
    return moment(parseInt(bigintString, 10)).format("ll");
};

const functionInputStringMap = {
    vFirstName: ["first name", undefined],
    vLastName: ["last name", undefined],
    vUserPreferences: ["user preferences", undefined],
    vAdditionalInfoUser: ["additional user info", undefined],
    vPublicName: ["public role name", undefined],
    vRoleStartDate: ["role starting date", transformBigint],
    vRoleEndDate: ["role ending date", transformBigint],
    vRolePreferences: ["role preferences", undefined],
    vAdditionalInfoRole: ["role additional info", undefined],
    vRoleDescription: ["role description", undefined],
};

export const getNameFromFunctionInput = (functionInput: string) => {
    return xtx(
        functionInputStringMap[functionInput][0],
        `data_utils.${functionInput}`
    );
};

export const getStringFromValue = (functionInput, val) => {
    let newVal = val;
    if (functionInputStringMap[functionInput][1]) {
        newVal = functionInputStringMap[functionInput][1](val);
    }
    if (typeof newVal === "object") {
        return JSON.stringify(newVal);
    } else {
        return newVal;
    }
};

