import gql from "graphql-tag";

import { IGeoData } from "redux/reducer_types";

export const CAPTURE_APP_ROLE_EVENT = gql`
    mutation addUserEvent($input: AddRoleAppEventInput!) {
        addRoleAppEvent(input: $input) {
            clientMutationId
        }
    }
`;

// interface IResult {
//     addRoleAppEvent: {
//         clientMutationId: string | null;
//     };
// }

export interface IRoleAppEventVars {
    vAppEventCategory: string;
    vAppEventName: string;
    vAppEventData?: any;
}

interface IFinalRoleAppEventVars extends IRoleAppEventVars {
    vRoleOwner: number;
    vGeoData?: IGeoData;
}

export interface IVariables {
    input: {
        roleAppEvent: IFinalRoleAppEventVars;
    };
}

// export default class MAddAppRoleEvent extends Mutation<IResult, IVariables> {}
