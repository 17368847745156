/** @format */

import * as Sentry from "@sentry/browser";
import { createSelector } from "reselect";

import { IProps as IGamePlayerProps } from "components/video/GamePlayer";
import { IStore } from "./reducer_types";

export const getAmIVisible = (state: IStore, props: IGamePlayerProps) =>
    props ? props.isVisible : true;

const getMyCurrentTask = (state: IStore, props) =>
    state.app.queues && state.app.queues[props.queueId]
        ? state.app.queues[props.queueId].executing
        : undefined;

export const currentTaskSelectionFn = () => {
    return createSelector([getMyCurrentTask], (val) => val);
};

const getActiveRoleType = (state: IStore) =>
    state.app.activeRole ? state.app.activeRole.userRole : "RECRUITER";

export const defaultQualityThresholdSelector = createSelector(
    [getActiveRoleType],
    (artype) => {
        switch (artype.toUpperCase()) {
            case "CLIENT_ADMIN": {
                return -1000;
            }
            case "COACH": {
                return -1000;
            }
            case "CTAGE_ADMIN": {
                return -1000;
            }
            case "PLAYER": {
                return -1000;
            }
            case "LIMITED_INTERNAL_ADMIN": {
                return -1000;
            }
            case "RECRUITER": {
                return 0;
            }
            case "GPARENT": {
                return -1000;
            }
            case "LIMITED_EXTERNAL_ADMIN": {
                return -1000;
            }
            default: {
                Sentry.captureMessage(`defaultQualityThresholdSelector:
                    activeRole type (${artype}) matches no case statements`);
                return 0;
            }
        }
    }
);

export const getActiveRole = (state: IStore) => state.app.activeRole;

// export const getVideoPositions = (state: IStore) => state.app.videoPositions;

export const getTagFlag = (state: IStore) =>
    state.app.tagFlag === undefined ? false : state.app.tagFlag;

export const getWithHighlights = (state: IStore) =>
    state.app.withHighlights === undefined ? false : state.app.withHighlights;

export const getVwhTask = (state: IStore) =>
    state.app.queues && state.app.queues.vwh
        ? state.app.queues.vwh.executing
        : undefined;

export const getEv1Task = (state: IStore) =>
    state.app.queues && state.app.queues.ev1
        ? state.app.queues.ev1.executing
        : undefined;

export const getEv2Task = (state: IStore) =>
    state.app.queues && state.app.queues.ev2
        ? state.app.queues.ev2.executing
        : undefined;

export const getShowHOnly = (state: IStore) =>
    state.app.hSequenceData === undefined
        ? false
        : state.app.hSequenceData.showHOnly
        ? true
        : false;

export const getRecentHIndex = (state: IStore) =>
    state.app.hSequenceData && state.app.hSequenceData.recentHIndex
        ? state.app.hSequenceData.recentHIndex
        : undefined;


// user confidential info
const selectUserFromStore = (state: IStore) => state.app.dbUser;

const selectCognitoUserFromStore = (state: IStore) => state.app.user;

export interface ISelectUser {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified: boolean;
    mobile: string;
    mobileVerified: boolean;
    primaryRole: string;
    principalLanguage: string;
    // mobileAppRegistered: boolean;
}

export const selectUser = createSelector(
    selectUserFromStore,
    selectCognitoUserFromStore,
    (dbUser, user) => ({
        id: (dbUser && dbUser.id) || -1,
        firstName: (dbUser && dbUser.firstName) || "",
        lastName: (dbUser && dbUser.lastName) || "",
        principalLanguage: "en",
        // principalLanguage:
        //     (dbUser &&
        //         dbUser.userPreferences &&
        //         dbUser.userPreferences.language) ||
        //     "en",
        // the following are used by cognito, so are retrieved from there
        email: (user && user.attributes.email) || "",
        emailVerified: (user && user.attributes.email_verified) || false,
        mobile: (user && user.attributes.phone_number) || "",
        mobileVerified:
            (user && user.attributes.phone_number_verified) || false,
        primaryRole: (user && user.attributes["custom:role"]) || "student",
        // mobileAppRegistered: (dbUser && dbUser.mobileAppRegistered) || false,
    })
);


// active role
export const selectActiveRole = (state: IStore) => state.app.activeRole;
