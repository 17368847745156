import { createAction } from "./action_helpers";
import {
    ActionsUnion,
    ISaveAuthProps,
    ISaveChAuthState,
    ISaveUsernameOnSignInProps,
    ISetRedirectAfterLogin,
} from "./reducer_types";

export enum ActionTypes {
    SAVE_AUTH_PROPS = "SAVE_AUTH_PROPS",
    SAVE_CHANGED_AUTH_STATE = "SAVE_CHANGED_AUTH_STATE",
    SET_REDIRECT_AFTER_LOGIN = "SET_REDIRECT_AFTER_LOGIN",
    SAVE_USERNAME_ON_SIGNIN = "SAVE_USERNAME_ON_SIGNIN",
}

export const Actions = {
    saveAuthProps: (payload: ISaveAuthProps) =>
        createAction(ActionTypes.SAVE_AUTH_PROPS, payload),
    // saveAuthConfig: (payload: ISaveAuthConfig) => createAction(ActionTypes.SAVE_AUTH_CONFIG, payload),
    saveChangedAuthState: (payload: ISaveChAuthState) =>
        createAction(ActionTypes.SAVE_CHANGED_AUTH_STATE, payload),
    saveUsernameOnSignIn: (payload: ISaveUsernameOnSignInProps) =>
        createAction(ActionTypes.SAVE_USERNAME_ON_SIGNIN, payload),
    setRedirectAfterLogin: (payload: ISetRedirectAfterLogin) =>
        createAction(ActionTypes.SET_REDIRECT_AFTER_LOGIN, payload),
};

export type ActionsType = ActionsUnion<typeof Actions>;
