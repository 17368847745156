import gql from "graphql-tag";

// import { IEntity } from "app/graphql_types";
import {
    entityFields,
    protectedFileFields,
} from "app/graqhQlFragments";

export const ADD_OR_UPDATE_ENTITY = gql`
    mutation addentity($input: AddOrUpdateEntityAndOptionalLogofileInput!) {
        addOrUpdateEntityAndOptionalLogofile(input: $input) {
            entity {
                ...entityFields
            }
        }
    }
    ${protectedFileFields}
    ${entityFields}
`;

// interface IAddOrUpdateEntityResult {
//     addOrUpdateEntityAndOptionalLogofile: {
//         entity: IEntity;
//     };
// }

export interface IAddOrUpdateEntityVars {
    input: {
        vEntityName: string;
        vEntityCategory: string;
        vExistingEntityId: number | null;
        vScoreboardName: string;
        vPlusCode: string;
        vLatitude: number;
        vLongitude: number;
        vMainColour: string;
        vLogoFileId?: number | null;
        vActualFilename?: string | null;
        vS3Filename?: string | null;
        vIdentityId?: string | null;
        vRoleOwner: number;
    };
}
