/** @format */

import { IPlayer } from "app/graphql_types";
import gql from "graphql-tag";

export const ONE_PLAYER_FACTS = gql`
    query onePlayerFacts(
        $vRoleId: Int!
        $vPlayerUserId: Int
        $vPlayerRole: Int
    ) {
        onePlayerFacts(
            vRoleId: $vRoleId
            vPlayerUserId: $vPlayerUserId
            vPlayerRole: $vPlayerRole
        ) {
            playerUserId
            publicName
            academicDp
            totalDp
            schoolCompletionYear
            citizenshipList
            financialScore
            capablePositions
            bioThirdPerson
            longBioThirdPerson
            headshotFilename
            actionPhotoFilename
            identityid
            ageDays
            ageVerified
            currentPlayerStatuses
            recruiterInfo
            uscollegeInfo
            highSchoolName
            highSchoolUrl
            personalStatement
            academicComment
            commentOnParents
            emailForRecruiting
            telephoneForRecruiting
            ncaaClearinghouseNumber
        }
    }
`;

export interface IOnePlayerFactsVariables {
    vRoleId: number;
    vPlayerUserId?: number;
    vPlayerRole?: number;
}

export interface IOnePlayerFactsResult {
    onePlayerFacts: IPlayer;
}

export interface IRecruiterInfoUSCollege {
    target_divisions: [
        | "ncaa_d1"
        | "ncaa_d2"
        | "ncaa_d3"
        | "naia"
        | "njcaa_d1"
        | "njcaa_d2"
        | "njcaa_d3"
        | "usl2"
        | "npsl"
        | "us1l"
        | "usl_c"
        | "mls"
    ];
    available_in_us_end: number;
    available_in_us_start: number;
    available_in_autumn_of: number[];
}

export interface IRecruiterInfoUSSummerLeague {
    targeted_leagues: ["usl2" | "npsl"];
    seasons_available: number[];
}

export interface IResult {
    roleInfo: {
        publicName: string;
        userInfo: {
            id: number;
            personFacts: {
                bioThirdPerson?: string;
                longBioThirdPerson?: string;
                academicComment?: string;
                capablePositions?: string[];
                headshotFilename?: string;
                actionPhotoFilename?: string;
                recruiterInfo?: {
                    [key: string]: any;
                };
            };
        };
    };
}

export interface IVariables {
    roleId: number;
}

// export default class QPlayerInfoByRole extends Query<IResult, IVariables> {}
