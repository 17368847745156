import gql from "graphql-tag";

// import { IProtectedFile } from "app/graphql_types";
import { protectedFileFields } from "app/graqhQlFragments";
import { IAddProtectedFile } from "redux/reducer_types";

export const ADD_PROTECTED_FILE = gql`
    mutation addpf($input: AddProtectedFileInput!) {
        addProtectedFile(input: $input) {
            protectedFile {
                ...protectedFileFields
            }
        }
    }
    ${protectedFileFields}
`;

// interface IAddProtectedFileResult {
//     addProtectedFile: {
//         protectedFile: IProtectedFile;
//     };
// }

export interface IAddProtectedFileVars {
    input: IAddProtectedFile;
}

// export default class MUpdateQuestion extends Mutation<
//     IAddProtectedFileResult,
//     IAddProtectedFileVars
// > {}
