import gql from "graphql-tag";

import { ICreateOrUpdateSequencePayload } from "redux/reducer_types";

export const CREATE_OR_UPDATE_SEQUENCE = gql`
    mutation createOrUpdateSequence($input: CreateOrUpdateSequenceInput!) {
        createOrUpdateSequence(input: $input) {
            clientMutationId
        }
    }
`;

export interface IVariables {
    input: ICreateOrUpdateSequencePayload;
}

// export default class MUpdateSequence extends Mutation<{}, IVariables> {}
