import gql from "graphql-tag";

import { ISaveNewHighlightPayload } from "redux/reducer_types";

export const ADD_HIGHLIGHT_SET = gql`
    mutation addhighlight($input: AddHighlightInput!) {
        addHighlight(input: $input) {
            messages: strings
        }
    }
`;

export interface IResult {
    addHighlight: {
        messages: string[];
    };
}

export interface IVariables {
    input: ISaveNewHighlightPayload;
}

// export default class MAddTeamHighlight extends Mutation<IResult, IVariables> {}
