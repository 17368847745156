import gql from "graphql-tag";

export const SET_LATEST_ROLE = gql`
    mutation slr($input: SetLatestRoleInput!) {
        setLatestRole(input: $input) {
            ctUserRole {
                id
            }
        }
    }
`;

// interface IMSetLatestRoleResult {
//     setLatestRole: {
//         ctUserRole: {
//             id: number;
//         };
//     };
// }

// export interface IMSetLatestRoleVars {
//     input: {
//         vRoleId: number;
//     };
// }
