import moment from "moment";

import { FunctionType } from "app/types";
import { onError } from ".";

/** @format */

export const getArrayDifferences = (
    newArray: Array<string | number>,
    oldArray: any[],
    oldArrayIds: Array<string | number>,
    orderFieldName: string
): {
    idsToDelete: Array<string | number>;
    itemsToCreate: Array<[any, number]>;
    itemsToReorder: any[];
} => {
    const oldArrayCopy = JSON.parse(JSON.stringify(oldArray));

    const oA: any[] = [];
    const oAIds: any[] = [];
    const idsToDelete: any[] = [];
    const itemsToReorder: any[] = [];
    const itemsToCreate: any[] = [];

    // identify old array items to delete
    oldArrayIds.forEach((oldId, index) => {
        if (!newArray.includes(oldId)) {
            idsToDelete.push(oldArrayCopy[index].id);
        } else {
            oA.push(oldArrayCopy[index]);
            oAIds.push(oldId);
        }
    });

    newArray.forEach((itemId, index) => {
        let lastOrder;
        if (oA[index - 1] !== undefined) {
            lastOrder =
                oA[index - 1][orderFieldName] !== undefined
                    ? (lastOrder = oA[index - 1][orderFieldName])
                    : (lastOrder = oA[index - 1][1]);
        } else if (itemsToCreate.length > 0) {
            lastOrder = itemsToCreate[itemsToCreate.length - 1][1];
        } else {
            lastOrder = -10;
        }
        let nextOrder;
        if (oA[index] !== undefined) {
            nextOrder = oA[index][orderFieldName];
        } else if (itemsToCreate.length > 0) {
            nextOrder = itemsToCreate[itemsToCreate.length - 1][1] + 2;
        } else {
            nextOrder = 3;
        }

        if (newArray[index] === oAIds[index]) {
            // pass
        } else if (oAIds.includes(newArray[index])) {
            const oldLocation = oAIds.indexOf(newArray[index]);
            const items = oA.splice(oldLocation, 1);
            oAIds.splice(oldLocation, 1);
            items[0][orderFieldName] = (lastOrder + nextOrder) / 2;
            itemsToReorder.push(items[0]);
            oA.splice(index, 0, items[0]);
            oAIds.splice(index, 0, newArray[index]);
        } else if (oAIds[index] === undefined) {
            itemsToCreate.push([newArray[index], (lastOrder + nextOrder) / 2]);
        } else if (!oAIds.includes(newArray[index])) {
            const insertedOrder = (lastOrder + nextOrder) / 2;
            const newItem = [newArray[index], insertedOrder];
            itemsToCreate.push(newItem);
            oA.splice(index, 0, newItem);
            oAIds.splice(index, 0, newArray[index]);
        }
    });
    return {
        idsToDelete,
        itemsToCreate,
        itemsToReorder,
    };
};

// tslint:disable-next-line: max-classes-per-file
export class DefaultDict {
    [x: string]: any;
    constructor(defaultInit) {
        return new Proxy(
            {},
            {
                get: (target, name) =>
                    name in target
                        ? target[name]
                        : (target[name] =
                              typeof defaultInit === "function"
                                  ? new defaultInit().valueOf()
                                  : defaultInit),
            }
        );
    }
}

// https://stackoverflow.com/a/52171480/4416993
export const cyrb53 = (str, seed = 0) => {
    // tslint:disable-next-line: no-bitwise
    let h1 = 0xdeadbeef ^ seed;
    // tslint:disable-next-line: no-bitwise
    let h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        // tslint:disable-next-line: no-bitwise
        h1 = Math.imul(h1 ^ ch, 2654435761);
        // tslint:disable-next-line: no-bitwise
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    // eslint-disable-next-line
    h1 =
        Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
        Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    // eslint-disable-next-line
    h2 =
        Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
        Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    // tslint:disable-next-line: no-bitwise
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export const escapeRegExpChars = (text: string) => {
    // eslint-disable-next-line
    return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
};

export const isDev = () => {
    const hostname = window && window.location && window.location.hostname;
    if (hostname === "localhost") {
        return true;
    } else {
        return false;
    }
};

export const cloneObject = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};

export const makeCurriculumElementLabel = (chapter, section, elementText) => {
    // from CurriculumMultiSelect (not used here, but may offer a better approach)
    // const spaceVals: number[] = items
    // .filter((i) => i.id === ce.elementParentId)
    // .map((i) =>
    //     i.userDataObject ? i.userDataObject.numSpaces : 0
    // );
    // const spaces =
    //     spaceVals.length > 0 ? Math.max(...spaceVals) + 2 : 2;
    // items.push({
    //     id: ce.id,
    //     name: `${".".repeat(spaces - 2)}${
    //         ce.chapter ? `${ce.chapter}.` : ""
    //     }${
    //         ce.section ? `${ce.section}.` : ""
    //     } ${ce.elementText.trim()}`,
    //     userDataObject: {
    //         lft: ce.lft,
    //         rgt: ce.rgt,
    //         elementParentId: ce.elementParentId,
    //         chapter: ce.chapter,
    //         section: ce.section,
    //         numSpaces: spaces,
    //     },
    // });
    return `${chapter ? `${chapter}.` : ""}${section ? `${section}.` : ""}${
        chapter || section ? " " : ""
    }${elementText}`;
};

// https://spin.atomicobject.com/2020/01/16/timeout-promises-nodejs/
export const promiseWithTimeout = (
    timeoutMs: number,
    promise: () => Promise<any>,
    failureMessage?: string
) => {
    let timeoutHandle: NodeJS.Timeout;
    const timeoutPromise = new Promise<never>((resolve, reject) => {
        timeoutHandle = setTimeout(
            () => reject(new Error(failureMessage)),
            timeoutMs
        );
    });

    return Promise.race([promise(), timeoutPromise]).then((result) => {
        clearTimeout(timeoutHandle);
        return result;
    });
};

const delay = (retryCount) =>
    new Promise((resolve) => setTimeout(resolve, 1000 + 1000 ** retryCount));

// https://medium.com/swlh/retrying-and-exponential-backoff-with-promises-1486d3c259
export const getResource = async (
    desc: { [key: string]: any },
    fnCall: FunctionType,
    maxRetryCount,
    retryCount = 0,
    lastError = null
) => {
    // console.log("in getResource");
    if (retryCount > maxRetryCount) {
        try {
            throw lastError || new Error("exceeded number of retries");
        } catch (e) {
            onError(null, e, {
                function: "getResource",
                type: "final error",
                ...desc,
            });
            throw e;
        }
    }
    try {
        // console.log("calling fnCall");
        const result = await fnCall();
        // console.log("got a result from getResource");
        // console.log(JSON.stringify(result));
        return result;
    } catch (e: any) {
        onError(null, e, {
            function: "getResource",
            type: "intermediate error",
            retryCount: retryCount.toString(),
            ...desc,
        });
        await delay(retryCount);
        return getResource(desc, fnCall, maxRetryCount, retryCount + 1, e);
    }
};

export const arraysDoOverlap = (array1, array2) => {
    // https://stackoverflow.com/a/51342342
    const r = [array1, array2].reduce((a, c) => a.filter((i) => c.includes(i)));
    return r.length > 0 ? true : false;
};

export const computeUsCollegeValues = (
    uscollegeInfo: { [key: string]: any },
    availableInUsStart: string,
    availableInUsEnd: string,
    availableInAutumnOf: number[]
) => {
    const epochNow = Date.now();
    // compute avail in US years
    const availableFrom = moment(availableInUsStart, "x");
    const availUSYears =
        (availableInAutumnOf &&
            availableInAutumnOf.filter(
                (yr) =>
                    new Date(`${yr}-Aug-15`).valueOf() >
                    Math.max(new Date().valueOf(), availableFrom.valueOf())
            )) ||
        [];

    let currentStatusText;
    let usCollegeStatusText;
    let availOfferValue = "Available now";

    if (epochNow > parseInt(availableInUsEnd, 10)) {
        availOfferValue = "No longer available";
    } else if (epochNow < parseInt(availableInUsStart, 10)) {
        availOfferValue = "May become available";
    } else if (uscollegeInfo && uscollegeInfo.committed) {
        currentStatusText = `Committed in ${uscollegeInfo.committed.when}${
            uscollegeInfo.offers && uscollegeInfo.offers.length > 1
                ? " with more than one offer"
                : ""
        }`;
        availOfferValue = "Committed / Active Offer(s)";
    } else {
        if (uscollegeInfo && uscollegeInfo.offers) {
            const offerList: number[] = [];
            uscollegeInfo.offers.forEach(
                (o: { expiry: string; status: string }) => {
                    if (
                        o.status !== "rejected" &&
                        epochNow < parseInt(o.expiry, 10)
                    ) {
                        offerList.push(1);
                    } else if (o.status === "accepted") {
                        // N.B. shouldn't be here, really, but if so...
                        offerList.push(2);
                    } else {
                        offerList.push(0);
                    }
                    currentStatusText = [
                        `Has had one or more offers. Still available for ${availUSYears!.join(
                            ", "
                        )}`,
                        "Currently has one or more active offers",
                        "Has accepted an offer",
                    ][Math.max(...offerList)];
                    if (Math.max(...offerList) > 0) {
                        availOfferValue = "Committed / Active Offer(s)";
                    }
                }
            );
        } else {
            if (uscollegeInfo && uscollegeInfo.hasEnoughHighlights) {
                availOfferValue = "Available now";
            } else {
                availOfferValue = "Available now (limited highlights)";
            }
        }
    }
    usCollegeStatusText = currentStatusText;

    return {
        availUSYears,
        availOfferValue,
        usCollegeStatusText,
    };
};
