import gql from "graphql-tag";

// import { IHighlightLikeEvent } from "app/graphql_types";
import { highlightLikeEventFields } from "app/graqhQlFragments";
import { IAddLikeEventPayload } from "redux/reducer_types";

export const ADD_LIKE_OR_RATING = gql`
    mutation createHighlightLikeEvent($input: CreateHighlightLikeEventInput!) {
        createHighlightLikeEvent(input: $input) {
            highlightLikeEvent {
                ...highlightLikeEventFields
            }
        }
    }
    ${highlightLikeEventFields}
`;

// interface IResult {
//     createHighlightLikeEvent: {
//         highlightLikeEvent: IHighlightLikeEvent;
//     };
// }

export interface IVariables {
    input: {
        highlightLikeEvent: IAddLikeEventPayload;
    };
}

// export default class MAddHighlightLikeEvent extends Mutation<
//     IResult,
//     IVariables
// > {}
