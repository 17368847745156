/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useRef, useEffect } from "react"; //
import { useSelector, useDispatch } from "react-redux"; //
import {
    useParams,
    Redirect,
    useLocation,
    // useRouteMatch,
} from "react-router-dom";
import Amplify from "aws-amplify";
// import type { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { ApolloProvider } from "@apollo/client";

import { Actions } from "../redux/app_actions"; // AppActions
import { apolloClientInstance } from "app/AppSupport/Utils";
import { IStore } from "redux/reducer_types";
import asyncComponent from "../app/AsyncComponent2";
import parentLogger from "app/utils/loggerService";
const logger = parentLogger.child({
    module: "AppWithAuth2",
});
const AsyncApp = asyncComponent(() => import("./App"));
const AsyncSignInSignUpParent = asyncComponent(
    () => import("./admin/SignInSignUpParent")
);
// import { UserAndRoleProvider } from "providers/userAndRoleProvider";
// import { AuthProvider } from "providers/authProvider";

// import { clearToasts } from app/AppSupport/Utils";
// import { createGlobalStyle, ThemeProvider } from "styled-components";

// import { theme } from "design-components/theme";
// const AsyncDevSignIn = asyncComponent(() => import("./admin/DevSignIn"));

// common locale data; N.B. not used directly by this file; possibly should be moved up to index.tsx
// these files don't have much useful info; keeping one just in case
// require("intl/locale-data/jsonp/en.js");
require("intl/locale-data/jsonp/en-GB.js");
// require("intl/locale-data/jsonp/en-US.js");
// require("intl/locale-data/jsonp/de.js");

const storageBucket = process.env.REACT_APP_STORAGE;

const amplifyConfig = {
    Auth: {
        authenticationFlowType: "CUSTOM_AUTH",
        // copied from Valearn - development used a password
        // authenticationFlowType:
        //     process.env.REACT_APP_REVIEW_APP === "true"
        //         ? "USER_SRP_AUTH"
        //         : "CUSTOM_AUTH",
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        mandatorySignIn: false,
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_APP_ID,
    },
    // for using api gateway that is manually set up
    // API: {
    //     endpoints: [
    //         {
    //             name: "valearnmain",
    //             endpoint: process.env.REACT_APP_API_URL,
    //             region: "eu-west-1",
    //         },
    //     ],
    // },
    Storage: {
        AWSS3: {
            bucket: storageBucket,
            region: process.env.REACT_APP_REGION,
        },
    },
};
Amplify.configure(amplifyConfig);

// const GlobalStyle = createGlobalStyle`
//   body {
//     background: ${(props) => props.theme.backgroundColor};
//     color: ${(props) => props.theme.textColor};
//     font-family: ${(props) => props.theme.fontFamily};
//     padding-top: ${(props) => props.theme.paddingTop};
//     width: "100vw";
//     height: "100vh";
//     lineHeight: 1.28581;
//   }
// `;

export const MINTABLETWIDTH = 1195;

// Optionally add Debug Logging N.B. this overwrites any log levels on other loggers; best to leave it out
// Amplify.Logger.LOG_LEVEL = "INFO";

const AppWithAuth: React.FC = () => {
    // const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject>>()
    let dispatch = useDispatch();
    const location = useLocation();
    // const match = useRouteMatch();

    const { lang, urlEmail } = useParams();

    // logger.info(match.url);
    // logger.info(location.pathname);

    const [passwordless] = useState(
        process.env.REACT_APP_REVIEW_APP === "true" ? false : true
    );

    const language = useSelector((state: IStore) => state.app.language);
    const authStatus = useSelector((state: IStore) => state.app.authStatus);

    const authStatusRef = useRef() as any;

    useEffect(() => {
        dispatch(
            Actions.saveInitialScreenSize({
                height: window.innerHeight,
                width: window.innerWidth,
            })
        );
        // in signinsignupparent ... might not always run, though
        // if (window.innerWidth > MINTABLETWIDTH) {
        //     const script = document.createElement("script");
        //     script.src =
        //         "https://static.zdassets.com/ekr/snippet.js?key=b4dc5cc9-b433-47d9-9e9e-ffa626fce495";
        //     script.id = "ze-snippet";
        //     script.async = true;
        //     document.body.appendChild(script);
        // }
    }, []);

    useEffect(() => {
        if (lang) {
            dispatch(Actions.requestNewLanguage({ language: lang }));
        }
        // setInitialLanguage();
        // N.B. to run only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang]);

    useEffect(() => {
        if (urlEmail) {
            dispatch(
                Actions.saveSignUpParams({
                    urlEmail: decodeURIComponent(urlEmail),
                })
            );
        }
    }, [urlEmail]);

    useEffect(() => {
        if (passwordless) {
            Amplify.configure({
                Auth: {
                    authenticationFlowType: "CUSTOM_AUTH",
                },
            });
        } else {
            Amplify.configure({
                Auth: {
                    authenticationFlowType: "USER_SRP_AUTH",
                },
            });
        }
    }, [passwordless]);

    // useEffect(() => {
    //     if (authStatus !== authStatusRef.current) {
    //         if (
    //             authStatus === "signedIn" &&
    //             authStatusRef.current === "signIn"
    //         ) {
    //             // remove any toasts leftover form sign in / sign up
    //             clearToasts();
    //             dispatch(AppActions.wsDoNotMaintain({}));
    //         }
    //         authStatusRef.current = authStatus;
    //     }
    // }, [authStatus]);

    const render = () => {
        logger.debug(
            `rendering AppWithAuth.  Have authStatus = "${authStatus}" and the ref is "${authStatusRef.current}"`
        );
        logger.debug(`rendering pathname is ${location.pathname}`);

        if (!passwordless) {
            logger.debug("rendering AsyncDevSignIn");
            // return <AsyncDevSignIn />;
            return <div>Dev sign in is not set up for this app</div>;
        } else if (authStatus === "signIn") {
            logger.debug("rendering signinsignupparent");
            return (
                <ApolloProvider client={apolloClientInstance}>
                    <AsyncSignInSignUpParent />
                </ApolloProvider>
            );
        } else if (["signedIn", "signedInAsGuest"].includes(authStatus || "")) {
            if (location?.pathname?.indexOf("/site") !== -1) {
                // once /site/:lang?/:urlEmail has been handled above, redirect to remove those params
                logger.debug("rendering redirect");
                return <Redirect to="/" />;
                // } else if (match.url !== "/app") {
                //     return <Redirect to={`/app${location.pathname}`} />;
            } else {
                logger.debug("rendering App");

                return (
                    // <ThemeProvider theme={theme}>
                    //     <GlobalStyle />
                    <ApolloProvider client={apolloClientInstance}>
                        {/* <AuthProvider>  --> for later when moving to Remix and out of Redux
                            <UserAndRoleProvider> */}
                        <AsyncApp
                            authStatus={authStatus}
                            initialAppLoad={
                                authStatus !== authStatusRef.current
                            }
                        />
                        {/* </UserAndRoleProvider>
                        </AuthProvider> */}
                    </ApolloProvider>
                    // </ThemeProvider>
                );
            }
        } else {
            logger.warn(`unhandled authstatus: ${authStatus}`);
            return <div></div>;
        }
    };

    if (language) {
        // the render function will remove /site/lang/urlEmail through a redirect above
        return render();
    } else {
        return <div>Loading language file...</div>;
    }
};

export default AppWithAuth;
