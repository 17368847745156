/** @format */

import { Actions as AuthActions } from "redux_auth/app_actions";

import { createAction } from "./action_helpers";
import * as rt from "./reducer_types";

export enum ActionTypes {
    SAVE_USER_DATA = "SAVE_USER_DATA",
    SAVE_ROLES_DATA = "SAVE_ROLES_DATA",
    CHOOSE_ROLE = "CHOOSE_ROLE",
    SAVE_QUIZ_FILTERED_QUESTIONS_VARIABLES = "SAVE_QUIZ_FILTERED_QUESTIONS_VARIABLES",
    SAVE_GROUP_UUID_LIST = "SAVE_GROUP_UUID_LIST",
    SAVE_GROUP_SHARE_TYPES = "SAVE_GROUP_SHARE_TYPES",
    EPIC_COMPLETED = "EPIC_COMPLETED",
    UPLOAD_PROTECTED_FILE = "UPLOAD_PROTECTED_FILE",
    ADD_PROTECTED_FILE = "ADD_PROTECTED_FILE",
    UPLOAD_VIDEO_FILE = "UPLOAD_VIDEO_FILE",
    UPLOAD_PROGRESS_UPDATE = "UPLOAD_PROGRESS_UPDATE",
    REFRESH_TOKEN_WITH_GQL = "REFRESH_TOKEN_WITH_GQL",
    EPIC_ERROR = "EPIC_ERROR",
    EPIC_SENTRY_MESSAGE = "EPIC_SENTRY_MESSAGE",
    TOKENS_UPDATED = "TOKENS_UPDATED",
    TOKEN_UPDATE_FAILURE = "TOKEN_UPDATE_FAILURE",
    GET_PLUS_CODE_DATA = "GET_PLUS_CODE_DATA",
    SAVE_PLUS_CODE_DATA = "SAVE_PLUS_CODE_DATA",
    SAVE_NEW_ENTITY = "SAVE_NEW_ENTITY",
    SET_NAVBAR_LOGO_ENTITY_NODE_ID = "SET_NAVBAR_LOGO_ENTITY_NODE_ID",
    SET_NAVBAR_DISPLAY_SETTING = "SET_NAVBAR_DISPLAY_SETTING",
    SET_WITH_HIGHLIGHTS_FLAG = "SET_WITH_HIGHLIGHTS_FLAG",
    STORE_CURRENT_VIDEO_POSITION = "STORE_CURRENT_VIDEO_POSITION",
    SAVE_ROLE_PREFERENCES = "SAVE_ROLE_PREFERENCES",
    SET_TAG_FLAG = "SET_TAG_FLAG",
    SAVE_NEW_HIGHLIGHT = "SAVE_NEW_HIGHLIGHT",
    GET_IP_DATA = "GET_IP_DATA",
    SAVE_IP_DATA = "SAVE_IP_DATA",
    EXECUTE_PG_FN_IN_LAMBDA = "EXECUTE_PG_FN_IN_LAMBDA",
    VERIFY_RECAPTCHA_TOKEN = "VERIFY_RECAPTCHA_TOKEN",
    SAVE_RECAPTCHA_V3_SCORE = "SAVE_RECAPTCHA_V3_SCORE",
    SAVE_RECAPTCHA_V2_RESPONSE = "SAVE_RECAPTCHA_V2_RESPONSE",
    START_PLEDGE_STATE_MACHINE = "START_PLEDGE_STATE_MACHINE",
    CHANGE_NEXT_HIGHLIGHT = "CHANGE_NEXT_HIGHLIGHT",
    CHANGE_PREV_HIGHLIGHT = "CHANGE_PREV_HIGHLIGHT",
    CHANGE_STOP_VIDEO = "CHANGE_STOP_VIDEO",
    SAVE_SHORTCUT_KEYS_DESC = "SAVE_SHORTCUT_KEYS_DESC",
    REQUEST_VIEW = "REQUEST_VIEW",
    SET_VIDEO_VIEWPOINTS = "SET_VIDEO_VIEWPOINTS",
    SET_ACTIVE_VIDEO_VIEWPOINT = "SET_ACTIVE_VIDEO_VIEWPOINT",
    TOGGLE_NEXT_HIGHLIGHT_REQUEST = "TOGGLE_NEXT_HIGHLIGHT_REQUEST",
    TOGGLE_PREV_HIGHLIGHT_REQUEST = "TOGGLE_PREV_HIGHLIGHT_REQUEST",
    SAVE_CURRENT_TAGGING_VARS = "SAVE_CURRENT_TAGGING_VARS",
    SHOW_SERVICE_WORKER_MESSAGE = "SHOW_SERVICE_WORKER_MESSAGE",
    UPDATE_HIGHLIGHT = "UPDATE_HIGHLIGHT",
    UPDATE_ROLE_EVENT = "UPDATE_ROLE_EVENT",
    SAVE_EVENT_DATA_QUERY_EVENT_ID_LIST = "SAVE_EVENT_DATA_QUERY_EVENT_ID_LIST",
    ADD_ROLE_EVENTS = "ADD_ROLE_EVENTS",
    SAVE_INITIAL_SCREEN_SIZE = "SAVE_INITIAL_SCREEN_SIZE",
    CAPTURE_APP_ROLE_EVENT = "CAPTURE_APP_ROLE_EVENT",
    SAVE_COOKIE_APPROVAL = "SAVE_COOKIE_APPROVAL",
    ADD_TO_QUEUE = "ADD_TO_QUEUE",
    Q_START_NEXT_TASK = "Q_START_NEXT_TASK",
    Q_TASK_COMPLETED = "Q_TASK_COMPLETED",
    ADD_MULTIPLE_TO_QUEUE = "ADD_MULTIPLE_TO_QUEUE",
    Q_CHECK = "Q_CHECK",
    Q_END_CHECKING_NORMALLY = "Q_END_CHECKING_NORMALLY",
    Q_END_CHECKING_WITH_ERROR = "Q_END_CHECKING_WITH_ERROR",
    Q_IS_ACTIVE = "Q_IS_ACTIVE",
    Q_IS_INACTIVE = "Q_IS_INACTIVE",
    RESET_QUEUE = "RESET_QUEUE",
    UPDATE_HIGHLIGHT_SEQ_DATA = "UPDATE_HIGHLIGHT_SEQ_DATA",
    ADD_LIKE_EVENT = "ADD_LIKE_EVENT",
    SAVE_PREVIOUS_PLAYING_STATE = "SAVE_PREVIOUS_PLAYING_STATE",
    REPLACE_HIGHLIGHT_SEQUENCE_ITEMS = "REPLACE_HIGHLIGHT_SEQUENCE_ITEMS",
    ADD_HIGHLIGHT_TO_SEQUENCE = "ADD_HIGHLIGHT_TO_SEQUENCE",
    ESTIMATE_BANDWIDTH = "ESTIMATE_BANDWIDTH",
    PREPARE_SYSTEM_HIGHLIGHT_SEQ = "PREPARE_SYSTEM_HIGHLIGHT_SEQ",
    SAVE_NEW_SYSTEM_HIGHLIGHT_SEQ = "SAVE_NEW_SYSTEM_HIGHLIGHT_SEQ",
    SAVE_PLAYER_FILTER = "SAVE_PLAYER_FILTER",
    CURRENT_ROLE_EVENTS = "CURRENT_ROLE_EVENTS",
    RESPOND_TO_WHOIS_REQUEST = "RESPOND_TO_WHOIS_REQUEST",
    START_MEASURING_BANDWIDTH = "START_MEASURING_BANDWIDTH",
    END_MEASURING_BANDWIDTH = "END_MEASURING_BANDWIDTH",
    CONSIDER_MEASURING_BANDWIDTH = "CONSIDER_MEASURING_BANDWIDTH",
    ABORT_MEASURING_BANDWIDTH = "ABORT_MEASURING_BANDWIDTH",
    GRADE_BANDWIDTH = "GRADE_BANDWIDTH",
    SET_NEW_BW_GRADE = "SET_NEW_BW_GRADE",
    UPDATE_LAST_ACTIVITY_TIME = "UPDATE_LAST_ACTIVITY_TIME",
    SAVE_BROWSER_TYPE = "SAVE_BROWSER_TYPE",
    FLAG_SEQUENCE = "FLAG_SEQUENCE",
    CREATE_OR_UPDATE_SEQUENCE = "CREATE_OR_UPDATE_SEQUENCE",
    LOG_PUBLIC_ACTION = "LOG_PUBLIC_ACTION",
    TURN_ON_OR_OFF_KEYBOARD_COMMANDS = "TURN_ON_OR_OFF_KEYBOARD_COMMANDS",
    // new Dec 2021 - may not be fully integrated since copied from Valearn
    REQUEST_NEW_LANGUAGE = "REQUEST_NEW_LANGUAGE",
    SET_ACTIVE_LANGUAGE = "SET_ACTIVE_LANGUAGE",
    SAVE_ROLE_LANGUAGE_PREFERENCE = "SAVE_ROLE_LANGUAGE_PREFERENCE",
    SHOW_TOAST = "SHOW_TOAST",
    FAIL_TO_FETCH = "FAIL_TO_FETCH",
    FAIL_TO_FETCH_FINAL_FAILURE = "FAIL_TO_FETCH_FINAL_FAILURE",
    SET_FAIL_TO_FETCH_COUNT = "SET_FAIL_TO_FETCH_COUNT",
    SAVE_AUTH_STATUS = "SAVE_AUTH_STATUS",
    SAVE_SIGN_UP_PARAMS = "SAVE_SIGN_UP_PARAMS",
    SAVE_RAW_COGNITO_USER = "SAVE_RAW_COGNITO_USER",
    SET_LOGIN_DETAILS = "SET_LOGIN_DETAILS",
    SET_REGISTRATION_DETAILS = "SET_REGISTRATION_DETAILS",
    SAVE_CLIENT_DETAILS = "SAVE_CLIENT_DETAILS",
    SAVE_CURRENT_PLAYER = "SAVE_CURRENT_PLAYER",
    // not added yet
    // SAVE_SIGNUP_RESPONSE = "SAVE_SIGNUP_RESPONSE",
    // SHOW_DIALOG = "SHOW_DIALOG",
    // HIDE_DIALOG = "HIDE_DIALOG",
}

export const AppActions = {
    // other actions
    saveBandwidthEstimate: (payload: rt.IEstimateBandwidthPayload) =>
        createAction(ActionTypes.ESTIMATE_BANDWIDTH, payload),
    startMeasuringBandwidth: (payload: {}) =>
        createAction(ActionTypes.START_MEASURING_BANDWIDTH, payload),
    endMeasuringBandwidth: (payload: {}) =>
        createAction(ActionTypes.END_MEASURING_BANDWIDTH, payload),
    setNewBwGrade: (payload: rt.ISetNewBwGradePayload) =>
        createAction(ActionTypes.SET_NEW_BW_GRADE, payload),
    logPublicAction: (payload: rt.ILogPublicActionPayload) =>
        createAction(ActionTypes.LOG_PUBLIC_ACTION, payload),

    saveBrowserType: (payload: rt.ISaveBrowserType) =>
        createAction(ActionTypes.SAVE_BROWSER_TYPE, payload),
    updateLastActivityTime: (payload: rt.IUpdateLastActivityTime) =>
        createAction(ActionTypes.UPDATE_LAST_ACTIVITY_TIME, payload),

    // queue actions
    addMultipleToQueue: (payload: rt.IAddMultipleToQueuePayload) =>
        createAction(ActionTypes.ADD_MULTIPLE_TO_QUEUE, payload),
    addToQueue: (payload: rt.IAddToQueuePayload) =>
        createAction(ActionTypes.ADD_TO_QUEUE, payload),
    completeQTask: (payload: rt.IQTaskCompletedPayload) =>
        createAction(ActionTypes.Q_TASK_COMPLETED, payload),
    endCheckingWithError: (payload: rt.IQEndCheckingWithError) =>
        createAction(ActionTypes.Q_END_CHECKING_WITH_ERROR, payload),
    qIsActive: (payload: rt.IQActiveStatusPayload) =>
        createAction(ActionTypes.Q_IS_ACTIVE, payload),
    qIsInactive: (payload: rt.IQActiveStatusPayload) =>
        createAction(ActionTypes.Q_IS_INACTIVE, payload),
    resetQueue: (payload: rt.IQActiveStatusPayload) =>
        createAction(ActionTypes.RESET_QUEUE, payload),
    startNextTask: (payload: rt.IQStartTaskPayload) =>
        createAction(ActionTypes.Q_START_NEXT_TASK, payload),

    // highlight sequence actions
    addHighlightToSequence: (payload: rt.IAddHighlightToSequencePayload) =>
        createAction(ActionTypes.ADD_HIGHLIGHT_TO_SEQUENCE, payload),
    updateHSequenceData: (payload: rt.IUpdateHSeqDataPayload) =>
        createAction(ActionTypes.UPDATE_HIGHLIGHT_SEQ_DATA, payload),
    replaceHighlightSequenceItems: (payload: rt.IReplaceHSItems) =>
        createAction(ActionTypes.REPLACE_HIGHLIGHT_SEQUENCE_ITEMS, payload),
    flagSequence: (payload: rt.IFlagSequencePayload) =>
        createAction(ActionTypes.FLAG_SEQUENCE, payload),
    createOrUpdateSequence: (payload: rt.ICreateOrUpdateSequencePayload) =>
        createAction(ActionTypes.CREATE_OR_UPDATE_SEQUENCE, payload),

    // highlight actions
    addLikeEvent: (payload: rt.IAddLikeEventPayload) =>
        createAction(ActionTypes.ADD_LIKE_EVENT, payload),
    changeNextHighlight: (payload: {}) =>
        createAction(ActionTypes.CHANGE_NEXT_HIGHLIGHT, payload),
    changePrevHighlight: (payload: {}) =>
        createAction(ActionTypes.CHANGE_PREV_HIGHLIGHT, payload),
    prepareSystemHighlightSeq: (
        payload: rt.IPrepareSystemHighlightSeqPayload
    ) => createAction(ActionTypes.PREPARE_SYSTEM_HIGHLIGHT_SEQ, payload),
    saveNewHighlight: (payload: rt.ISaveNewHighlightPayload) =>
        createAction(ActionTypes.SAVE_NEW_HIGHLIGHT, payload),
    saveNewSystemHighlightSeq: (
        payload: rt.ISaveNewSystemHighlightSeqPayload
    ) => createAction(ActionTypes.SAVE_NEW_SYSTEM_HIGHLIGHT_SEQ, payload),
    savePreviousPlayingState: (payload: rt.ISavePrevPlayingStatePayload) =>
        createAction(ActionTypes.SAVE_PREVIOUS_PLAYING_STATE, payload),
    setWithHighlightsFlag: (payload: rt.ISetWithHighlightsFlagPayload) =>
        createAction(ActionTypes.SET_WITH_HIGHLIGHTS_FLAG, payload),
    toggleNextHighlightRequest: (payload: {}) =>
        createAction(ActionTypes.TOGGLE_NEXT_HIGHLIGHT_REQUEST, payload),
    togglePrevHighlightRequest: (payload: {}) =>
        createAction(ActionTypes.TOGGLE_PREV_HIGHLIGHT_REQUEST, payload),
    updateHighlight: (payload: rt.IUpdateHighlightPayload) =>
        createAction(ActionTypes.UPDATE_HIGHLIGHT, payload),

    addProtectedFile: (payload: rt.IAddProtectedFile) =>
        createAction(ActionTypes.ADD_PROTECTED_FILE, payload),
    addRoleEvents: (payload: rt.IAddRoleEventsPayload) =>
        createAction(ActionTypes.ADD_ROLE_EVENTS, payload),
    captureAppRoleEvent: (payload: rt.ICaptureAppRoleEventPayload) =>
        createAction(ActionTypes.CAPTURE_APP_ROLE_EVENT, payload),
    changeStopVideo: (payload: {}) =>
        createAction(ActionTypes.CHANGE_STOP_VIDEO, payload),
    chooseRole: (payload: rt.IChooseRole) =>
        createAction(ActionTypes.CHOOSE_ROLE, payload),
    epicCompleted: (payload: rt.IEpicCompletedPayload) =>
        createAction(ActionTypes.EPIC_COMPLETED, payload),
    epicError: (payload: rt.IEpicErrorPayload) =>
        createAction(ActionTypes.EPIC_ERROR, payload),
    epicSentryMessage: (payload: rt.IEpicSentryMessagePayload) =>
        createAction(ActionTypes.EPIC_SENTRY_MESSAGE, payload),
    executePgFnInLambda: (payload: rt.IExecutePgFnInLambdaPayload) =>
        createAction(ActionTypes.EXECUTE_PG_FN_IN_LAMBDA, payload),
    getIpData: (payload: {}) => createAction(ActionTypes.GET_IP_DATA, payload),
    getPlusCodeData: (payload: rt.IPlusCodePayload) =>
        createAction(ActionTypes.GET_PLUS_CODE_DATA, payload),
    gqlToRefreshToken: (payload: {}) =>
        createAction(ActionTypes.REFRESH_TOKEN_WITH_GQL, payload),
    requestView: (payload: rt.IRequestViewPayload) =>
        createAction(ActionTypes.REQUEST_VIEW, payload),
    saveCookieApproval: (payload: rt.ISaveCookieApprovalPayload) =>
        createAction(ActionTypes.SAVE_COOKIE_APPROVAL, payload),
    saveCurrentTaggingVars: (payload: rt.ISaveCurrentTaggingVarsPayload) =>
        createAction(ActionTypes.SAVE_CURRENT_TAGGING_VARS, payload),
    saveEventIdList: (payload: rt.ISaveEventIdListPayload) =>
        createAction(ActionTypes.SAVE_EVENT_DATA_QUERY_EVENT_ID_LIST, payload),
    saveGroupShareTypes: (payload: rt.ISaveGroupShareTypes) =>
        createAction(ActionTypes.SAVE_GROUP_SHARE_TYPES, payload),
    saveGroupUuidList: (payload: rt.ISaveGroupUuidList) =>
        createAction(ActionTypes.SAVE_GROUP_UUID_LIST, payload),
    saveInitialScreenSize: (payload: rt.ISaveInitialScreenSize) =>
        createAction(ActionTypes.SAVE_INITIAL_SCREEN_SIZE, payload),
    saveIpData: (payload: rt.ISaveIpDataPayload) =>
        createAction(ActionTypes.SAVE_IP_DATA, payload),
    saveNewEntity: (payload: rt.ISaveNewEntityPayload) =>
        createAction(ActionTypes.SAVE_NEW_ENTITY, payload),
    savePlayerFilter: (payload: rt.ISavePlayerFilterPayload) =>
        createAction(ActionTypes.SAVE_PLAYER_FILTER, payload),
    savePlusCodeData: (payload: rt.ISavePlusCodeData) =>
        createAction(ActionTypes.SAVE_PLUS_CODE_DATA, payload),
    saveRecaptchaV2Response: (payload: rt.ISaveRecaptchaV2ResponsePayload) =>
        createAction(ActionTypes.SAVE_RECAPTCHA_V2_RESPONSE, payload),
    saveRecaptchaV3Score: (payload: rt.ISaveRecaptchaV3ScorePayload) =>
        createAction(ActionTypes.SAVE_RECAPTCHA_V3_SCORE, payload),
    saveRolePreferences: (payload: rt.ISaveRolePreferencesPayload) =>
        createAction(ActionTypes.SAVE_ROLE_PREFERENCES, payload),
    saveRolesData: (payload: rt.ISaveRolesData) =>
        createAction(ActionTypes.SAVE_ROLES_DATA, payload),
    saveShortcutKeysDesc: (payload: rt.ISaveShortcutKeysDesc) =>
        createAction(ActionTypes.SAVE_SHORTCUT_KEYS_DESC, payload),
    saveUserData: (payload: rt.ISaveUserPayload) =>
        createAction(ActionTypes.SAVE_USER_DATA, payload),
    setActiveVideoViewpoint: (payload: rt.ISetActiveVideoViewpointPayload) =>
        createAction(ActionTypes.SET_ACTIVE_VIDEO_VIEWPOINT, payload),
    setNavbarDisplaySetting: (payload: rt.ISetNavbarDisplaySettingPayload) =>
        createAction(ActionTypes.SET_NAVBAR_DISPLAY_SETTING, payload),
    setNavbarLogoEntityNodeId: (
        payload: rt.ISetNavbarLogoEntityNodeIdPayload
    ) => createAction(ActionTypes.SET_NAVBAR_LOGO_ENTITY_NODE_ID, payload),
    setTagFlag: (payload: rt.ISetTagFlagPayload) =>
        createAction(ActionTypes.SET_TAG_FLAG, payload),
    setVideoViewpoints: (payload: rt.ISetVideoViewpointsPayload) =>
        createAction(ActionTypes.SET_VIDEO_VIEWPOINTS, payload),
    showServiceWorkerMessage: (payload: rt.IShowServiceWorkerMessagePayload) =>
        createAction(ActionTypes.SHOW_SERVICE_WORKER_MESSAGE, payload),
    startPledgeStateMachine: (payload: rt.IStartPledgeStateMachinePayload) =>
        createAction(ActionTypes.START_PLEDGE_STATE_MACHINE, payload),
    storeCurrentVideoPosition: (
        payload: rt.IStoreCurrentVideoPositionPayload
    ) => createAction(ActionTypes.STORE_CURRENT_VIDEO_POSITION, payload),
    tokenUpdateFailure: (payload: rt.IEpicErrorPayload) =>
        createAction(ActionTypes.TOKEN_UPDATE_FAILURE, payload),
    tokensUpdated: (payload: {}) =>
        createAction(ActionTypes.TOKENS_UPDATED, payload),
    updateRoleEvent: (payload: rt.IUpdateRoleEventPayload) =>
        createAction(ActionTypes.UPDATE_ROLE_EVENT, payload),
    uploadProgressUpdate: (payload: rt.IUploadProgressUpdate) =>
        createAction(ActionTypes.UPLOAD_PROGRESS_UPDATE, payload),
    uploadProtectedFile: (payload: rt.IUploadProtectedFile) =>
        createAction(ActionTypes.UPLOAD_PROTECTED_FILE, payload),
    uploadVideoFile: (payload: rt.IUploadVideoFiles) =>
        createAction(ActionTypes.UPLOAD_VIDEO_FILE, payload),
    verifyRecaptchaToken: (payload: rt.IVerifyRecaptchaTokenPayload) =>
        createAction(ActionTypes.VERIFY_RECAPTCHA_TOKEN, payload),

    respondToWhoisRequest: (payload: rt.IRespondToWhoisRequestPayload) =>
        createAction(ActionTypes.RESPOND_TO_WHOIS_REQUEST, payload),
    setCurrentRoleEvents: (payload: rt.ICurrentRoleEventsPayload) =>
        createAction(ActionTypes.CURRENT_ROLE_EVENTS, payload),

    turnOnOrOffKeyboardCommands: (payload: rt.IOnOffKeyboardCommandsPayload) =>
        createAction(ActionTypes.TURN_ON_OR_OFF_KEYBOARD_COMMANDS, payload),

    requestNewLanguage: (payload: rt.ISetCurrentLanguage) =>
        createAction(ActionTypes.REQUEST_NEW_LANGUAGE, payload),
    setActiveLanguage: (payload: rt.ISetCurrentLanguage) =>
        createAction(ActionTypes.SET_ACTIVE_LANGUAGE, payload),
    saveRoleLanguagePref: (payload: rt.ISaveRoleLanguagePref) =>
        createAction(ActionTypes.SAVE_ROLE_LANGUAGE_PREFERENCE, payload),
    showToast: (payload: rt.IShowToastPayload) =>
        createAction(ActionTypes.SHOW_TOAST, payload),
    failToFetch: (payload: {}) =>
        createAction(ActionTypes.FAIL_TO_FETCH, payload),
    failToFetchFailure: (payload: {}) =>
        createAction(ActionTypes.FAIL_TO_FETCH_FINAL_FAILURE, payload),
    setFailToFetchCount: (payload: rt.ISetFailToFetchCount) =>
        createAction(ActionTypes.SET_FAIL_TO_FETCH_COUNT, payload),
    saveAuthStatus: (payload: rt.ISaveAuthStatus) =>
        createAction(ActionTypes.SAVE_AUTH_STATUS, payload),
    saveSignUpParams: (payload: rt.ISaveSignUpPayload) =>
        createAction(ActionTypes.SAVE_SIGN_UP_PARAMS, payload),
    saveRawCognitoUser: (payload: rt.IRawCognitoUser) =>
        createAction(ActionTypes.SAVE_RAW_COGNITO_USER, payload),
    setLoginDetails: (payload: rt.ILoginDetails) =>
        createAction(ActionTypes.SET_LOGIN_DETAILS, payload),
    setRegistrationDetails: (payload: rt.IRegistrationDetails) =>
        createAction(ActionTypes.SET_REGISTRATION_DETAILS, payload),
    saveClientDetails: (payload: rt.ISaveClientDetails) =>
        createAction(ActionTypes.SAVE_CLIENT_DETAILS, payload),
    saveCurrentPlayer: (payload: rt.ISaveCurrentPlayer) =>
        createAction(ActionTypes.SAVE_CURRENT_PLAYER, payload),
};

export const Actions = {
    ...AppActions,
    ...AuthActions,
};

export type ActionsType = rt.ActionsUnion<typeof Actions>;
