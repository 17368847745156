import gql from "graphql-tag";

import { IHighlightLikeEvent } from "app/graphql_types";
import { highlightLikeEventFields } from "app/graqhQlFragments";

export const HIGHLIGHT_LIKE_DATA = gql`
    query getHighlightLikeData($vHightlightIds: [Int], $vRoleId: Int) {
        getHighlightLikeData(
            vHightlightIds: $vHightlightIds
            vRoleId: $vRoleId
        ) {
            nodes {
                ...highlightLikeEventFields
            }
        }
    }
    ${highlightLikeEventFields}
`;

export interface IResult {
    getHighlightLikeData: {
        nodes: IHighlightLikeEvent[];
    };
    loading: boolean;
    error?: any;
}

export interface IVariables {
    vHightlightIds: number[];
    vRoleId: number;
}

// export default class QHighlightLikeData extends Query<IResult, IVariables> {}
