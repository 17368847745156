
import { createEpicMiddleware } from "redux-observable";
import * as ReselectTools from "reselect-tools";
import { composeWithDevTools } from 'redux-devtools-extension';

import { appRootReducer, rootEpic } from "../../redux"; // issue here for absolute imports is the name matches the package
import { authReducer } from "redux_auth";

import * as selectors from "../selectors";

const redux = require("redux")

// const todos = (state : string[] = [], action: {type: string, text: string}) => {
//     switch (action.type) {
//       case 'ADD_TODO':
//         return state.concat([action.text])
//       default:
//         return state
//     }
//   }

// console.log(typeof appRootReducer)
// console.log(typeof authReducer)
// const rootReducer1 = redux.combineReducers({test: todos});
// console.log(typeof rootReducer1)
// const rootReducer = combineReducers({auth: authReducer});
const rootReducer = redux.combineReducers({app: appRootReducer, auth: authReducer});
let composeEnhancers = redux.compose;
if (process.env.NODE_ENV !== "production") {
    composeEnhancers = composeWithDevTools({
        // Specify name here, actionsBlacklist, actionsCreators and other options if needed
    });
}
//     try {
//         // @ts-ignore
//         composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({maxAge: 500, trace: true});
//     } catch {
//         // do nothing
//     }
// }
const epicMiddleware = createEpicMiddleware();

export const store = redux.createStore(
    rootReducer,
    composeEnhancers(
        redux.applyMiddleware(epicMiddleware),
    ),
    // redux.applyMiddleware(epicMiddleware)
    // redux.compose(
    //         redux.applyMiddleware(epicMiddleware),
    // ),
);

// @ts-ignore
epicMiddleware.run(rootEpic);

ReselectTools.getStateWith(() => store.getState());

ReselectTools.registerSelectors(selectors);
