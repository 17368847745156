/** @format */

import { InMemoryCache } from "@apollo/client/cache";
import { ApolloClient } from "@apollo/client";
import { ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError as onErrorApollo } from "@apollo/client/link/error";
import { createHttpLink } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { Auth } from "aws-amplify";

import { Actions } from "redux/app_actions";
import { store } from "redux/store";

import { prepareUserData } from "./DataUtils";
import { IStore } from "redux/reducer_types";
// import { showToast } from app/AppSupport/Utils";

// import intl from "react-intl-universal";

// const xtx = (str: string, key?: string) => intl.get(key || str || "x") || str;

const MAX_RECOVERY_ATTEMPTS = 4;

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
    // tslint:disable-next-line:max-line-length
    const userEmailKey = `CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_WEB_APP_ID}.LastAuthUser`;
    const userEmail = localStorage.getItem(userEmailKey);
    const tokenKey = `CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_WEB_APP_ID}.${userEmail}.idToken`;
    const token = localStorage.getItem(tokenKey);
    const state: IStore = store.getState();
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
            "x-api-key": state.app.authStatus !== "signedIn" ? process.env.REACT_APP_POSTGRAPHILE_API_KEY : "no_key_if_signed_in",
        },
    };
});

const cache = new InMemoryCache({
    // @ts-ignore
    dataIdFromObject: (object) => object.nodeId || null,
});

// N.B. to persist the cache, need to handle deletions and other changes, which could happen by other users
// persistCache({
//     cache,
//     storage: window.localStorage,
// });

// const defaultOptions = {
//     query: {
//         errorPolicy: "all",
//         fetchPolicy: "no-cache",
//     },
// };

// see https://github.com/apollographql/apollo-link/issues/297

const recoveryLink = new RetryLink({
    attempts: (count, operation, error) => {
        const state: IStore = store.getState();
        if (state.app.authStatus !== "signedIn") {
            return false
        } else if (error.statusCode === 401 && count < MAX_RECOVERY_ATTEMPTS) {
            return new Promise((resolve, reject) => {
                Auth.currentAuthenticatedUser()
                    .then((userdata) => {
                        console.log(
                            `in ApolloUtils, got userdata of ${JSON.stringify(
                                userdata
                            )}`
                        );
                        const savedUser = prepareUserData(false, userdata);
                        store.dispatch(
                            Actions.saveUserData({ user: savedUser })
                        );
                        resolve(true);
                    })
                    .catch((err) => {
                        store.dispatch(
                            Actions.tokenUpdateFailure({ errorData: err })
                        );
                    });
            });
        } else if (
            error.message === "Failed to fetch" &&
            count < MAX_RECOVERY_ATTEMPTS
        ) {
            return true;
        } else if (
            error.message === "Failed to fetch" &&
            count === MAX_RECOVERY_ATTEMPTS
        ) {
            store.dispatch(Actions.failToFetchFailure({}));
            return false;
        } else {
            return false;
        }
    },
    delay: (count, operation, error) => {
        if (error.statusCode === 401 && count < MAX_RECOVERY_ATTEMPTS) {
            return count * 500 + 1000 * Math.random();
        } else if (
            error.message === "Failed to fetch" &&
            count < MAX_RECOVERY_ATTEMPTS
        ) {
            console.log(
                `in recoveryLink delay: error.message is Failed to fetch.  count is ${count}`
            );
            console.log(error);
            store.dispatch(Actions.failToFetch({}));
            const delayMs =
                count *
                    parseInt(
                        process.env.REACT_APP_DB_MESSAGE_MIN_TIME || "5000",
                        10
                    ) +
                parseInt(
                    process.env.REACT_APP_DB_MESSAGE_VARIABLE_TIME || "3000",
                    10
                ) *
                    Math.random();
            console.log(`delaying next connection attempt by ${delayMs}ms`);
            return delayMs;
        } else {
            return count * 1000 * Math.random();
        }
    },
});

const errorLink = onErrorApollo(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
            // tslint:disable-next-line: no-console
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
                    locations
                )}, Path: ${path}`
            )
        );
        // TODO: send these to sentry as well
    }

    if (networkError) {
        // tslint:disable-next-line: no-console
        console.log(`[Network error]: ${networkError}`);
    }
});

// const apolloClient = new ApolloClient({cache, link: authLink.concat(httpLink)});
export const apolloClientInstance = new ApolloClient({
    cache,
    link: ApolloLink.from([errorLink, recoveryLink, authLink, httpLink]),
});

// https://stackoverflow.com/a/38552302/4416993
// const parseJwtWithoutVerification = (token) => {
//     const base64Url = token.split(".")[1];
//     const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
//     return JSON.parse(window.atob(base64));
// };
