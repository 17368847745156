/** @format */

import { Intent, IToastProps } from "@blueprintjs/core";
import * as Sentry from "@sentry/browser";
import { API, Storage } from "aws-amplify";
import {
    ActionsObservable,
    combineEpics,
    ofType,
    StateObservable,
} from "redux-observable";
import { concat, forkJoin, from, interval, of } from "rxjs";
import { ajax } from "rxjs/ajax";
import {
    catchError,
    filter,
    flatMap,
    map,
    merge,
    mergeMap,
    // mergeMapTo,
    take,
    takeUntil,
} from "rxjs/operators";
import intl from "react-intl-universal";
import http from "axios";

import { Languages } from "Resources";
import {
    fileUploadProgress,
    getQueueErrorToastMessage,
    getPlayerInfoToastMessage,
    longUpload,
    onEvent,
} from "app/AppSupport/Utils";
import {
    showToast,
    apolloClientInstance as client,
    AppToaster,
} from "app/AppSupport/Utils";
import { SET_LATEST_ROLE } from "auth/MSetLatestRole";
import { UPDATE_ROLE_PREFERENCES } from "auth/MSetRolePreferences";
import { TOKEN_REFRESH_WITH_GQL } from "auth/QSimpleForTokenRefresh";
import { ADD_OR_UPDATE_ENTITY } from "components/admin/graphql/MAddOrUpdateEntity";
import { ADD_PROTECTED_FILE } from "components/admin/graphql/MAddProtectedFile";
import { ADD_LIKE_OR_RATING } from "components/highlights/graphql/MAddHighlightLikeEvent";
import { ADD_HIGHLIGHT_TO_SEQUENCE } from "components/highlights/graphql/MAddHighlightToSequence";
import { ADD_HIGHLIGHT_SET } from "components/highlights/graphql/MAddTeamHighlight";
import { REPLACE_HIGHLIGHT_SEQUENCE_ITEMS } from "components/highlights/graphql/MReplaceHighlightSequenceItems";
import { HIGHLIGHT_LIKE_DATA } from "components/highlights/graphql/QHighlightLikeData";
import {
    IOnePlayerFactsResult,
    ONE_PLAYER_FACTS,
} from "components/profiles/graphql/QPlayerInfoByRole";
import { GET_HSID_FOR_SYSTEM_HIGHLIGHT_SEQ } from "components/profiles/graphql/QSystemHighlightSeqForPlayer";

import history from "app/utils/history";

import { ActionTypes } from "./app_actions";
import {
    IAddHighlightToSequencePayload,
    IAddLikeEventPayload,
    IAddMultipleToQueuePayload,
    IAddProtectedFile,
    IAddRoleEventsPayload,
    IAddToQueuePayload,
    ICaptureAppRoleEventPayload,
    IChooseRole,
    ICreateOrUpdateSequencePayload,
    IEpicErrorPayload,
    IEpicSentryMessagePayload,
    IExecutePgFnInLambdaPayload,
    ILogPublicActionPayload,
    IPlusCodePayload,
    IPrepareSystemHighlightSeqPayload,
    IQTaskCompletedPayload,
    IReplaceHSItems,
    IRespondToWhoisRequestPayload,
    ISaveNewEntityPayload,
    ISaveNewHighlightPayload,
    ISaveRoleLanguagePref,
    ISaveRolePreferencesPayload,
    ISetCurrentLanguage,
    IShowToastPayload,
    IStartPledgeStateMachinePayload,
    IStore,
    IUpdateHighlightPayload,
    IUpdateRoleEventPayload,
    IUploadProtectedFile,
    IUploadVideoFiles,
    IVerifyRecaptchaTokenPayload,
} from "./reducer_types";

import { CAPTURE_APP_ROLE_EVENT } from "components/app_role_events/graphql/MCaptureAppRoleEvent";
import { UPDATE_HIGHLIGHT } from "components/highlights/graphql/MUpdateHighlight";
import { HIGHLIGHTS_FOR_TAGGING_BOTTOM } from "components/highlights/graphql/QHighlightsForTaggingBottom";
import { ADD_ROLE_EVENTS } from "components/role_events/graphql/MAddRoleEvents";
import { UPDATE_ROLE_EVENT } from "components/role_events/graphql/MUpdateRoleEvent";
// import { EVENT_AND_HIGHLIGHTS } from "components/video/graphql/QEventAndHighlights";
import { EVENT_DATA_BY_EVENT_IDS_OR_SEQ } from "components/video/graphql/QEventDataByEventIdList";
import { CREATE_OR_UPDATE_SEQUENCE } from "components/profiles/graphql/MUpdateSequence";

import {
    endMeasuringBandwidthEpic,
    startMeasuringBandwidthEpic,
} from "./bandwidthEpics";

const xtx = (str: string, key?: string) => intl.get(key || str || "x") || str;

// seconds at which queue is considered to have timed out
const Q_TIMEOUT = parseInt(
    process.env.REACT_APP_VIDEO_TIMEOUT_IN_SECS || "15",
    10
);
console.log(`Q_TIMEOUT is ${Q_TIMEOUT}`);

interface IActionWithoutPayload {
    type: string;
}

interface IEpicError {
    type: string;
    payload: IEpicErrorPayload;
}

export const epicError = (
    action$: ActionsObservable<IEpicError>,
    state$: StateObservable<IStore>
) => {
    // epic receives the message in its payload and logs it to Sentry; also shows error in Toast
    return action$.pipe(
        ofType(ActionTypes.EPIC_ERROR),
        mergeMap((action) =>
            from(
                Sentry.captureException(
                    JSON.stringify(action.payload.errorData)
                )
            ).pipe(
                take(1),
                map(() => {
                    const toastVals: IToastProps = {
                        intent: Intent.DANGER,
                        message: action.payload.errorData.message,
                        timeout: 0,
                    };
                    AppToaster.show(toastVals);
                    return {
                        payload: {
                            message: "Notified Sentry in captureException",
                        },
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                })
            )
        )
    );
};

interface IEpicSentryMessage {
    type: string;
    payload: IEpicSentryMessagePayload;
}

export const epicSentryMessage = (
    action$: ActionsObservable<IEpicSentryMessage>,
    state$: StateObservable<IStore>
) => {
    // epic receives the message in its payload and logs it to Sentry; also shows error in Toast
    return action$.pipe(
        ofType(ActionTypes.EPIC_SENTRY_MESSAGE),
        mergeMap((action) =>
            from(
                Sentry.captureMessage(
                    JSON.stringify(action.payload.errorMessage)
                )
            ).pipe(
                take(1),
                map(() => {
                    if (action.payload.toastMessage) {
                        const toastVals: IToastProps = {
                            intent: action.payload.toastIntent || Intent.DANGER,
                            message: action.payload.toastMessage,
                            timeout: action.payload.toastTimeout || 0,
                        };
                        AppToaster.show(toastVals);
                    }
                    return {
                        payload: {
                            message: "Notified Sentry in captureMessage",
                        },
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                })
            )
        )
    );
};

interface IEpicCookieApproval {
    type: string;
    payload: any;
}

export const epicCookieApproval = (
    action$: ActionsObservable<IEpicCookieApproval>,
    state$: StateObservable<IStore>
) => {
    // epic receives the message in its payload and logs it to Sentry; also shows error in Toast
    return action$.pipe(
        ofType(ActionTypes.SAVE_ROLES_DATA),
        map(() => {
            if (
                state$.value.app.activeRole &&
                state$.value.app.cookiesApproved
            ) {
                const activeRole = state$.value.app.activeRole;
                if (
                    activeRole.userRoleConfidentialById.rolePreferences ===
                        null ||
                    (activeRole.userRoleConfidentialById.rolePreferences !==
                        null &&
                        activeRole.userRoleConfidentialById.rolePreferences
                            .cookiesApproved !== true)
                ) {
                    return {
                        payload: {
                            activeRoleId: activeRole.id,
                            newPreferences: {
                                cookiesApproved: true,
                            },
                        },
                        type: ActionTypes.SAVE_ROLE_PREFERENCES,
                    };
                }
            }
            return {
                payload: { message: "No update to cookiesApproved pref" },
                type: ActionTypes.EPIC_COMPLETED,
            };
        })
    );
};

export const refreshTokenWithGql = (
    action$: ActionsObservable<IActionWithoutPayload>,
    state$: StateObservable<IStore>
) =>
    // epic makes a gql request which will automatically refresh token if it is expired;
    // the action emitted on success will be SAVE_USER_DATA
    action$.pipe(
        ofType(ActionTypes.REFRESH_TOKEN_WITH_GQL),
        mergeMap((action) =>
            from(
                client.query({
                    query: TOKEN_REFRESH_WITH_GQL,
                })
            ).pipe(
                map(() => ({
                    type: ActionTypes.TOKENS_UPDATED,
                })),
                catchError((err) => {
                    return of({
                        type: ActionTypes.TOKEN_UPDATE_FAILURE,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IChooseRoleAction {
    type: string;
    payload: IChooseRole;
}

export const chooseRoleEpic = (
    action$: ActionsObservable<IChooseRoleAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.CHOOSE_ROLE),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: SET_LATEST_ROLE,
                    variables: { input: { vRoleId: action.payload.roleId } },
                })
            ).pipe(
                map(() => ({
                    payload: { message: "Set latest role" },
                    type: ActionTypes.EPIC_COMPLETED,
                })),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface ISetRolePreferencesAction {
    type: string;
    payload: ISaveRolePreferencesPayload;
}

export const setRolePreferencesEpic = (
    action$: ActionsObservable<ISetRolePreferencesAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.SAVE_ROLE_PREFERENCES),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: UPDATE_ROLE_PREFERENCES,
                    variables: {
                        input: {
                            id: action.payload.activeRoleId,
                            patch: {
                                rolePreferences: {
                                    ...state$.value.app.activeRole!
                                        .userRoleConfidentialById
                                        .rolePreferences,
                                    ...action.payload.newPreferences,
                                },
                            },
                        },
                    },
                })
            ).pipe(
                map(() => ({
                    payload: {
                        variables: {
                            vAppEventCategory: "user",
                            vAppEventName: "save_user_prefs",

                            vAppEventData: {
                                prefsAdded: action.payload.newPreferences,
                            },
                        },
                    },
                    type: ActionTypes.CAPTURE_APP_ROLE_EVENT,
                })),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IAddRoleEventsAction {
    type: string;
    payload: IAddRoleEventsPayload;
}

export const addRoleEventsEpic = (
    action$: ActionsObservable<IAddRoleEventsAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.ADD_ROLE_EVENTS),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: ADD_ROLE_EVENTS,
                    variables: action.payload.variables,

                    // may want to provide this list in the payload in the future
                    refetchQueries: state$.value.app.taggingQueryVariables
                        ? [
                              {
                                  query: EVENT_DATA_BY_EVENT_IDS_OR_SEQ,
                                  variables:
                                      state$.value.app
                                          .eventDataQueryEventIdList,
                              },
                          ]
                        : [],
                })
            ).pipe(
                map(() => {
                    showToast(
                        action.payload.toastMessage,
                        action.payload.toastMs,
                        Intent.SUCCESS
                    );
                    return {
                        payload: { message: action.payload.toastMessage },
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IUpdateRoleEventAction {
    type: string;
    payload: IUpdateRoleEventPayload;
}

export const updateRoleEventEpic = (
    action$: ActionsObservable<IUpdateRoleEventAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.UPDATE_ROLE_EVENT),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: UPDATE_ROLE_EVENT,
                    variables: action.payload.variables,

                    refetchQueries: state$.value.app.taggingQueryVariables
                        ? [
                              {
                                  query: EVENT_DATA_BY_EVENT_IDS_OR_SEQ,
                                  variables:
                                      state$.value.app
                                          .eventDataQueryEventIdList,
                              },
                          ]
                        : [],
                })
            ).pipe(
                map(() => {
                    showToast(
                        action.payload.toastMessage,
                        2000,
                        Intent.SUCCESS
                    );
                    return {
                        payload: { message: action.payload.toastMessage },
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IUpdateHighlightAction {
    type: string;
    payload: IUpdateHighlightPayload;
}

export const updateHighlightEpic = (
    action$: ActionsObservable<IUpdateHighlightAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.UPDATE_HIGHLIGHT),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: UPDATE_HIGHLIGHT,
                    variables: action.payload.variables,

                    refetchQueries: state$.value.app.taggingQueryVariables
                        ? [
                              {
                                  query: HIGHLIGHTS_FOR_TAGGING_BOTTOM,
                                  variables:
                                      state$.value.app.taggingQueryVariables,
                              },
                          ]
                        : [],
                })
            ).pipe(
                map(() => {
                    showToast(
                        action.payload.toastMessage,
                        2000,
                        Intent.SUCCESS
                    );
                    return {
                        payload: { message: action.payload.toastMessage },
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface ICaptureAppRoleEventAction {
    type: string;
    payload: ICaptureAppRoleEventPayload;
}

export const captureAppRoleEventEpic = (
    action$: ActionsObservable<ICaptureAppRoleEventAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.CAPTURE_APP_ROLE_EVENT),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: CAPTURE_APP_ROLE_EVENT,
                    variables: {
                        input: {
                            ...action.payload.variables,
                            vAppEventData: {
                                ...action.payload.variables.vAppEventData,
                                asofdate: Date.now(),
                                // version,
                                bandwidthGrade: state$.value.app.bandwidthGrade,
                                bandwidthEstimate:
                                    state$.value.app.bandwidthEstimate,
                                browserData: state$.value.app.browserData,
                            },
                            vGeoData: state$.value.app.geoData || {},
                            vRoleOwner:
                                (state$.value.app.activeRole &&
                                    state$.value.app.activeRole.id) ||
                                -1,
                        },
                    },
                })
            ).pipe(
                map(() => {
                    return {
                        payload: { lastActivityTime: Date.now() },
                        type: ActionTypes.UPDATE_LAST_ACTIVITY_TIME,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface ISaveNewHighlightAction {
    type: string;
    payload: ISaveNewHighlightPayload;
}

export const saveNewTeamHighlightEpic = (
    // misnomer - used for indiv and team highlights
    action$: ActionsObservable<ISaveNewHighlightAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.SAVE_NEW_HIGHLIGHT),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: ADD_HIGHLIGHT_SET,
                    variables: {
                        input: action.payload,
                    },

                    refetchQueries: state$.value.app.taggingQueryVariables
                        ? [
                              {
                                  query: HIGHLIGHTS_FOR_TAGGING_BOTTOM,
                                  variables:
                                      state$.value.app.taggingQueryVariables,
                              },
                          ]
                        : [],
                })
            ).pipe(
                map((res: any) => {
                    let message;
                    let toastIntent;
                    let toastDuration;
                    // @ts-ignore -- need to fix the res.data type; currently {}
                    if (
                        res &&
                        res.data &&
                        res.data.addHighlight.messages &&
                        res.data.addHighlight.messages.length > 0
                    ) {
                        // @ts-ignore -- need to fix the res.data type; currently {}
                        message = res.data.addHighlight.messages.join("; ");
                        toastIntent = Intent.WARNING;
                        toastDuration = 0;
                    } else {
                        const numHighlights = action.payload.vAddToTeam
                            ? 1
                            : 0 + action.payload.vIndividualRoleIds.length;
                        message =
                            numHighlights === 1
                                ? "New highlight added"
                                : `New highlights added (${numHighlights})`;
                        toastIntent = Intent.SUCCESS;
                        toastDuration = 2000;
                    }
                    showToast(message, toastDuration, toastIntent);
                    return {
                        payload: { message },
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IAddLikeEventAction {
    type: string;
    payload: IAddLikeEventPayload;
}

export const addLikeEventEpic = (
    action$: ActionsObservable<IAddLikeEventAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.ADD_LIKE_EVENT),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: ADD_LIKE_OR_RATING,
                    variables: {
                        input: { highlightLikeEvent: action.payload.variables },
                    },

                    refetchQueries: [
                        {
                            query: HIGHLIGHT_LIKE_DATA,
                            variables: action.payload.refetchVariables,
                        },
                    ],
                })
            ).pipe(
                map((res) => {
                    let message = "Like change recorded";
                    if (action.payload.variables.isAFavourite !== null) {
                        message = "Favourite change recorded";
                    } else if (action.payload.variables.qualityScore !== null) {
                        message = "Rating change recorded";
                    }
                    showToast(message, 2000, Intent.SUCCESS);
                    return {
                        payload: {},
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IAddProtectedFileAction {
    type: string;
    payload: IAddProtectedFile;
}

export const addProtectedFileEpic = (
    action$: ActionsObservable<IAddProtectedFileAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.ADD_PROTECTED_FILE),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: ADD_PROTECTED_FILE,
                    variables: { input: action.payload },
                })
            ).pipe(
                map(() => {
                    const message = `File ${action.payload.vActualFilename} uploaded and added`;
                    showToast(message, 2000, Intent.SUCCESS);
                    return {
                        payload: { message: "Protected file mutation done" },
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IUploadProtectedFileAction {
    type: string;
    payload: IUploadProtectedFile;
}

export const uploadProtectedFileEpic = (
    action$: ActionsObservable<IUploadProtectedFileAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.UPLOAD_PROTECTED_FILE),
        // tap((val) => {
        //     console.log(`UPLOAD_PROTECTED_FILE: Before mergeMap:`);
        //     console.log(val);
        // }),
        mergeMap((action) => {
            // TODO - check the size of the file to choose upload function to use
            let putFn = Storage.put;
            if (true) {
                // @ts-ignore
                putFn = longUpload;
            }
            // console.log("uploading protected file - probably json");
            return from(
                putFn(action.payload.uuidFilename, action.payload.file, {
                    contentType: action.payload.file.type,
                    level: "protected",
                    progressCallback: fileUploadProgress,
                })
            ).pipe(
                // tap((val) => {
                //     console.log(`UPLOAD_PROTECTED_FILE: After Storage.Put, before map:`);
                //     console.log(val);
                // }),
                map(() => {
                    if (
                        action.payload.followUpAction &&
                        action.payload.followUpAction !==
                            ActionTypes.EPIC_COMPLETED
                    ) {
                        if (action.payload.roleOwner) {
                            const itemKey = `aws.cognito.identity-id.${process.env.REACT_APP_IDENTITY_POOL_ID}`;
                            const identityId = localStorage.getItem(itemKey);
                            // const followUpPayload = action.payload.followUpAddlPayload.eventVars.input;
                            // this is a file that needs to be entered into the protected_file table
                            if (identityId !== null) {
                                const newEventVars = {
                                    ...action.payload.followUpAddlPayload
                                        .eventVars,
                                };
                                if (newEventVars.input === undefined) {
                                    newEventVars.input = {};
                                }
                                newEventVars.input.vActualFilename =
                                    action.payload.file.name ||
                                    action.payload.uuidFilename;
                                newEventVars.input.vIdentityId = identityId;
                                newEventVars.input.vRoleOwner =
                                    action.payload.roleOwner;
                                newEventVars.input.vS3Filename =
                                    action.payload.uuidFilename;
                                const payload = { eventVars: newEventVars };
                                // const payload = {
                                //     ...action.payload.followUpAddlPayload.eventVars.input,
                                //     vActualFilename: action.payload.file.name || action.payload.uuidFilename,
                                //     vIdentityId: identityId,
                                //     vRoleOwner: action.payload.roleOwner,
                                //     vS3Filename: action.payload.uuidFilename,
                                // };
                                return {
                                    payload,
                                    type: action.payload.followUpAction,
                                };
                            } else {
                                return {
                                    payload: {
                                        errorMessage: {
                                            message:
                                                "No identityId found in local storage",
                                            source: "UPLOAD_PROTECTED_FILE",
                                        },
                                        toastMessage:
                                            process.env.NODE_ENV ===
                                            "production"
                                                ? undefined
                                                : "No identityId found in local storage",
                                    },
                                    type: ActionTypes.EPIC_SENTRY_MESSAGE,
                                };
                            }
                        } else {
                            return {
                                payload: {
                                    errorMessage: {
                                        message: `No roleOwner provided; cannot enter file info into database;
                                                  file uploaded, however; TODO - only upload if this check succeeds`,
                                        source: "UPLOAD_PROTECTED_FILE",
                                    },
                                    toastMessage:
                                        process.env.NODE_ENV === "production"
                                            ? undefined
                                            : "No roleOwner provided; cannot enter file info into database",
                                },
                                type: ActionTypes.EPIC_SENTRY_MESSAGE,
                            };
                        }
                    } else if (
                        action.payload.followUpAction &&
                        action.payload.followUpAction ===
                            ActionTypes.EPIC_COMPLETED
                    ) {
                        // console.log("in next to bottom of upload protected file");
                        return {
                            payload: {
                                toastIntent:
                                    action.payload.followUpAddlPayload
                                        .toastIntent || Intent.PRIMARY,
                                toastMessage:
                                    action.payload.followUpAddlPayload
                                        .toastMessage || "File upload complete",
                                toastMs:
                                    action.payload.followUpAddlPayload
                                        .toastMs || 2000,
                            },
                            type: ActionTypes.EPIC_COMPLETED,
                        };
                    } else {
                        // console.log("in very bottom of upload protected file");
                        return {
                            payload: {
                                toastIntent: Intent.PRIMARY,
                                toastMessage: "File upload complete",
                                toastMs: 2000,
                            },
                            type: ActionTypes.EPIC_COMPLETED,
                        };
                    }
                }),
                // tap((val) => {
                //     console.log(`UPLOAD_PROTECTED_FILE: After map, before catchError:`);
                //     console.log(val);
                // }),
                // @ts-ignore
                catchError((error, source) => {
                    // console.log("error caught in upload protected file");
                    // https://medium.freecodecamp.org/how-to-get-a-new-access-token-using-redux-observables-and-the-refresh-token-api-d38d875a8add
                    // can't really get here without having already authenticated at least once
                    const idTokenExp =
                        state$.value.auth.authProps!.authData!.signInUserSession
                            .idToken.payload.exp;
                    // console.log(idTokenExp, new Date().getTime());
                    if (idTokenExp * 1000 < new Date().getTime()) {
                        // aws Storage shows 400 for jwt expiration
                        // console.log(`protected file error: idTokenExp: ${idTokenExp}, now: ${new Date().getTime()}`);
                        return action$.ofType(ActionTypes.SAVE_USER_DATA).pipe(
                            takeUntil(
                                action$.ofType(ActionTypes.TOKEN_UPDATE_FAILURE)
                            ),
                            take(1),
                            // mergeMapTo(source),
                            merge(
                                of({
                                    type: ActionTypes.REFRESH_TOKEN_WITH_GQL,
                                }),
                                source
                            )
                        );
                    } else {
                        return of({
                            type: ActionTypes.EPIC_ERROR,
                            payload: { errorData: error },
                        });
                    }
                })
                // tap((val) => {
                //     console.log(`UPLOAD_PROTECTED_FILE: After catchError, at end:`);
                //     console.log(val);
                // }),
            );
        })
    );

export const epicTokensUpdateFailure = (
    action$: ActionsObservable<IEpicError>,
    state$: StateObservable<IStore>
) => {
    return action$.pipe(
        ofType(ActionTypes.TOKEN_UPDATE_FAILURE),
        map((action) => {
            return {
                payload: action.payload,
                type: ActionTypes.EPIC_ERROR,
            };
        })
    );
};

interface IUploadVideoFileAction {
    type: string;
    payload: IUploadVideoFiles;
}

export const uploadVideoFileEpic = (
    action$: ActionsObservable<IUploadVideoFileAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.UPLOAD_VIDEO_FILE),
        mergeMap((action) => {
            // TODO - check the size of the file to choose upload function to use
            let putFn = Storage.put;
            if (true) {
                // @ts-ignore
                putFn = longUpload;
            }
            const obsArray: any[] = [];
            const infoArray: any[] = [];
            onEvent(
                null,
                { uploadVideoFileEpicPayload: action.payload },
                `In uploadVideoFileEpic with payload`
            );
            for (let i = 0; i < action.payload.files.length; i++) {
                const f = action.payload.files[i];
                infoArray.push({
                    contentType: f.type,
                    filename: f.name,
                });
                obsArray.push(
                    from(
                        putFn(`video_uploads/${f.name}`, f, {
                            contentType: f.type,
                            level: "protected",
                            progressCallback: fileUploadProgress, // may not work with multiple
                        })
                    ).pipe(
                        catchError((err) =>
                            of({
                                type: ActionTypes.EPIC_ERROR,
                                payload: { errorData: err },
                            })
                        )
                    )
                );
            }
            // console.log(`obs array has ${obsArray.length} elements`);
            onEvent(
                null,
                { uploadVideoFileEpic_infoArray: infoArray },
                `In uploadVideoFileEpic: built obsArray`
            );
            return from(forkJoin(obsArray)).pipe(
                map(() => {
                    if (action.payload.videoData) {
                        const { videoData } = action.payload;
                        // console.log("uploading json file");
                        // console.log(videoData);
                        const blob = new Blob(
                            [JSON.stringify(videoData, null, 2)],
                            {
                                type: "application/json",
                            }
                        );
                        return {
                            payload: {
                                file: blob,
                                roleOwner: action.payload.videoData.roleId,
                                uuidFilename: `${action.payload.directory}/${action.payload.files[0].name}.json`,
                            },
                            type: ActionTypes.UPLOAD_PROTECTED_FILE,
                        };
                    } else {
                        return {
                            type: ActionTypes.EPIC_COMPLETED,
                            payload: {},
                        };
                    }
                }),
                // @ts-ignore
                catchError((error, source) => {
                    // https://medium.freecodecamp.org/how-to-get-a-new-access-token-using-redux-observables-and-the-refresh-token-api-d38d875a8add
                    // can't really get here without having already authenticated at least once
                    const idTokenExp =
                        state$.value.auth.authProps!.authData!.signInUserSession
                            .idToken.payload.exp;
                    if (idTokenExp * 1000 < new Date().getTime()) {
                        // aws Storage shows 400 for jwt expiration
                        // console.log(`upload video file error: idTokenExp: ${idTokenExp}, now: ${new Date().getTime()}`);
                        return action$.ofType(ActionTypes.SAVE_USER_DATA).pipe(
                            takeUntil(
                                action$.ofType(ActionTypes.TOKEN_UPDATE_FAILURE)
                            ),
                            take(1),
                            // mergeMapTo(source),
                            merge(
                                of({
                                    type: ActionTypes.REFRESH_TOKEN_WITH_GQL,
                                }),
                                source
                            )
                        );
                    } else {
                        return of({
                            type: ActionTypes.EPIC_ERROR,
                            payload: { errorData: error },
                        });
                    }
                })
            );
        })
    );

interface IGetPlusCodeDataAction {
    type: string;
    payload: IPlusCodePayload;
}

export const getPlusCodeDataEpic = (
    action$: ActionsObservable<IGetPlusCodeDataAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.GET_PLUS_CODE_DATA),
        mergeMap((action) =>
            ajax
                .getJSON(
                    `https://plus.codes/api?address=${encodeURIComponent(
                        action.payload.plusCodeText
                    )}&ekey=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
                )
                .pipe(
                    map((response) => {
                        // @ts-ignore
                        if (response.status === "ZERO_RESULTS") {
                            const err = new Error(
                                "No results from provided Plus Code"
                            );
                            return of({
                                type: ActionTypes.EPIC_ERROR,
                                payload: { errorData: err },
                            });
                        }
                        // prettier-ignore
                        // @ts-ignore
                        return {payload: { ...response, enteredPlusCode: action.payload.plusCodeText },
                            type: ActionTypes.SAVE_PLUS_CODE_DATA,
                        };
                    }),
                    catchError((err) => {
                        return of({
                            type: ActionTypes.EPIC_ERROR,
                            payload: { errorData: err },
                        });
                    })
                )
        )
    );

interface IAddNewEntityAction {
    type: string;
    payload: ISaveNewEntityPayload;
}

export const addNewEntityEpic = (
    action$: ActionsObservable<IAddNewEntityAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.SAVE_NEW_ENTITY),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: ADD_OR_UPDATE_ENTITY,
                    variables: action.payload.eventVars,
                })
            ).pipe(
                map(() => {
                    const message = action.payload.eventVars.input
                        .vExistingEntityId
                        ? "Updated entity"
                        : "Added new entity";
                    showToast(message, 2000, Intent.SUCCESS);
                    return {
                        payload: { message },
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IGetIpDataAction {
    type: string;
    payload: {};
}

export const getIPDataEpic = (
    action$: ActionsObservable<IGetIpDataAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.GET_IP_DATA),
        mergeMap((action) =>
            ajax
                .getJSON(
                    // `https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`,
                    `https://ipinfo.io/geo?token=b57ed58d4404f5`
                )
                .pipe(
                    map((response) => {
                        // @ts-ignore
                        if (response.status === null) {
                            const err = new Error(
                                "No results from IP geo request"
                            );
                            return of({
                                type: ActionTypes.EPIC_ERROR,
                                payload: { errorData: err },
                            });
                        }
                        return {
                            payload: { response },
                            type: ActionTypes.SAVE_IP_DATA,
                        };
                    }),
                    catchError((err) => {
                        return of({
                            type: ActionTypes.EPIC_ERROR,
                            payload: { errorData: err },
                        });
                    })
                )
        )
    );

interface ISavePledgeAction {
    type: string;
    payload: IExecutePgFnInLambdaPayload;
}

export const savePledgeEpic = (
    action$: ActionsObservable<ISavePledgeAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.EXECUTE_PG_FN_IN_LAMBDA),
        mergeMap((action) =>
            from(
                API.post("gamesrestapi", "/pledges", {
                    body: action.payload,
                })
            ).pipe(
                map((response) => {
                    const message = action.payload.toastMessage;
                    showToast(message, 2000, Intent.SUCCESS);
                    const payload: IStartPledgeStateMachinePayload = {
                        new_pledge_id: 0,
                        pledge_data: action.payload,
                    };
                    try {
                        payload.new_pledge_id =
                            response.result[0].record_pledge;
                    } catch (e) {
                        //
                    }
                    return {
                        payload,
                        type: ActionTypes.START_PLEDGE_STATE_MACHINE,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IVerifyRecaptchaTokenAction {
    type: string;
    payload: IVerifyRecaptchaTokenPayload;
}

export const verifyRecaptchaTokenEpic = (
    action$: ActionsObservable<IVerifyRecaptchaTokenAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.VERIFY_RECAPTCHA_TOKEN),
        mergeMap((action) =>
            from(
                API.post("gamesrestapi", "/verifyRecaptchaToken", {
                    body: action.payload,
                })
            ).pipe(
                map((response) => {
                    if (action.payload.version === "v3") {
                        return {
                            payload: {
                                score:
                                    (response.message &&
                                        response.message.score) ||
                                    0.0,
                            },
                            type: ActionTypes.SAVE_RECAPTCHA_V3_SCORE,
                        };
                    } else {
                        return {
                            payload: { ...response },
                            type: ActionTypes.SAVE_RECAPTCHA_V2_RESPONSE,
                        };
                    }
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IStartPledgeStateMachineAction {
    type: string;
    payload: IStartPledgeStateMachinePayload;
}

export const startPledgeStateMachineEpic = (
    action$: ActionsObservable<IStartPledgeStateMachineAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.START_PLEDGE_STATE_MACHINE),
        mergeMap((action) =>
            from(
                API.post("gamesrestapi", "/initiatesteps", {
                    body: action.payload,
                })
            ).pipe(
                map(() => {
                    return {
                        payload: { message: "state machine started" },
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface ILogPublicAction {
    type: string;
    payload: ILogPublicActionPayload;
}

export const logPublicActionEpic = (
    action$: ActionsObservable<ILogPublicAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.LOG_PUBLIC_ACTION),
        mergeMap((action) =>
            from(
                API.post("videoRequest", "/publicactioncapture", {
                    body: action.payload,
                })
            ).pipe(
                map(() => {
                    return {
                        payload: { message: "public action logged" },
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IAddToQueueAction {
    type: string;
    payload: IAddToQueuePayload;
}

export const addToQueueEpic = (
    // when a task is added to the queue, this epic will fire a start task action
    // if there is no currently executing task
    action$: ActionsObservable<IAddToQueueAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.ADD_TO_QUEUE, ActionTypes.Q_IS_ACTIVE),
        map((action) => {
            const queues = state$.value.app.queues;
            if (queues && queues[action.payload.qid]) {
                const queue = queues[action.payload.qid];
                if (!queue.executing && queue.active) {
                    return {
                        type: ActionTypes.Q_START_NEXT_TASK,

                        payload: {
                            qid: action.payload.qid,
                        },
                    };
                } else if (queue.executing && queue.active) {
                    return {
                        payload: {
                            message: `task currently executing in ${action.payload.qid}`,
                        },
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                } else {
                    return {
                        payload: {
                            message: `WARNING in action ${action.payload.action.uniqueId}: queue ${action.payload.qid} is inactive`,
                        },
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                }
            } else {
                return {
                    payload: {
                        message: `queue not found: ${action.payload.qid}`,
                    },
                    type: ActionTypes.EPIC_COMPLETED,
                };
            }
        })
    );

interface IAddMultipleToQueueAction {
    type: string;
    payload: IAddMultipleToQueuePayload;
}

export const addMultipleToQueueEpic = (
    // when a task is added to the queue, this epic will fire a start task action
    // if there is no currently executing task
    action$: ActionsObservable<IAddMultipleToQueueAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.ADD_MULTIPLE_TO_QUEUE),
        flatMap((action) => {
            const queues = state$.value.app.queues;
            // eslint-disable-next-line
            const emittedActions = new Array();
            // eslint-disable-next-line
            const queuesStartingTasks = new Array();
            action.payload.tasklist.forEach((t) => {
                const qid = t.qid;
                // check if a tasks should be started for this queue
                if (
                    queues &&
                    queues[qid] &&
                    !queuesStartingTasks.includes(qid)
                ) {
                    if (!queues[qid].executing && queues[qid].active) {
                        emittedActions.push({
                            type: ActionTypes.Q_START_NEXT_TASK,

                            payload: {
                                qid,
                            },
                        });
                        queuesStartingTasks.push(qid);
                    } else if (!queues[qid].active) {
                        emittedActions.push({
                            payload: {
                                message: `WARNING in ADD_MULTIPLE_TO_QUEUE action ${t.action.uniqueId}: queue ${qid} is inactive`,
                            },
                            type: ActionTypes.EPIC_COMPLETED,
                        });
                    }
                }
            });
            if (emittedActions.length === 0) {
                emittedActions.push({
                    payload: {
                        message: `Tasks currently executing in all relevant queues`,
                    },
                    type: ActionTypes.EPIC_COMPLETED,
                });
            }
            return emittedActions;
        })
    );

interface ITaskCompletedAction {
    type: string;
    payload: IQTaskCompletedPayload;
}

export const queueTaskCompletedEpic = (
    // when a task is completed, this epic will fire a start task action
    // if there is no currently executing task (there should not be one)
    action$: ActionsObservable<ITaskCompletedAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.Q_TASK_COMPLETED),
        map((action) => {
            const queues = state$.value.app.queues;
            if (queues && queues[action.payload.qid]) {
                const queue = queues[action.payload.qid];
                if (!queue.executing && queue.queueList.length > 0) {
                    return {
                        type: ActionTypes.Q_START_NEXT_TASK,

                        payload: {
                            qid: action.payload.qid,
                        },
                    };
                } else if (queue.executing) {
                    return {
                        payload: {
                            message: `Error: task shows as currently executing in ${action.payload.qid}`,
                        },
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                } else {
                    return {
                        payload: { message: `Task completed` },
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                }
            } else {
                return {
                    payload: {
                        message: `queue not found: ${action.payload.qid}`,
                    },
                    type: ActionTypes.EPIC_COMPLETED,
                };
            }
        })
    );

interface IStartCheckQueueAction {
    type: string;
    payload: {
        qid: string;
    };
}

export const queueStartCheckEpic = (
    action$: ActionsObservable<IStartCheckQueueAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.Q_START_NEXT_TASK),
        // @ts-ignore
        // switchMap((action) => {
        mergeMap((action) => {
            const queues = state$.value.app.queues;
            const qid = action.payload.qid;
            if (queues && queues[qid]) {
                const queue = queues[qid];
                if (!queue.executing) {
                    return of({
                        payload: {
                            message: `No need to check queue ${qid} - nothing executing`,
                        },
                        type: ActionTypes.EPIC_COMPLETED,
                    });
                } else {
                    return interval(1000).pipe(
                        takeUntil(
                            action$.pipe(
                                filter(
                                    (a) =>
                                        [
                                            "Q_END_CHECKING_NORMALLY",
                                            "Q_END_CHECKING_WITH_ERROR",
                                        ].includes(a.type) &&
                                        a.payload.qid === qid
                                )
                            )
                        ),
                        map((time) => ({
                            type: "Q_CHECK",
                            payload: { qid, time },
                        }))
                    );
                }
            }
        })
    );

interface ICheckQueueAction {
    type: string;
    payload: {
        qid: string;
        time: number;
    };
}

export const queueCheckEpic = (
    action$: ActionsObservable<ICheckQueueAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.Q_CHECK),
        map((action) => {
            const qid = action.payload.qid;
            const queue = state$.value.app.queues[qid];
            if (queue.executing && action.payload.time > Q_TIMEOUT) {
                return {
                    payload: {
                        qid,
                        message: `Q_CHECK of ${qid} has timed out`,
                    },
                    type: ActionTypes.Q_END_CHECKING_WITH_ERROR,
                };
            } else if (!queue.executing) {
                return {
                    payload: {
                        qid,
                        message: `Q_CHECK of ${qid} ok - no longer executing`,
                    },
                    type: ActionTypes.Q_END_CHECKING_NORMALLY,
                };
            } else {
                return {
                    payload: {
                        message: `Q_CHECK of ${qid} still executing but not yet timed out`,
                    },
                    type: ActionTypes.EPIC_COMPLETED,
                };
            }
        })
    );

interface IQueueErrorAction {
    type: string;
    payload: {
        qid: string;
        message: string;
    };
}

export const queueErrorEpic = (
    action$: ActionsObservable<IQueueErrorAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.Q_END_CHECKING_WITH_ERROR),
        mergeMap((action) =>
            concat(
                of({
                    payload: {
                        errorMessage: {
                            message: action.payload.message,
                            source: "queue check",
                        },
                        toastIntent: Intent.WARNING,
                        toastMessage: getQueueErrorToastMessage(
                            process.env.NODE_ENV,
                            action.payload.message,
                            state$.value.app.bandwidthEstimate
                        ),
                        toastTimeout: 7500,
                    },
                    type: ActionTypes.EPIC_SENTRY_MESSAGE,
                }),
                of({
                    payload: {
                        variables: {
                            vAppEventCategory: "video",
                            vAppEventData: {
                                latestBandwidth:
                                    state$.value.app.bandwidthEstimate,
                                message: action.payload.message,
                            },
                            vAppEventName: "video_queue_hang",
                        },
                    },
                    type: ActionTypes.CAPTURE_APP_ROLE_EVENT,
                })
            )
        )
    );

interface IReplaceHighlightSequenceItemsAction {
    type: string;
    payload: IReplaceHSItems;
}

export const replaceHSItemsEpic = (
    action$: ActionsObservable<IReplaceHighlightSequenceItemsAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.REPLACE_HIGHLIGHT_SEQUENCE_ITEMS),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: REPLACE_HIGHLIGHT_SEQUENCE_ITEMS,
                    variables: {
                        input: action.payload,
                    },

                    // N.B. the following doesn't work for some reason
                    // refetchQueries: [
                    //     {query: HIGHLIGHTS_FOR_TAGGING_BOTTOM, variables: state$.value.app.taggingQueryVariables},
                    //     {query: EVENT_AND_HIGHLIGHTS, variables: state$.value.app.taggingQueryVariables},
                    // ],
                })
            ).pipe(
                map((res) => {
                    const message = "Sequence updated";
                    showToast(message, 2000, Intent.SUCCESS);
                    return {
                        payload: { message },
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IAddHighlightToSequenceAction {
    type: string;
    payload: IAddHighlightToSequencePayload;
}

export const addHighlightToSequenceEpic = (
    action$: ActionsObservable<IAddHighlightToSequenceAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.ADD_HIGHLIGHT_TO_SEQUENCE),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: ADD_HIGHLIGHT_TO_SEQUENCE,
                    variables: {
                        input: action.payload,
                    },

                    // N.B. the following doesn't work for some reason
                    // refetchQueries: [
                    //     {query: HIGHLIGHTS_FOR_TAGGING_BOTTOM, variables: state$.value.app.taggingQueryVariables},
                    //     {query: EVENT_AND_HIGHLIGHTS, variables: state$.value.app.taggingQueryVariables},
                    // ],
                })
            ).pipe(
                map((res) => {
                    const message = "Highlight added to sequence";
                    showToast(message, 2000, Intent.SUCCESS);
                    return {
                        payload: { message },
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IGetHsidForSystemHighlightSeq {
    type: string;
    payload: IPrepareSystemHighlightSeqPayload;
}

export const getHsidForSystemHighlightSeqEpic = (
    action$: ActionsObservable<IGetHsidForSystemHighlightSeq>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.PREPARE_SYSTEM_HIGHLIGHT_SEQ),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: GET_HSID_FOR_SYSTEM_HIGHLIGHT_SEQ,
                    variables: {
                        input: action.payload,
                    },
                })
            ).pipe(
                map((res: any) => {
                    const newHsid =
                        res.data.createSavedHighlightSequence.highlightSequence
                            .id;
                    return {
                        payload: { newHsid },
                        type: ActionTypes.SAVE_NEW_SYSTEM_HIGHLIGHT_SEQ,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface IRespondToWhoisRequestAction {
    type: string;
    payload: IRespondToWhoisRequestPayload;
}

export const respondToWhoisRequestEpic = (
    action$: ActionsObservable<IRespondToWhoisRequestAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.RESPOND_TO_WHOIS_REQUEST),
        mergeMap((action) =>
            from(
                client.query({
                    query: ONE_PLAYER_FACTS,
                    variables: {
                        vRoleId: state$.value.app.activeRole?.id,
                        vPlayerRole: action.payload.roleId,
                    },
                })
            ).pipe(
                map((res: any) => {
                    const roleRes: IOnePlayerFactsResult = res.data;
                    const toastMessage = getPlayerInfoToastMessage(
                        roleRes.onePlayerFacts,
                        action.payload.jerseyNumber,
                        state$.value.app.activeRole?.userRoleConfidentialById
                            .recruiterCategory
                    );
                    const playerBtoa = btoa(
                        JSON.stringify({
                            pId: roleRes.onePlayerFacts.playerUserId,
                            pN: roleRes.onePlayerFacts.publicName,
                        })
                    );
                    const qualityThresholdBtoa = btoa("0");

                    const routeText = `/profiles/${playerBtoa}/${qualityThresholdBtoa}`;

                    const toastAction = {
                        onClick: () =>
                            history.push(routeText, "pushed_from_app"),
                        text: "View Full Profile",
                    };
                    showToast(toastMessage, 0, Intent.SUCCESS, toastAction);
                    return {
                        payload: {
                            message: `showing player toast for ${res.data.roleInfo.publicName}`,
                        },
                        type: ActionTypes.EPIC_COMPLETED,
                    };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.TOKEN_UPDATE_FAILURE,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface ICreateOrUpdateSequencePayloadAction {
    type: string;
    payload: ICreateOrUpdateSequencePayload;
}

export const createOrUpdateSequenceEpic = (
    action$: ActionsObservable<ICreateOrUpdateSequencePayloadAction>,
    state$: StateObservable<IStore>
) =>
    action$.pipe(
        ofType(ActionTypes.CREATE_OR_UPDATE_SEQUENCE),
        mergeMap((action) =>
            from(
                client.mutate({
                    mutation: CREATE_OR_UPDATE_SEQUENCE,
                    variables: {
                        input: action.payload.input,
                    },
                    refetchQueries: action.payload.refetchQueryData
                        ? action.payload.refetchQueryData
                        : [],
                })
            ).pipe(
                map(() => ({
                    payload: {
                        variables: {
                            vAppEventCategory: "sequence",
                            vAppEventName: "create_or_update_sequence",

                            vAppEventData: {
                                sequence_data: action.payload.input,
                            },
                        },
                    },
                    type: ActionTypes.CAPTURE_APP_ROLE_EVENT,
                })),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            )
        )
    );

interface ISetCurrentLanguageEpic {
    type: string;
    payload: ISetCurrentLanguage;
}

const setCurrentLanguageEpic = (
    action$: ActionsObservable<ISetCurrentLanguageEpic>,
    state$: StateObservable<IStore>
) => {
    // epic receives the message in its payload and logs it to Sentry; also shows error in Toast
    return action$.pipe(
        ofType(ActionTypes.REQUEST_NEW_LANGUAGE),
        mergeMap((action) => {
            const newLanguage: string = Languages.allLanguagesList.includes(
                action.payload.language
            )
                ? action.payload.language
                : Languages.defaultLangKey;
            return from(http.get(`/locales/${newLanguage}.json`)).pipe(
                mergeMap((res) => {
                    intl.init({
                        currentLocale: newLanguage,
                        locales: {
                            [newLanguage]: res.data,
                        },
                    });
                    // const options = intl.getInitOptions();
                    // console.log(options);
                    // return {
                    //     payload: {
                    //         language: newLanguage,
                    //     },
                    //     type: ActionTypes.SET_ACTIVE_LANGUAGE,
                    // };

                    const nextActions: { type: string; payload: any }[] = [
                        {
                            payload: {
                                language: newLanguage,
                            },
                            type: ActionTypes.SET_ACTIVE_LANGUAGE,
                        },
                    ];
                    if (action.payload.updateRolePreferences) {
                        nextActions.push({
                            type: ActionTypes.SAVE_ROLE_LANGUAGE_PREFERENCE,
                            payload: {
                                roleId: state$.value.app.activeRole?.id,
                                language: action.payload.language,
                            },
                        });
                    }
                    return nextActions;
                    // return {
                    //     payload: {
                    //         language: newLanguage,
                    //     },
                    //     type: ActionTypes.SET_ACTIVE_LANGUAGE,
                    // };
                }),
                catchError((err) => {
                    return of({
                        type: ActionTypes.EPIC_ERROR,
                        payload: { errorData: err },
                    });
                })
            );
        })
    );
};

interface ISaveRoleLanguagePrefEpic {
    type: string;
    payload: ISaveRoleLanguagePref;
}

const saveRoleLanguagePrefEpic = (
    action$: ActionsObservable<ISaveRoleLanguagePrefEpic>,
    state$: StateObservable<IStore>
) => {
    return action$.pipe(
        ofType(ActionTypes.SAVE_ROLE_LANGUAGE_PREFERENCE),
        mergeMap((action) => {
            // get existing role language preference, if any
            const targetRole = (state$.value.app.roles || []).filter(
                (r) => r.id === action.payload.roleId
            )[0];
            const tRPrefs = targetRole.userRoleConfidentialById.rolePreferences;
            if (
                tRPrefs === null ||
                tRPrefs.language !== state$.value.app.language
            ) {
                return [
                    {
                        type: ActionTypes.SAVE_ROLE_PREFERENCES,
                        payload: {
                            activeRoleId: action.payload.roleId,
                            newPreferences: {
                                language: action.payload.language,
                            },
                        },
                    },
                    {
                        type: ActionTypes.SHOW_TOAST, // N.B. really should happen from SAVE_ROLE_PREFERENCE epic; leaving here now for testing
                        payload: {
                            toastIntent: Intent.PRIMARY,
                            toastMessage: xtx(
                                "Role language preference updated",
                                "epics.role_lang_pref_updated"
                            ),
                            toastMs: 2000,
                        },
                    },
                ];
            } else {
                return of({
                    type: ActionTypes.EPIC_COMPLETED,
                    payload: {},
                });
            }
        })
    );
};

interface IShowToastEpic {
    type: string;
    payload: IShowToastPayload;
}

const epicCompletedEpic = (
    action$: ActionsObservable<IShowToastEpic>,
    state$: StateObservable<IStore>
) => {
    return action$.pipe(
        ofType(ActionTypes.SHOW_TOAST),
        map((action) => {
            showToast(
                action.payload.toastMessage,
                action.payload.toastMs,
                action.payload.toastIntent
            );
            return {
                type: ActionTypes.EPIC_COMPLETED,
                payload: {},
            };
        })
    );
};

export const rootEpic = combineEpics(
    epicError,
    epicSentryMessage,
    refreshTokenWithGql,
    chooseRoleEpic,
    setRolePreferencesEpic,
    addRoleEventsEpic,
    updateRoleEventEpic,
    updateHighlightEpic,
    saveNewTeamHighlightEpic,
    addProtectedFileEpic,
    uploadProtectedFileEpic,
    uploadVideoFileEpic,
    getPlusCodeDataEpic,
    addNewEntityEpic,
    getIPDataEpic,
    savePledgeEpic,
    verifyRecaptchaTokenEpic,
    startPledgeStateMachineEpic,
    captureAppRoleEventEpic,
    epicCookieApproval,
    addToQueueEpic,
    addMultipleToQueueEpic,
    queueTaskCompletedEpic,
    queueStartCheckEpic,
    queueCheckEpic,
    queueErrorEpic,
    addLikeEventEpic,
    replaceHSItemsEpic,
    addHighlightToSequenceEpic,
    getHsidForSystemHighlightSeqEpic,
    respondToWhoisRequestEpic,
    startMeasuringBandwidthEpic,
    endMeasuringBandwidthEpic,
    createOrUpdateSequenceEpic,
    logPublicActionEpic,
    setCurrentLanguageEpic,
    saveRoleLanguagePrefEpic,
    epicCompletedEpic
);
