import gql from "graphql-tag";

import {
    IHighlightSequenceCore,
    IRetrievedHighlight,
} from "app/graphql_types";
import {
    highlightSequenceFields,
    protectedFileFields,
    retrievedHighlightFields,
    retrievedHighlightFieldsNoNodeId,
} from "app/graqhQlFragments";

export const HIGHLIGHTS_FOR_TAGGING_BOTTOM = gql`
    query highlightsForTaggingBottom(
        $vRoleIdListForIndivHighlights: [Int]
        $vEventIdList: [Int]
        $vMinQuality: Float
        $vMaxQuality: Float
        $vReturnTeamHighlights: Boolean
        $vHighlightSuperCatList: [String]
        $vHighlightCatList: [String]
        $vIncludeApproved: Boolean
        $vIncludeUnapproved: Boolean
        $vIncludeOnlyEditable: Boolean
        $vIncludePhasesAndTransitions: Boolean
        $vHighlightSubCats: [String]
        $vSelectSortOrder: String
        $vDisplaySortOrder: String
        $vRecordLimit: Int
        $vSkip: Boolean
        $vHighlightSequenceId: Int!
        $vPermitSloMo: Boolean
        $vDoubleSloMoPortions: Boolean
    ) {
        getRelevantHighlights(
            vRoleIdListForIndivHighlights: $vRoleIdListForIndivHighlights
            vEventIdList: $vEventIdList
            vMinQuality: $vMinQuality
            vMaxQuality: $vMaxQuality
            vReturnTeamHighlights: $vReturnTeamHighlights
            vHighlightSuperCatList: $vHighlightSuperCatList
            vHighlightCatList: $vHighlightCatList
            vIncludeApproved: $vIncludeApproved
            vIncludeUnapproved: $vIncludeUnapproved
            vIncludeOnlyEditable: $vIncludeOnlyEditable
            vIncludePhasesAndTransitions: $vIncludePhasesAndTransitions
            vHighlightSubCats: $vHighlightSubCats
            vSelectSortOrder: $vSelectSortOrder
            vDisplaySortOrder: $vDisplaySortOrder
            vRecordLimit: $vRecordLimit
            vSkip: $vSkip
        ) {
            totalCount
            nodes {
                ...retrievedHighlightFields
            }
        }
        getHighlightSequenceItems(
            vHighlightSequenceId: $vHighlightSequenceId
            vPermitSloMo: $vPermitSloMo
            vDoubleSloMoPortions: $vDoubleSloMoPortions
            vIncludeUnapproved: $vIncludeUnapproved
        ) {
            totalCount
            nodes {
                ...retrievedHighlightFieldsNoNodeId
            }
        }
        highlightSequences {
            nodes {
                ...highlightSequenceFields
            }
        }
    }
    ${highlightSequenceFields}
    ${protectedFileFields}
    ${retrievedHighlightFields}
    ${retrievedHighlightFieldsNoNodeId}
`;

// different here because of use with graphql HOC
export interface IResult {
    getRelevantHighlights: {
        totalCount: number;
        nodes: IRetrievedHighlight[];
    };
    getHighlightSequenceItems: {
        totalCount: number;
        nodes: IRetrievedHighlight[];
    };
    highlightSequences?: {
        nodes: IHighlightSequenceCore[];
    };
    loading: boolean;
    error?: any;
}

export interface IVariables {
    vRoleIdListForIndivHighlights: number[];
    vEventIdList: number[];
    vMinQuality: number | null;
    vMaxQuality: number | null;
    vReturnTeamHighlights: boolean;
    vHighlightSuperCatList: string[] | null;
    vHighlightCatList: string[] | null;
    vIncludeApproved: boolean;
    vIncludeUnapproved: boolean;
    vIncludeOnlyEditable: boolean;
    vIncludePhasesAndTransitions: boolean;
    vHighlightSubCats: string[] | null;
    vSelectSortOrder: string | null;
    vDisplaySortOrder: string | null;
    vRecordLimit: number | null;
    vSkip: boolean;
    vHighlightSequenceId: number;
    vPermitSloMo?: boolean;
    vDoubleSloMoPortions?: boolean;
}

// export default class QHighlightsForTaggingBottom extends Query<
//     IResult,
//     IVariables
// > {}
