import gql from "graphql-tag";

import { IAddHighlightToSequencePayload } from "redux/reducer_types";

export const ADD_HIGHLIGHT_TO_SEQUENCE = gql`
    mutation addHighlightToSequence($input: AddItemToHighlightSequenceInput!) {
        addItemToHighlightSequence(input: $input) {
            clientMutationId
        }
    }
`;

// interface IResult {
//     clientMutationId: null;
// }

export interface IVariables {
    input: IAddHighlightToSequencePayload;
}

// export default class MAddHighlightToSequence extends Mutation<
//     IResult,
//     IVariables
// > {}
