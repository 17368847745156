/** @format */
import * as React from "react";

import { escapeRegExpChars } from "./OtherUtils";
import { StringTools } from "app/AppSupport";

import {
    Intent,
    IToastProps,
    Position,
    Toaster,
    IconName,
    IActionProps,
} from "@blueprintjs/core";
import * as Sentry from "@sentry/browser";
import moment from "moment";

import { store } from "redux/store";
import { Actions } from "redux/app_actions";
import { Grid, GridItem } from "design-components/CSSGridElements";

import {
    IPlayer,
    IPlayerDataForDisplay,
    RecruiterCategory,
} from "app/graphql_types";
import { financialScoreMapping } from "Resources/Enums";
import parentLogger from "app/utils/loggerService";

const logger = parentLogger.child({
    module: "UserDisplayUtils",
});

// from https://stackoverflow.com/a/196991/4416993
export const toTitleCase = (str: any) => {
    return str.replace(/\w\S*/g, (txt: any) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export const computeSeconds = (hms: string): number => {
    const a = hms.split(":");
    const alen = a.length;
    const seconds =
        (+a[alen - 3] || 0) * 60 * 60 + (+a[alen - 2] || 0) * 60 + +a[alen - 1];
    return seconds;
};

export const computeMinSecText = (s: number): string => {
    const minutes = Math.floor(s / 60);
    const seconds = Math.floor(s - minutes * 60);
    // from https://stackoverflow.com/questions/12230343/how-can-i-display-time-with-leading-zeros
    // N.B. padStart wasn't shown as available
    const minLength = minutes > 100 ? 3 : 2;
    return `${("0" + minutes).slice(-minLength)}:${("0" + seconds).slice(-2)}`;
};

export const onEvent = (
    toastMessage: string | null,
    additionalData: { [key: string]: any },
    sentryMessage: string
) => {
    if (additionalData) {
        Sentry.withScope((scope) => {
            Object.keys(additionalData).forEach((k) => {
                let val = additionalData[k];
                if (!["string", "number", "boolean"].includes(typeof val)) {
                    val = JSON.stringify(val);
                }
                scope.setTag(k, additionalData[k]);
            });
            Sentry.captureMessage(sentryMessage);
        });
    } else {
        Sentry.captureMessage(sentryMessage);
    }
    if (toastMessage) {
        showToast(toastMessage, 0, Intent.DANGER);
    }
};

export const onError = (
    message: string | null,
    e,
    additionalData?: { [key: string]: string }
) => {
    if (additionalData) {
        Sentry.withScope((scope) => {
            Object.keys(additionalData).forEach((k) => {
                let val = additionalData[k];
                if (!["string", "number", "boolean"].includes(typeof val)) {
                    val = JSON.stringify(val);
                }
                scope.setTag(k, additionalData[k]);
            });
            Sentry.captureException(e);
        });
    } else {
        Sentry.captureException(e);
    }
    if (message) {
        showToast(
            `${message}: ${
                (e !== null && e !== undefined && e.message) ||
                "no further error data available"
            }`,
            0,
            Intent.DANGER
        );
    }
};

// export const getMarkdownInnerHtml = (markdownText: string) => {
//     let mdInnerHtml = "";
//     if (markdownText) {
//         try {
//             mdInnerHtml = md.render(markdownText);
//         } catch (e) {
//             mdInnerHtml = "Error with markdown";
//         }
//     }
//     return mdInnerHtml;
// };

export const AppToaster = Toaster.create({
    className: "messages-toaster",
    position: Position.BOTTOM_LEFT,
});

export const showToast = (
    message: React.ReactNode,
    timeout: number,
    intent: Intent,
    action?: IActionProps,
    icon?: IconName
) => {
    const toastVals: IToastProps = {
        intent,
        message,
        timeout,
    };
    if (action) {
        toastVals.action = action;
    }
    if (icon) {
        toastVals.icon = icon;
    }
    AppToaster.show(toastVals);
};

export const clearToasts = () => {
    AppToaster.clear();
};

// https://stackoverflow.com/a/7254108/4416993
// function pad(a, b) {
//     return (1e15 + a + "").slice(-b);
// }

export const highlightText = (text: string, query: string) => {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter((word) => word.length > 0)
        .map(escapeRegExpChars);
    if (words.length === 0) {
        return [text];
    }
    const regexp = new RegExp(words.join("|"), "gi");
    const tokens: React.ReactNode[] = [];
    while (true) {
        const match = regexp.exec(text);
        if (!match) {
            break;
        }
        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
};

export const fileUploadProgress = (progressEvent) => {
    // const { key, loaded, total } = progressEvent;
    const { loaded, total } = progressEvent;
    // TODO - remove this once iphone debugging is complete
    // Sentry.withScope((scope) => {
    //     scope.setTag("fileUpload_key", key);
    //     scope.setTag("fileUpload_total", total);
    //     scope.setTag("fileUpload_loaded", loaded);
    //     Sentry.captureMessage(`In uploadVideoFile fileUploadProgress`);
    // });

    if (loaded && total) {
        store.dispatch(
            Actions.uploadProgressUpdate({
                loaded,
                total,
            })
        );
    }
};

export const getPlayerInfoToastMessage = (
    playerData: IPlayer,
    jerseyNumber: number,
    recruiterCategory?: RecruiterCategory
) => {
    const p = getPlayerDataForDisplay(playerData, recruiterCategory);

    let subTitle = (p.capablePositions && p.capablePositions.join(",")) || "";

    const toastMessage = (
        <Grid>
            <GridItem fontSizeOrSizes={22}>
                <strong>{`#${jerseyNumber}. ${p.publicName}`}</strong>
            </GridItem>
            <GridItem fontSizeOrSizes={22}>
                <strong>{`${subTitle}${
                    p.statusTextForViewer ? `", "${p.statusTextForViewer}` : ""
                }`}</strong>
            </GridItem>
            <GridItem>{p.bioThirdPerson}</GridItem>
            {p.academicComment ? (
                <GridItem>{p.academicComment}</GridItem>
            ) : (
                <></>
            )}
        </Grid>
    );
    return toastMessage;
};

export const getQueueErrorToastMessage = (
    envname: string,
    internalMessage: string,
    bandwidth: number | undefined
) => {
    let toastMessage: JSX.Element;
    if (envname === "production") {
        toastMessage = (
            <Grid>
                <GridItem fontSizeOrSizes={22}>
                    <strong>Video Bandwidth Too Low For Current Video</strong>
                </GridItem>
                <GridItem>
                    The video bandwidth was not high enough, and the video
                    loading has hung, unfortunately.
                </GridItem>
                <GridItem>
                    <strong>
                        One workaround that sometimes helps is to move the video
                        forward or backward by 2 seconds, and press play again
                    </strong>
                </GridItem>
            </Grid>
        );
    } else {
        toastMessage = (
            <p>{`${internalMessage}; latest bandwidth: ${bandwidth}`}</p>
        );
    }
    return toastMessage;
};

export const validateField = (
    field_name: string,
    fieldValue: string,
    saveError: boolean
) => {
    let error = "none";
    let overrideSaveError = false;
    switch (field_name) {
        case "signInEmailOrMobile":
            if (StringTools.stringIsNullOrEmpty(fieldValue)) {
                error = "email_or_mobile_empty";
            } else if (
                !StringTools.validateEmailAddress(fieldValue) &&
                !StringTools.validateMobileNumber(fieldValue)
            ) {
                if (
                    fieldValue.charAt(0) === "+" ||
                    "0123456789".indexOf(fieldValue.charAt(0)) !== -1
                ) {
                    error = "invalid_mobile";
                } else {
                    error = "invalid_email";
                }
            }
            break;
        case "accessCode":
            if (StringTools.stringIsNullOrEmpty(fieldValue)) {
                error = "access_code_empty";
            } else if (
                !fieldValue
                    .split("")
                    .every((c) => "0123456789 ".indexOf(c) !== -1)
            ) {
                error = "non_numeric_character_in_access_code";
                overrideSaveError = true;
            } else if (fieldValue.split(/\s+/gi).join("").length > 6) {
                overrideSaveError = true;
                error = "access_code_6_digits";
            } else if (fieldValue.split(/\s+/gi).join("").length !== 6) {
                error = "access_code_6_digits";
            }
            break;
        case "firstName":
            if (StringTools.stringIsNullOrEmpty(fieldValue)) {
                error = "first_name_empty";
            } else if (fieldValue.length > 60) {
                error = "first_name_max_length";
            }
            break;
        case "lastName":
            if (StringTools.stringIsNullOrEmpty(fieldValue)) {
                error = "last_name_empty";
            } else if (fieldValue.length > 60) {
                error = "last_name_max_length";
            }
            break;
        case "signUpEmail":
            if (StringTools.stringIsNullOrEmpty(fieldValue)) {
                error = "signup_email_empty";
            } else if (!StringTools.validateEmailAddress(fieldValue)) {
                error = "invalid_email";
            }
            break;
        // case "mobile":
        //     const { fullMobileNumber, mobileErrorMsg } =
        //         PhoneNumberTools.parseNumber(
        //             `${registrationDetails.countryCode}${fieldValue}`
        //         );
        //     if (mobileErrorMsg === undefined) {
        //         setValidatedFullMobile(fullMobileNumber);
        //         // actions.setRegistrationDetails({
        //         //     fullMobileNumber,
        //         // });
        //     } else {
        //         error = mobileErrorMsg;
        //     }
        //     break;

        default:
        //
    }
    return { error, overrideSaveError };
};

export const getPlayerDataForDisplay = (
    p: IPlayer,
    recruiterCategory?: RecruiterCategory
) => {
    const fnLogger = logger.child({ function: "getPlayerDataForDisplay" });
    // set DOB text for all first
    const dobText = p.ageDays
        ? moment.unix(Date.now() / 1000 - p.ageDays * 86400).format("MMMM YYYY")
        : undefined;

    // different recruiters get different status messages and may or may not get financial info
    let statusTextForViewer;
    let financialText;
    let availOfferValue;

    if (["US_COLLEGE", "US_PREP_SCHOOL"].includes(recruiterCategory || "")) {
        let fromVal;
        let availUSYears;

        const usStart: number | undefined =
            p.recruiterInfo?.us_college?.available_in_us_start;
        const usEnd: number | undefined =
            p.recruiterInfo?.us_college?.available_in_us_end;
        const usAvailToStartYears: [number, number] | undefined =
            p.recruiterInfo?.us_college?.available_in_autumn_of;

        if (usEnd && usAvailToStartYears && usAvailToStartYears.length > 0) {
            // these are the requirements to show anything for a US college recruiter

            const epochNow = Date.now();
            const availableFrom = moment(
                p.recruiterInfo?.us_college?.available_in_us_start || 0,
                "x"
            );
            availUSYears =
                (usAvailToStartYears &&
                    usAvailToStartYears.filter(
                        (yr) =>
                            new Date(`${yr}-Aug-15`).valueOf() >
                            Math.max(
                                new Date().valueOf(),
                                availableFrom.valueOf()
                            )
                    )) ||
                [];

            availOfferValue = "Available now";

            if (epochNow > usEnd) {
                availOfferValue = "No longer available";
            } else if (usStart && epochNow < usStart) {
                availOfferValue = "May become available";
            } else if (p.uscollegeInfo && p.uscollegeInfo.committed) {
                statusTextForViewer = `Committed in ${
                    p.uscollegeInfo.committed.when
                }${
                    p.uscollegeInfo.offers && p.uscollegeInfo.offers.length > 1
                        ? " with more than one offer"
                        : ""
                }`;
                availOfferValue = "Committed / Active Offer(s)";
            } else {
                if (p.uscollegeInfo && p.uscollegeInfo.offers) {
                    const offerList: number[] = [];
                    p.uscollegeInfo.offers.forEach(
                        (o: { expiry: string; status: string }) => {
                            if (
                                o.status !== "rejected" &&
                                epochNow < parseInt(o.expiry, 10)
                            ) {
                                offerList.push(1);
                            } else if (o.status === "accepted") {
                                // N.B. shouldn't be here, really, but if so...
                                offerList.push(2);
                            } else {
                                offerList.push(0);
                            }
                            statusTextForViewer = [
                                `Has had one or more offers. Still available for ${availUSYears!.join(
                                    ", "
                                )}`,
                                "Currently has one or more active offers",
                                "Has accepted an offer",
                            ][Math.max(...offerList)];
                            if (Math.max(...offerList) > 0) {
                                availOfferValue = "Committed / Active Offer(s)";
                            }
                        }
                    );
                } else {
                    if (
                        p.uscollegeInfo &&
                        p.uscollegeInfo.hasEnoughHighlights
                    ) {
                        availOfferValue = "Available now";
                    } else {
                        availOfferValue = "Available now (limited highlights)";
                    }
                }
            }

            fromVal =
                moment.max(availableFrom, moment()) === availableFrom
                    ? availableFrom.fromNow()
                    : "now";
            if (!statusTextForViewer) {
                statusTextForViewer = `${
                    fromVal === "now" ? "Available" : "Likely available"
                } ${fromVal} for ${availUSYears.join(", ")}`;
            }

            financialText = p.financialScore
                ? financialScoreMapping[p.financialScore]
                : "Budget unknown";
        }
    } else if (recruiterCategory === "US_SUMMER_LEAGUE") {
        const seasonsAvailable =
            p.recruiterInfo?.us_summer_league?.seasons_available;
        if (seasonsAvailable && seasonsAvailable.length > 0) {
            statusTextForViewer = `Available for summer league seasons ${seasonsAvailable.join(
                ", "
            )}`;
        }
    } else if (recruiterCategory) {
        statusTextForViewer =
            p.recruiterInfo &&
            p.recruiterInfo[recruiterCategory.toLowerCase()] &&
            p.recruiterInfo[recruiterCategory.toLowerCase()]["statusText"];
    }
    fnLogger.debug(statusTextForViewer);

    const extendedPlayerData: IPlayerDataForDisplay = {
        ...p,
        statusTextForViewer,
        availOfferValue,
        financialText,
        dobText,
    };
    return extendedPlayerData;
};
